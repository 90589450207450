import React, { useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import sh from "bundles/common/utils/sh";
import BeatLoader from "react-spinners/BeatLoader";

const Form = ({ data, type }) => {
  const intl = useIntl();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    name: "",
    challenge: "Finance",
    email: "",
    phone: "",
    linkedin_url: "",
    message: "",
    resume: "",
  });
  const [referralFormData, setReferralFormData] = useState({
    name: "",
    email: "",
    job_title: "",
    phone: "",
    linkedin_url: "",
    career_field: "",
    country: "",
    referrer_name: "",
    referrer_email: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [referralFormErrors, setReferralFormErrors] = useState({});

  const documentUrls = {
    privacyPolicy: {
      en: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Privacy+Policy+EN.pdf",
      nl: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Privacybeleid+-+NL.pdf",
      fr: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Politique+de+confidentialite%CC%81+FR.pdf",
    },
    terms: {
      en: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Terms+Wesbite+EN.pdf",
      fr: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Conditions+ge%CC%81ne%CC%81rales+Site+FR.pdf",
      nl: "https://sander-production.s3.eu-west-3.amazonaws.com/SANDER+-+Algemene+Gebruiksvoorwaarden+NL.pdf",
    },
  };

  const formPrivacyLabel = `
    ${intl.formatMessage({ id: "form.agree_to" })}&nbsp;
    <a href="${documentUrls.terms[i18nLocale] || documentUrls.terms.en}"
      target="_blank"
      style="color: #356DE0; text-decoration: underline;"
      onmouseover="this.style.color='darkblue'"
      onmouseout="this.style.color='#356DE0'">${intl.formatMessage({ id: "form.sander_conditions" })}</a>&nbsp;
    ${intl.formatMessage({ id: "form.and" })}&nbsp;
    <a href="${documentUrls.privacyPolicy[i18nLocale] || documentUrls.privacyPolicy.en}"
      target="_blank"
      style="color: #356DE0; text-decoration: underline;"
      onmouseover="this.style.color='darkblue'"
      onmouseout="this.style.color='#356DE0'">${intl.formatMessage({ id: "form.sander_privacy" })}</a>
  `;

  const handleChange = (e) => {
    if (e.target.name === "resume") {
      setFormData({ ...formData, resume: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleReferralChange = (e) => {
    setReferralFormData({ ...referralFormData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;
    const required = intl.formatMessage({ id: "form.error.required", defaultMessage: "Required" });

    if (!formData.name.trim()) {
      errors.name = required;
      formIsValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = required;
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = intl.formatMessage({ id: "form.error.email_invalid", defaultMessage: "Invalid Email" });
      formIsValid = false;
    }

    if (!formData.phone.trim()) {
      errors.phone = required;
      formIsValid = false;
    }

    if (type !== "contact" && !formData.resume) {
      errors.resume = required;
      formIsValid = false;
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const validateReferralForm = () => {
    let errors = {};
    let formIsValid = true;
    const required = intl.formatMessage({ id: "form.error.required", defaultMessage: "Required" });

    if (!referralFormData.name.trim()) {
      errors.name = required;
      formIsValid = false;
    }

    if (!referralFormData.email.trim()) {
      errors.email = required;
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(referralFormData.email)) {
      errors.email = intl.formatMessage({ id: "form.error.email_invalid", defaultMessage: "Invalid Email" });
      formIsValid = false;
    }

    if (!referralFormData.job_title.trim()) {
      errors.job_title = required;
      formIsValid = false;
    }

    if (!referralFormData.phone.trim()) {
      errors.phone = required;
      formIsValid = false;
    }

    if (!referralFormData.linkedin_url.trim()) {
      errors.linkedin_url = required;
      formIsValid = false;
    }

    if (!referralFormData.career_field.trim()) {
      errors.career_field = required;
      formIsValid = false;
    }

    if (!referralFormData.country.trim()) {
      errors.country = required;
      formIsValid = false;
    }

    if (!referralFormData.referrer_name.trim()) {
      errors.referrer_name = required;
      formIsValid = false;
    }

    if (!referralFormData.referrer_email.trim()) {
      errors.referrer_email = required;
      formIsValid = false;
    }

    setReferralFormErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === "referral") {
      if (!validateReferralForm()) {
        setIsSubmitting(false);
        return;
      }
    } else {
      if (!validateForm()) {
        setIsSubmitting(false);
        return;
      }
    }
    setIsSubmitting(true);

    const formPayload = new FormData();

    if (type === "referral") {
      Object.entries(referralFormData).forEach(([key, value]) => {
        formPayload.append(`referral_data[${key}]`, value);
      });
      formPayload.append("contact_data[type]", type);
    } else {
      Object.entries(formData).forEach(([key, value]) => {
        formPayload.append(`contact_data[${key}]`, value);
      });
      formPayload.append("contact_data[type]", type);
    }

    formPayload.append("authenticity_token", authenticityToken);

    sh.post("/contact", formPayload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(() => {
        enqueueSnackbar("Successfully Sent", {
          variant: "success",
        });
        setTimeout(() => {
          setIsSubmitting(false);
          window.location.reload();
          window.scrollTo(0, 0);
        }, 1400);
      })
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
      });
  };


  return (
    <section id="form" className="vw-py [--py:48] mini:[--py:96] tablet:[--py:105] desktop:[--py:120] text-darkGreen">
      <div id="form-container" className="gg-container">
        <div id="form-grid" className="gg-grid !vw-gap-y [--gap-y:24] mini:[--gap-y:80]">
          <div id="form-title" className="h3 col-start-1 col-span-12 mini:col-start-6 mini:col-span-14 mini:text-center tablet:col-start-1 tablet:col-span-8 tablet:text-left laptop:col-start-2 laptop:col-span-7 desktop:col-start-2 desktop:col-span-8 richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }}></div>

          {/* The form fields */}
          {type !== "referral" ? (
            <form onSubmit={handleSubmit} action="" method="POST" className="flex flex-col vw-gap [--gap:24] col-start-1 col-span-12 mini:col-start-3 mini:col-span-20 tablet:col-start-10 tablet:col-span-15 laptop:col-start-11 laptop:col-span-13">
              <fieldset className="flex flex-col vw-gap-y [--gap-y:24] mini:[--gap-y:32] laptop:[--gap-x:60] desktop:[--gap-x:68] mini:vw-gap-x mini:[--gap-x:28] mini:grid grid-cols-2">
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="fullName"><FormattedMessage id="form.name" /></label>
                  <input type="text" name="name" id="fullName" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" />
                  {formErrors.name && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{formErrors.name}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="challenge"><FormattedMessage id="form.challenge" />*</label>
                  <div className="select relative">
                    <select name="challenge" id="challenge" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30 appearance-none">
                      <option value="Finance">Finance</option>
                      <option value="IT">IT</option>
                      <option value="HR">HR</option>
                    </select>
                    <svg className="vw-w [--w:7] vw-h [--h:13] absolute top-1/2 transform -translate-y-1/2 vw-right [--right:24] pointer-events-none" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.597356 0.616183L6.19471 6.5625L0.597355 12.5088" stroke="#346DE1" strokeWidth="0.884544" strokeLinecap="round" />
                    </svg>
                  </div>
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="email"><FormattedMessage id="form.email" /></label>
                  <input type="email" name="email" id="email" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" inputMode="email" />
                  {formErrors.email && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{formErrors.email}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="phone"><FormattedMessage id="form.phone" /></label>
                  <input type="tel" name="phone" id="phone" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" inputMode="tel" />
                  {formErrors.phone && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{formErrors.phone}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] mini:col-span-2 fadeInUp">
                  <label htmlFor="CV"><FormattedMessage id="form.your_cv" />{type !== "contact" && <span>*</span>}</label>
                  <div className="file relative">
                    <input
                      type="file"
                      name="resume"
                      id="CV"
                      onChange={handleChange}
                      className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30"
                      accept="application/pdf"
                    />
                    <svg className="vw-w [--w:18] vw-h [--h:16] absolute top-1/2 vw-right [--right:24] transform -translate-y-1/2 pointer-events-none" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9.125V14.125H16.8247V9.125" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8.98242 11.2422L8.98242 1.21617" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M7.0376 2.95312L9.09439 1.00352L11.1512 2.95312" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  {formErrors.resume && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{formErrors.resume}</div>}
                </div>
                {/* <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] mini:col-span-2 fadeInUp">
                <label htmlFor="linkedin">Your url linkedin</label>
                <input type="url" name="linkedin_url" id="linkedin" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" placeholder="Url" inputMode="url" />
              </div> */}
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] mini:col-span-2 fadeInUp">
                  <label htmlFor="message"><FormattedMessage id="form.your_message" /></label>
                  <textarea name="message" id="message" onChange={handleChange} className="vw-px [--px:16] vw-py [--py:10] vw-h [--h:140] resize-none body-paragraph rounded w-full border border-teal-950/30"></textarea>
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] mini:col-span-2 fadeInUp">
                  <div className="checkbox">
                    <input type="checkbox" name="consent[]" id="consent0" required />
                    <label htmlFor="consent0" dangerouslySetInnerHTML={{ __html: formPrivacyLabel }} style={{ display: "block" }}></label>
                  </div>
                </div>
              </fieldset>
              <div id="form-submit" className="fadeInUp">
                <button type="submit" disabled={isSubmitting} target="_self" className="inline-flex text-center justify-center items-center max-mini:w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full bg-deepBlue text-white">
                  <svg className="vw-w [--w:7] vw-h [--h:13] object-contain overflow-visible" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999996 1.09796L6.40204 6.5L0.999997 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span>
                    {isSubmitting ? <BeatLoader size={10} color="#FFFFFF" /> : <FormattedMessage id="form.send" />}
                  </span>
                </button>
              </div>
            </form>
          ) :
            <form onSubmit={handleSubmit} action="" method="POST" className="flex flex-col vw-gap [--gap:24] col-start-1 col-span-12 mini:col-start-3 mini:col-span-20 tablet:col-start-10 tablet:col-span-15 laptop:col-start-11 laptop:col-span-13">
              <fieldset className="flex flex-col vw-gap-y [--gap-y:24] mini:[--gap-y:32] laptop:[--gap-x:60] desktop:[--gap-x:68] mini:vw-gap-x mini:[--gap-x:28] mini:grid grid-cols-2">
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="name"><FormattedMessage id="referred_form.referred_name" /></label>
                  <input type="text" name="name" id="referredName" onChange={handleReferralChange} placeholder="John Smith" className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" />
                  {referralFormErrors.name && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{referralFormErrors.name}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="email"><FormattedMessage id="referred_form.referred_email" /></label>
                  <input type="email" name="email" id="email" onChange={handleReferralChange} placeholder="john.smith@email.com" className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" inputMode="email" />
                  {referralFormErrors.email && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{referralFormErrors.email}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="phone"><FormattedMessage id="referred_form.phone" /></label>
                  <input type="tel" name="phone" id="phone" onChange={handleReferralChange} placeholder="+32 474 56 78 90" className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" />
                  {referralFormErrors.phone && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{referralFormErrors.phone}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="jobTitle"><FormattedMessage id="referred_form.profile" /></label>
                  <input type="text" name="job_title" id="job_title" onChange={handleReferralChange} placeholder="Accountant, Project manager,..." className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" inputMode="email" />
                  {referralFormErrors.job_title && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{referralFormErrors.job_title}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] mini:col-span-2 fadeInUp">
                  <label htmlFor="linkedinUrl"><FormattedMessage id="referred_form.linkedin" /></label>
                  <input type="text" name="linkedin_url" id="linkedin_url" onChange={handleReferralChange} placeholder="https://www.linkedin.com/in/john-smith/" className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" inputMode="email" />
                  {referralFormErrors.linkedin_url && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{referralFormErrors.linkedin_url}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] mini:col-span-2 fadeInUp">
                  <label htmlFor="careerField"><FormattedMessage id="referred_form.career_field" /></label>
                  <div className="flex flex-col vw-px vw-py [--py:10] body-paragraph w-full">
                    <label className="flex items-center">
                      <input type="radio" name="career_field" value="Finance" onChange={handleReferralChange} className="mr-2" /> Finance
                    </label>
                    <label className="flex items-center mt-2">
                      <input type="radio" name="career_field" value="Technology" onChange={handleReferralChange} className="mr-2" /> Technology
                    </label>
                    <label className="flex items-center mt-2">
                      <input type="radio" name="career_field" value="HR" onChange={handleReferralChange} className="mr-2" /> Human Resources
                    </label>
                  </div>
                  {referralFormErrors.career_field && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{referralFormErrors.career_field}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] mini:col-span-2 fadeInUp">
                  <label htmlFor="country"><FormattedMessage id="referred_form.country" /></label>
                  <div className="flex flex-col vw-px vw-py [--py:10] body-paragraph w-full">
                    <label className="flex items-center">
                      <input type="radio" name="country" value="Belgium" onChange={handleReferralChange} className="mr-2" /> Belgium
                    </label>
                    <label className="flex items-center mt-2">
                      <input type="radio" name="country" value="France" onChange={handleReferralChange} className="mr-2" /> France
                    </label>
                    <label className="flex items-center mt-2">
                      <input type="radio" name="country" value="Luxembourg" onChange={handleReferralChange} className="mr-2" /> Luxembourg
                    </label>
                  </div>
                  {referralFormErrors.country && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{referralFormErrors.country}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="referrerName"><FormattedMessage id="referred_form.referrer_name" /></label>
                  <input type="text" name="referrer_name" id="referrer_name" onChange={handleReferralChange} placeholder="John Smith" className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" />
                  {referralFormErrors.name && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{referralFormErrors.name}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] fadeInUp">
                  <label htmlFor="referrerEmail"><FormattedMessage id="referred_form.referrer_email" /></label>
                  <input type="email" name="referrer_email" id="referrer_email" onChange={handleReferralChange} placeholder="john.smith@email.com" className="vw-px [--px:16] vw-py [--py:10] body-paragraph rounded w-full border border-teal-950/30" inputMode="email" />
                  {referralFormErrors.email && <div style={{ color: "red", fontSize: "12px", marginTop: "-12px" }}>{referralFormErrors.email}</div>}
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] mini:col-span-2 fadeInUp">
                  <div className="checkbox">
                    <input type="checkbox" name="consent1" id="consent1" required />
                    <label htmlFor="consent1" style={{ display: "block" }}><FormattedMessage id="referred_form.consent1"/></label>
                  </div>
                </div>
                <div id="form-group" className="body-paragraph flex flex-col vw-gap [--gap:6] mini:col-span-2 fadeInUp">
                  <div className="checkbox">
                    <input type="checkbox" name="consent2" id="consent2" required />
                    <label htmlFor="consent2" dangerouslySetInnerHTML={{ __html: formPrivacyLabel }} style={{ display: "block" }}></label>
                  </div>
                </div>
              </fieldset>
              <div id="form-submit" className="fadeInUp">
                <button type="submit" disabled={isSubmitting} target="_self" className="inline-flex text-center justify-center items-center max-mini:w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full bg-deepBlue text-white">
                  <svg className="vw-w [--w:7] vw-h [--h:13] object-contain overflow-visible" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999996 1.09796L6.40204 6.5L0.999997 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span>
                    {isSubmitting ? <BeatLoader size={10} color="#FFFFFF" /> : <FormattedMessage id="referred_form.send" />}
                  </span>
                </button>
              </div>
            </form>
          }
        </div>
      </div>
    </section>
  );
};

export default Form;
