import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const NumbersExtended = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const data = {
    image1: {
      url: "/static/images/whyuscandidat-vision-1.jpg",
      alt: intl.formatMessage({ id: "numbers_extended.image1_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscandidat-vision-1_640.webp",
        medium: "/static/images/scaled/whyuscandidat-vision-1_1280.webp",
        large: "/static/images/scaled/whyuscandidat-vision-1_2560.webp"
      }
    },
    image2: {
      url: "/static/images/silke_picture.jpg",
      alt: intl.formatMessage({ id: "numbers_extended.image2_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/silke_picture_640.webp",
        medium: "/static/images/scaled/silke_picture_1280.webp",
        large: "/static/images/new_pictures/silke_picture_2560.webp"
      }
    },
    subtitle: intl.formatMessage({ id: "numbers_extended.subtitle" }),
    title: intl.formatMessage({ id: "numbers_extended.title" }),
    content: intl.formatMessage({ id: "numbers_extended.content" }),
    button: {
      url: `/${i18nLocale}/contact`,
      title: intl.formatMessage({ id: "numbers_extended.button.title" }),
      target: "_self"
    },
    cards: [
      {
        number: {
          prefix: "+",
          value: 587,
          suffix: ""
        },
        label: intl.formatMessage({ id: "numbers_extended.cards.placements.label" })
      },
      {
        number: {
          prefix: "+",
          value: 184,
          suffix: ""
        },
        label: intl.formatMessage({ id: "numbers_extended.cards.partners.label" })
      },
      {
        number: {
          prefix: "+",
          value: 3500,
          suffix: ""
        },
        label: intl.formatMessage({ id: "numbers_extended.cards.interviews.label" })
      }
    ]
  };

  return (
    <section id="numbers-extended" className="max-mini:vw-mt max-mini:[--mt:50] relative text-darkGreen">
      {data.image1 && (
        <div id="numbers-extended-image-1" className="overflow-hidden mini:absolute mini:right-0 mini:vw-top mini:[--top:90] laptop:[--top:80] desktop:[--top:90] w-screen mini:vw-w [--w:328] tablet:[--w:440] laptop:[--w:522] desktop:[--w:618] aspect-[328/312] mini:aspect-[328/324] tablet:aspect-[440/434] laptop:aspect-[522/542] desktop:aspect-[618/639] vw-rounded-tl [--rounded-tl:130] parallax-image-wrapper">
          <img
            className="w-full h-full object-cover parallax-image"
            src={data.image1.sizes.thumbnail}
            srcSet={`${data.image1.sizes.thumbnail} 640w, ${data.image1.sizes.medium} 1280w, ${data.image1.sizes.large} 2560w`}
            sizes="100vw"
            alt={data.image1.alt}
          />
        </div>
      )}
      {data.image2 && (
        <div id="numbers-extended-image-2" className="overflow-hidden mini:absolute mini:right-0 mini:vw-top mini:[--top:425] tablet:[--top:555] laptop:[--top:615] desktop:[--top:705] vw-w [--w:176] mini:[--w:191] tablet:[--w:256] laptop:[--w:300] desktop:[--w:370] aspect-[176/185] mini:aspect-[191/224] tablet:aspect-[256/300] laptop:aspect-[300/357] desktop:aspect-[370/430] vw-rounded-bl [--rounded-bl:90] ml-auto vw-mt [--mt:-100] parallax-image-wrapper">
          <img
            className="w-full h-full object-cover parallax-image"
            src={data.image2.sizes.thumbnail}
            srcSet={`${data.image2.sizes.thumbnail} 640w, ${data.image2.sizes.medium} 1280w, ${data.image2.sizes.large} 2560w`}
            sizes="100vw"
            alt={data.image2.alt}
          />
        </div>
      )}
      <div id="numbers-extended-container" className="gg-container vw-py [--py:60] mini:[--py:90] tablet:[--py:140] laptop:[--py:170] desktop:[--py:200]">
        <div id="numbers-extended-grid" className="gg-grid !vw-gap-y [--gap-y:24] mini:[--gap-y:42]">
          <div id="numbers-extended-heading" className="col-start-1 col-span-12 tablet:col-start-1 tablet:col-span-13 laptop:col-start-3 laptop:col-span-11 flex flex-col vw-gap [--gap:24] tablet:[--gap:42] desktop:[--gap:64]">
            <div id="numbers-extended-subtitle" className="subtitle uppercase text-deepBlue fadeInUp">
              {data.subtitle}
            </div>
            <h3 id="numbers-extended-title" className="h1 fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }}></h3>
          </div>
          <div id="numbers-extended-text" className="col-start-1 col-span-12 tablet:col-start-3 tablet:col-span-10 laptop:col-start-4 laptop:col-span-8 flex flex-col vw-gap [--gap:24] tablet:[--gap:42]">
            <div id="numbers-extended-content" className="richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{ __html: data.content }}></div>
            {data.button && (
              <div id="numbers-extended-cta" className="fadeInUp">
                <a href={data.button.url} className="button max-mini:w-full border-deepBlue bg-deepBlue text-white hover:border-lightBlue hover:bg-lightBlue hover:text-deepBlue" target={data.button.target}>
                  <svg className="button-icon" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span className="button-text">{data.button.title}</span>
                </a>
              </div>
            )}
          </div>
          <div id="numbers-extended-cards" className="col-start-1 col-span-12 mini:col-start-2 mini:col-span-22 laptop:col-start-4 laptop:col-span-18 flex flex-col vw-gap [--gap:48] mini:grid mini:grid-cols-2 mini:[&>*:nth-child(2)]:col-start-1 mini:vw-gap-x mini:[--gap-x:36] laptop:[--gap-x:100] desktop:[--gap-x:120] mini:vw-gap-y mini:[--gap-y:56] vw-pt [--pt:42] mini:[--pt:90] tablet:[--pt:110] laptop:[--pt:130] desktop:[--pt:160]">
            {data.cards.map((card, index) => (
              <div key={index} id="numbers-extended-card" className="flex items-center justify-between vw-pb [--pb:16] tablet:[--pb:18] desktop:[--pb:22] border-b border-softGreen fadeInUp">
                {card.number && (
                  <div id="numbers-extended-card-number" className="category-number">
                    {card.number.prefix}<span data-counter={card.number.value}>{card.number.value}</span>{card.number.suffix}
                  </div>
                )}
                <div id="numbers-extended-card-label" className="vw-text [--text:16] tablet:[--text:18] laptop:[--text:20] vw-leading [--leading:35]">
                  {card.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NumbersExtended;
