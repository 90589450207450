import React from "react";
import { useIntl } from "react-intl";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import Footer from "../components/globals/Footer";

import TitleHero from "../components/partials/TitleHero";
import Content from "../components/partials/Content";

const PrivacyPolicy = () => {
  const intl = useIntl();

  const titleData = {
    title: intl.formatMessage({ id: "footer.privacy_policy.title" }),
  };

  const contentData = [
    {
      title: intl.formatMessage({ id: "privacy.preamble_title" }),
      html: intl.formatMessage({ id: "privacy.preamble_html" }),
    },
    {
      title: intl.formatMessage({ id: "privacy.data_controller_title" }),
      html: intl.formatMessage({ id: "privacy.data_controller_html" }),
    },
    {
      title: intl.formatMessage({ id: "privacy.consent_title" }),
      html: intl.formatMessage({ id: "privacy.consent_html" }),
    },
    {
      title: intl.formatMessage({ id: "privacy.data_processed_title" }),
      html: intl.formatMessage({ id: "privacy.data_processed_html" }),
    },
    {
      title: intl.formatMessage({ id: "privacy.data_purpose_title" }),
      html: intl.formatMessage({ id: "privacy.data_purpose_html" }),
    },
    {
      title: intl.formatMessage({ id: "privacy.technical_measures_title" }),
      html: intl.formatMessage({ id: "privacy.technical_measures_html" }),
    },
    {
      title: intl.formatMessage({ id: "privacy.access_rights_title" }),
      html: intl.formatMessage({ id: "privacy.access_rights_html" }),
    },
    {
      title: intl.formatMessage({ id: "privacy.personal_rights_title" }),
      html: intl.formatMessage({ id: "privacy.personal_rights_html" }),
    },
    {
      title: intl.formatMessage({ id: "privacy.contact_title" }),
      html: intl.formatMessage({ id: "privacy.contact_html" }),
    },
  ];

  return (
    <div id="contact">
      <Menu />
      <Header />

      <main>
        <TitleHero data={titleData} />
        <Content data={contentData} />
      </main>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
