import React from "react";

const OurTeam = () => {
  const teamCards = [
    {
      image: {
        url: "/static/images/team/Alex.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Alex_640.webp",
          medium: "/static/images/team/scaled/Alex_1280.webp",
          large: "/static/images/team/scaled/Alex_2560.webp"
        }
      },
      title: "Alexander Asnong",
      function: "Founder & CEO",
      contact: {
        linkedin: "https://www.linkedin.com/in/alexasnong/",
        phone: "+32 479 32 47 20",
        email: "a.asnong@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Ben.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Ben_640.webp",
          medium: "/static/images/team/scaled/Ben_1280.webp",
          large: "/static/images/team/scaled/Ben_2560.webp"
        }
      },
      title: "Benyamin Yakoubi",
      function: "Partner",
      contact: {
        linkedin: "https://www.linkedin.com/in/benyamin-yakoubi/",
        phone: "+32 493 91 90 63",
        email: "b.yakoubi@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Jules.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Jules_640.webp",
          medium: "/static/images/team/scaled/Jules_1280.webp",
          large: "/static/images/team/scaled/Jules_2560.webp"
        }
      },
      title: "Jules Fadié",
      function: "Partner",
      contact: {
        linkedin: "https://www.linkedin.com/in/julesfadie/",
        phone: "+32 493 48 32 84",
        email: "j.fadie@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Jade.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Jade_640.webp",
          medium: "/static/images/team/scaled/Jade_1280.webp",
          large: "/static/images/team/scaled/Jade_2560.webp"
        }
      },
      title: "Jade El Sayed",
      function: "Partner",
      contact: {
        linkedin: "https://www.linkedin.com/in/jade-el-sayed-587219153/",
        phone: "+32 472 69 31 07",
        email: "jade@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Jacques.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Jacques_640.webp",
          medium: "/static/images/team/scaled/Jacques_1280.webp",
          large: "/static/images/team/scaled/Jacques_2560.webp"
        }
      },
      title: "Jacques Van Bost",
      function: "Manager",
      contact: {
        linkedin: "https://www.linkedin.com/in/jacques-van-bost-054030144/",
        phone: "+33 6 51 01 26 82",
        email: "jacques@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Andy.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Andy_640.webp",
          medium: "/static/images/team/scaled/Andy_1280.webp",
          large: "/static/images/team/scaled/Andy_2560.webp"
        }
      },
      title: "Andy Agredano",
      function: "Managing Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/andyagredano/",
        phone: "+32 489 57 73 25",
        email: "a.agredano@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Margaux.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Margaux_640.webp",
          medium: "/static/images/team/scaled/Margaux_1280.webp",
          large: "/static/images/team/scaled/Margaux_2560.webp"
        }
      },
      title: "Margaux op de Beek",
      function: "Senior Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/margaux-op-de-beek-493656205/",
        phone: "+32 492 63 40 09",
        email: "m.opdebeek@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Magali.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Magali_640.webp",
          medium: "/static/images/team/scaled/Magali_1280.webp",
          large: "/static/images/team/scaled/Magali_2560.webp"
        }
      },
      title: "Magali Ribera",
      function: "Senior Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/magali-ribera-5675bb172/",
        phone: "+32 475 68 32 41",
        email: "m.ribera@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Ibrahim.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Ibrahim_640.webp",
          medium: "/static/images/team/scaled/Ibrahim_1280.webp",
          large: "/static/images/team/scaled/Ibrahim_2560.webp"
        }
      },
      title: "Ibrahim Elci",
      function: "Senior Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/ibrahim-elci/",
        phone: "+32 484 32 80 29",
        email: "i.elci@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Nissrine.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Nissrine_640.webp",
          medium: "/static/images/team/scaled/Nissrine_1280.webp",
          large: "/static/images/team/scaled/Nissrine_2560.webp"
        }
      },
      title: "Nisrine Essahib",
      function: "HR Consultant",
      contact: {
        linkedin: "https://www.linkedin.com/in/nisrine-essahib-847766163/",
        phone: "+32 472 18 33 54",
        email: "n.essahib@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Yannick.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Yannick_640.webp",
          medium: "/static/images/team/scaled/Yannick_1280.webp",
          large: "/static/images/team/scaled/Yannick_2560.webp"
        }
      },
      title: "Yannick Vargiamidis",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/yannick-vargiamidis-478333231/",
        phone: "+32 471 50 12 18",
        email: "yannick@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Cecile.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Cecile_640.webp",
          medium: "/static/images/team/scaled/Cecile_1280.webp",
          large: "/static/images/team/scaled/Cecile_2560.webp"
        }
      },
      title: "Cecile Moline",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/cecile-moline-a867b8b5/",
        phone: "+32 479 36 35 85",
        email: "c.moline@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Giulia.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Giulia_640.webp",
          medium: "/static/images/team/scaled/Giulia_1280.webp",
          large: "/static/images/team/scaled/Giulia_2560.webp"
        }
      },
      title: "Giulia Sebastianelli",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/giulia-sebastianelli-b152891ba/",
        phone: "+39 327 824 8289",
        email: "giulia@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Marie.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Marie_640.webp",
          medium: "/static/images/team/scaled/Marie_1280.webp",
          large: "/static/images/team/scaled/Marie_2560.webp"
        }
      },
      title: "Marie Yeghyaian",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/marie-yeghyaian-735302129/",
        phone: "+33 6 26 71 57 68",
        email: "marie@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Silke.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Silke_640.webp",
          medium: "/static/images/team/scaled/Silke_1280.webp",
          large: "/static/images/team/scaled/Silke_2560.webp"
        }
      },
      title: "Silke Saelen",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/silke-saelen-7a13a4209/",
        phone: "+32 478 78 45 97",
        email: "s.saelen@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Kamal.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Kamal_640.webp",
          medium: "/static/images/team/scaled/Kamal_1280.webp",
          large: "/static/images/team/scaled/Kamal_2560.webp"
        }
      },
      title: "Kamal Salmanian",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/kamal-salmanian-092098177/",
        phone: "+32 483 34 40 70",
        email: "k.salmanian@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Charlotte.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Charlotte_640.webp",
          medium: "/static/images/team/scaled/Charlotte_1280.webp",
          large: "/static/images/team/scaled/Charlotte_2560.webp"
        }
      },
      title: "Charlotte Martinot",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/charlotte-martinot-b9379320b/",
        phone: "+32 470 56 40 20",
        email: "c.martinot@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Jamai.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Jamai_640.webp",
          medium: "/static/images/team/scaled/Jamai_1280.webp",
          large: "/static/images/team/scaled/Jamai_2560.webp"
        }
      },
      title: "Jamaï Cazelles de Livers",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/jamaï-cazelles-de-livers-30607318/",
        phone: "+32 456 40 01 82",
        email: "j.cazelles@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Justine.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Justine_640.webp",
          medium: "/static/images/team/scaled/Justine_1280.webp",
          large: "/static/images/team/scaled/Justine_2560.webp"
        }
      },
      title: "Justine Leys",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/justine-leys-61647a105/",
        phone: "+32 492 54 51 79",
        email: "j.leys@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Isa.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Isa_640.webp",
          medium: "/static/images/team/scaled/Isa_1280.webp",
          large: "/static/images/team/scaled/Isa_2560.webp"
        }
      },
      title: "Isa Eykens",
      function: "Recruitment Advisor",
      contact: {
        linkedin: "https://www.linkedin.com/in/isa-eykens-648524233/",
        phone: "+32 499 72 12 40",
        email: "isa@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Sam.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Sam_640.webp",
          medium: "/static/images/team/scaled/Sam_1280.webp",
          large: "/static/images/team/scaled/Sam_2560.webp"
        }
      },
      title: "Sam Rozenberg",
      function: "Software Engineer",
      contact: {
        linkedin: "https://www.linkedin.com/in/sam-rozenberg/",
        phone: "+32 484 30 40 50",
        email: "sam@squarehub.eu"
      }
    },
    {
      image: {
        url: "/static/images/team/Gideon.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Gideon_640.webp",
          medium: "/static/images/team/scaled/Gideon_1280.webp",
          large: "/static/images/team/scaled/Gideon_2560.webp"
        }
      },
      title: "Gideon Maydell",
      function: "Software Engineer",
      contact: {
        linkedin: "https://www.linkedin.com/in/gideonmaydell/",
        phone: "+43 650 2488887",
        email: "gideon@squarehub.eu"
      }
    },
    {
      image: {
        url: "/static/images/team/Nahel.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Nahel_640.webp",
          medium: "/static/images/team/scaled/Nahel_1280.webp",
          large: "/static/images/team/scaled/Nahel_2560.webp"
        }
      },
      title: "Nahel Ben Abbes",
      function: "Business Data Analyst",
      contact: {
        linkedin: "https://www.linkedin.com/in/nahel-ben-abbes/",
        phone: "+32 466 18 54 86",
        email: "nahel@wearesander.com"
      }
    },
    {
      image: {
        url: "/static/images/team/Sophie.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Sophie_640.webp",
          medium: "/static/images/team/scaled/Sophie_1280.webp",
          large: "/static/images/team/scaled/Sophie_2560.webp"
        }
      },
      title: "Sophie Pierquin",
      function: "Marketing Officer",
      contact: {
        linkedin: "https://www.linkedin.com/in/sophie-pierquin-071595172/",
        phone: "+32 497 66 83 16",
        email: "sophie@squarehub.eu"
      }
    },
    {
      image: {
        url: "/static/images/team/Vincent.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Vincent_640.webp",
          medium: "/static/images/team/scaled/Vincent_1280.webp",
          large: "/static/images/team/scaled/Vincent_2560.webp"
        }
      },
      title: "Vincent Babet",
      function: "Account Manager",
      contact: {
        linkedin: "https://www.linkedin.com/in/vbabet/",
        phone: "+32 475 24 45 96",
        email: "vincent@squarehub.eu"
      }
    },
    {
      image: {
        url: "/static/images/team/Flor.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Flor_640.webp",
          medium: "/static/images/team/scaled/Flor_1280.webp",
          large: "/static/images/team/scaled/Flor_2560.webp"
        }
      },
      title: "Flor Spanhove",
      function: "Account Manager",
      contact: {
        linkedin: "https://www.linkedin.com/in/flor-spanhove-17229615b/",
        phone: "+32 495 21 33 54",
        email: "flor@squarehub.eu"
      }
    },
    {
      image: {
        url: "/static/images/team/Sebastien.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Sebastien_640.webp",
          medium: "/static/images/team/scaled/Sebastien_1280.webp",
          large: "/static/images/team/scaled/Sebastien_2560.webp"
        }
      },
      title: "Sébastien del Marmol",
      function: "Sales Development Representative",
      contact: {
        linkedin: "https://www.linkedin.com/in/sébastien-del-marmol-b87682202/",
        phone: "+32 492 93 86 64",
        email: "sebastien@squarehub.eu"
      }
    },
    {
      image: {
        url: "/static/images/team/Sacha.jpeg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Sacha_640.webp",
          medium: "/static/images/team/scaled/Sacha_1280.webp",
          large: "/static/images/team/scaled/Sacha_2560.webp"
        }
      },
      title: "Sacha Van Laethem",
      function: "Talent Officer",
      contact: {
        linkedin: "https://www.linkedin.com/in/sacha-van-laethem-9b3a6824b/",
        phone: "+32 491 48 79 49",
        email: "contact@squarehub.eu"
      }
    },
    {
      image: {
        url: "/static/images/team/Lina.jpg",
        alt: "",
        sizes: {
          thumbnail: "/static/images/team/scaled/Lina_640.webp",
          medium: "/static/images/team/scaled/Lina_1280.webp",
          large: "/static/images/team/scaled/Lina_2560.webp"
        }
      },
      title: "Lina Boumard",
      function: "Talent Officer",
      contact: {
        linkedin: "https://www.linkedin.com/in/lina-boumard/",
        phone: "+32 488 49 90 82",
        email: "contact@squarehub.eu"
      }
    }
  ];

  return (
    <section id="team" className="vw-py [--py:60] mini:[--py:90] tablet:[--py:110] laptop:[--py:130] desktop:[--py:170]">
      <div id="team-container" className="gg-container">
        <div id="team-grid" className="grid grid-cols-1 mini:grid-cols-3 vw-gap-x [--gap-x:20] tablet:[--gap-x:32] laptop:[--gap-x:46] desktop:[--gap-x:52] vw-gap-y [--gap-y:32] mini:[--gap-y:64] tablet:[--gap-y:82] desktop:[--gap-y:128] laptop:vw-px laptop:[--px:85] desktop:[--px:112]">
          {teamCards.map((card, index) => (
            <div key={index} id="team-card" className="col-span-1 flex mini:flex-col vw-gap [--gap:32] mini:[--gap:28] items-start justify-start max-mini:[&:not(:last-child)]:vw-pb max-mini:[&:not(:last-child)]:[--pb:32] max-mini:[&:not(:last-child)]:border-b max-mini:[&:not(:last-child)]:border-lightBlue fadeInUp mini:[&:nth-child(3n-1)]:animate-delay-150 mini:[&:nth-child(3n)]:animate-delay-300">
              <div id="team-card-image" className="overflow-hidden max-mini:rounded-full vw-w [--w:80] aspect-square max-mini:grow-0 max-mini:shrink-0 mini:w-full mini:aspect-[208/234] mini:vw-rounded-tr mini:[--rounded-tr:60] laptop:[--rounded-tr:90]">
                <img
                  src={card.image.sizes.thumbnail}
                  srcSet={`${card.image.sizes.thumbnail} 640w, ${card.image.sizes.medium} 1280w, ${card.image.sizes.large} 2560w`}
                  sizes="100vw"
                  className="w-full h-full object-cover"
                  alt={card.title} loading="lazy"
                />
              </div>
              <div id="team-card-infos" className="flex flex-col vw-gap [--gap:14] mini:text-center mini:w-full">
                <div id="team-card-name" className="category-title text-black">
                  {card.title}
                </div>
                <div id="team-card-function" className="small-paragraph text-black">
                  {card.function}
                </div>
                <div id="team-card-contact" className="flex items-center justify-start mini:justify-center vw-gap [--gap:14]">
                  {card.contact.linkedin && (
                    <a
                      href={card.contact.linkedin}
                      target="_blank"
                      rel="noreferrer"
                      className="vw-w [--w:40] aspect-square rounded-full border border-lightBlue text-deepBlue inline-flex items-center justify-center transition-colors hover:border-deepBlue hover:bg-deepBlue hover:text-white"
                    >
                      <svg className="vw-h [--h:14] aspect-[448/512]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg>
                    </a>
                  )}
                  {card.contact.phone && (
                    <a
                      href={`tel:${card.contact.phone}`}
                      className="vw-w [--w:40] aspect-square rounded-full border border-lightBlue text-deepBlue inline-flex items-center justify-center transition-colors hover:border-deepBlue hover:bg-deepBlue hover:text-white"
                    >
                      <svg className="vw-h [--h:14] aspect-[21/12]" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.63331 7.17223C3.04928 7.16963 3.45409 7.03736 3.79136 6.79386C4.12863 6.55036 4.38158 6.20775 4.51497 5.81373C4.93012 4.60975 5.71042 3.56523 6.7472 2.82565C7.78398 2.08606 9.02562 1.68823 10.2992 1.68758C11.5727 1.68823 12.8143 2.08606 13.8511 2.82565C14.8879 3.56523 15.6682 4.60975 16.0833 5.81373C16.2167 6.20775 16.4697 6.55036 16.807 6.79386C17.1442 7.03736 17.549 7.16963 17.965 7.17223H20.5976C20.1757 3.15578 15.7247 0 10.2992 0C4.87357 0 0.426786 3.15578 0.000671387 7.17223H2.63331Z" fill="currentColor" />
                        <path d="M10.2999 2.74219C8.95715 2.74219 7.66941 3.27559 6.71996 4.22504C5.77051 5.17449 5.23712 6.46222 5.23712 7.80494V11.3911H15.3626V7.80494C15.3626 6.46222 14.8292 5.17449 13.8798 4.22504C12.9303 3.27559 11.6426 2.74219 10.2999 2.74219ZM10.2999 9.38705C9.68446 9.38705 9.09424 9.14258 8.65908 8.70741C8.22391 8.27225 7.97945 7.68204 7.97945 7.06662C7.97945 6.45121 8.22391 5.861 8.65908 5.42583C9.09424 4.99067 9.68446 4.74619 10.2999 4.74619C10.9153 4.74619 11.5055 4.99067 11.9407 5.42583C12.3758 5.861 12.6203 6.45121 12.6203 7.06662C12.6203 7.68204 12.3758 8.27225 11.9407 8.70741C11.5055 9.14258 10.9153 9.38705 10.2999 9.38705Z" fill="currentColor" />
                      </svg>
                    </a>
                  )}
                  {card.contact.email && (
                    <a
                      href={`mailto:${card.contact.email}`}
                      className="vw-w [--w:40] aspect-square rounded-full border border-lightBlue text-deepBlue inline-flex items-center justify-center transition-colors hover:border-deepBlue hover:bg-deepBlue hover:text-white"
                    >
                      <svg className="vw-h [--h:14] aspect-[14/10]" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.83823 4.84448L0.532898 1.22461V9.18261H14.0003V1.22461L8.69495 4.84448C8.27462 5.13332 7.7766 5.28794 7.26659 5.28794C6.75659 5.28794 6.25856 5.13332 5.83823 4.84448Z" fill="currentColor" />
                        <path d="M0.528625 0L5.83396 3.61987C6.25429 3.90871 6.75232 4.06333 7.26232 4.06333C7.77233 4.06333 8.27035 3.90871 8.69068 3.61987L13.996 0H0.528625Z" fill="currentColor" />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
