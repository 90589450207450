import ReactOnRails from "react-on-rails";
import LoginApp from "../bundles/Login/startup/LoginApp";
import InternalApp from "../bundles/Internal/startup/InternalApp";
import MarketingApp from "../bundles/Marketing/startup/MarketingApp";

ReactOnRails.register({
  LoginApp,
  InternalApp,
  MarketingApp
});
