import React from "react";
import { IconButton, Badge } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const CustomExpansionControl = ({ row, expandedRows, setExpandedRows, applicationsCount, isRowExpandable }) => {
  const isExpanded = expandedRows.includes(row.id);
  const enabled = isRowExpandable();

  const handleToggle = () => {
    if (!enabled) return;

    const newExpandedRows = isExpanded
      ? expandedRows.filter(id => id !== row.id)
      : [...expandedRows, row.id];
    setExpandedRows(newExpandedRows);
  };

  return (
    <IconButton onClick={handleToggle} size="small" disabled={!enabled} style={{ color: enabled ? "inherit" : "grey" }}>
      <Badge badgeContent={applicationsCount} color="success" sx={{ "& .MuiBadge-badge": { minWidth: "26px" } }}>
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Badge>
    </IconButton>
  );
};

export default CustomExpansionControl;
