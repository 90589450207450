import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const CardSelect = ({
  fieldName,
  selected,
  values,
  onChange,
  multiple = false,
  ...props
}) => {
  const selectedValues = multiple ? selected : [selected];

  const handleOptionClicked = (code) => {
    onChange(fieldName, code, multiple);
  };
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      columnGap: "24px",
      rowGap: "8px",
      overflow: "auto",
      flexWrap: "wrap",
      justifyContent: props.justifyContent,
    },
    option: {
      border: `1px solid ${theme.palette.common.sh_grey}`,
      height: "40px",
      borderRadius: "4px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "24px",
      paddingRight: "24px",
      cursor: "pointer",
      whiteSpace: "nowrap",
      flexGrow: 1,
      justifyContent: "center",
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        borderColor: theme.palette.text["primary"],
      },
    },
    selectedOption: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  }));

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {values.map((value) => (
        <Box
          key={value.code}
          className={`${classes.option} ${
            selectedValues?.includes(value.code) ? classes.selectedOption : ""
          }`}
          onClick={() => handleOptionClicked(value.code)}
        >
          <Typography>{value.label}</Typography>
        </Box>
      ))}
    </Box>);
};

export default CardSelect;
