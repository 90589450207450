import React from "react";

const Content = ({ data }) => {
  return (
    <section id="content-section" className="relative text-darkGreen" style={{ overflowX: "unset" }}>
      <div id="content-container" className="gg-container vw-pt [--pt:70] mini:[--pt:90]">
        <div id="content-richtext" className="richtext small-paragraph tablet:vw-pl tablet:[--pl:36] laptop:[--pl:56]">
          {data.map((section, index) => (
            <div key={index} className="section">
              <h2 className="category-title">{section.title}</h2>
              <div
                className="section-content"
                style={{ margin: "20px 0" }}
                dangerouslySetInnerHTML={{ __html: section.html }}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Content;
