import React from "react";

const Quote = ({ data }) => {

  return (
    <section id="quote" className={`vw-py [--py:46] mini:[--py:70] tablet:[--py:112] laptop:[--py:140] desktop:[--py:170] ${data.additionalClasses}`}>
      <div id="quote-container" className="gg-container">
        <div className="gg-grid">
          <div className="col-span-12 mini:col-span-24 tablet:col-start-2 tablet:col-span-22 laptop:col-start-2 laptop:col-span-21 flex flex-col mini:flex-row mini:items-start vw-gap [--gap:24] mini:[--gap:56] laptop:[--gap:63] desktop:[--gap:88]">
            {data.image && (
              <div id="quote-image" className="vw-w [--w:88] tablet:[--w:100] laptop:[--w:125] desktop:[--w:148] aspect-square overflow-hidden vw-rounded [--rounded:8] !rounded-br-none grow-0 shrink-0 fadeInUp">
                <img
                  className="w-full h-full object-cover"
                  src={data.image.sizes.thumbnail}
                  srcSet={`${data.image.sizes.thumbnail} 640w, ${data.image.sizes.medium} 1280w, ${data.image.sizes.large} 2560w`}
                  sizes="100vw"
                  alt={data.image.alt}
                />
              </div>
            )}
            <blockquote id="quote-text-wrapper" className="flex flex-col vw-gap [--gap:24] mini:[--gap:42]">
              <div id="quote-content" className="richtext font-light vw-text [--text:16] mini:[--text:20] tablet:[--text:24] laptop:[--text:28] desktop:[--text:32] leading-[192%] fadeInUp" dangerouslySetInnerHTML={{ __html: data.content }}></div>
              <footer className="vw-text [--text:14] mini:[--text:15] desktop:[--text:17] font-bold text-deepBlue leading-[178%] fadeInUp">
                {data.name}
                <cite className="vw-text [--text:13] mini:[--text:14] desktop:[--text:16] font-light text-darkGreen not-italic vw-ml [--ml:36] tablet:[--ml:48] leading-[178%]">
                  &mdash; {data.position}
                </cite>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quote;
