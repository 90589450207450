import React from "react";
import { useIntl } from "react-intl";

const Mozaic = () => {
  const intl = useIntl();

  const data = {
    top: {
      subtitle: intl.formatMessage({ id: "mosaic.top.subtitle" }),
      title: intl.formatMessage({ id: "mosaic.top.title" }),
      content: intl.formatMessage({ id: "mosaic.top.content" })
    },
    lightBeige: {
      text: intl.formatMessage({ id: "mosaic.light_beige.text" })
    },
    image1: {
      url: "/static/images/joinourteam-mozaic-1.jpg",
      alt: intl.formatMessage({ id: "mosaic.image1.alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/joinourteam-mozaic-1_640.webp",
        medium: "/static/images/scaled/joinourteam-mozaic-1_1280.webp",
        large: "/static/images/scaled/joinourteam-mozaic-1_2560.webp"
      }
    },
    deepBlue: {
      text: intl.formatMessage({ id: "mosaic.deep_blue.text" }),
      // small: intl.formatMessage({ id: "mosaic.deep_blue.small" })
    },
    image2: {
      url: "/static/images/Sam_Jean.jpg",
      alt: intl.formatMessage({ id: "mosaic.image2.alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/Sam_Jean_640.webp",
        medium: "/static/images/scaled/Sam_Jean_1280.webp",
        large: "/static/images/scaled/Sam_Jean_2560.webp"
      }
    },
    image3: {
      url: "/static/images/joinourteam-mozaic-2.jpg",
      alt: intl.formatMessage({ id: "mosaic.image2.alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/joinourteam-mozaic-2_640.webp",
        medium: "/static/images/scaled/joinourteam-mozaic-2_1280.webp",
        large: "/static/images/scaled/joinourteam-mozaic-2_2560.webp"
      }
    },
    lightBlue: {
      text: intl.formatMessage({ id: "mosaic.light_blue.text" })
    }
  };

  return (
    <section id="mozaic" className="grid grid-cols-1 mini:grid-cols-3 tablet:grid-cols-4 self-end">
      <div id="mozaic-top" className="vw-py [--py:56] mini:[--py:68] tablet:[--py:80] laptop:[--py:100] desktop:[--py:128] vw-px [--px:16] mini:[--px:108] tablet:vw-pl tablet:[--pl:84] laptop:[--pl:108] desktop:[--pl:146] tablet:vw-pr tablet:[--pr:40] laptop:[--pr:102] desktop:[--pr:112] flex flex-col vw-gap [--gap:24] mini:[--gap:42] bg-lightBlue vw-rounded-tr [--rounded-tr:60] mini:[--rounded-tr:80] tablet:[--rounded-tr:110] laptop:[--rounded-tr:150] mini:col-span-3 tablet:col-span-2 max-mini:order-1">
        <div id="mozaik-top-subtitle" className="subtitle uppercase text-deepBlue" dangerouslySetInnerHTML={{ __html: data.top.subtitle }}></div>
        <h3 id="mozaik-top-title" className="h2">
          <span className="block text-balance" dangerouslySetInnerHTML={{ __html: data.top.title }}></span>
        </h3>
        <div id="mozaik-top-content" className="richtext body-paragraph" dangerouslySetInnerHTML={{ __html: data.top.content }}></div>
      </div>

      <div className="flex flex-col items-start mini:items-center mini:text-center justify-around aspect-[312/185] mini:aspect-square bg-lightBeige col-span-1 self-end vw-text [--text:22] tablet:[--text:26] laptop:[--text:32] desktop:[--text:36] leading-[135%] font-medium vw-px [--px:16] mini:[--px:24] vw-py [--py:60] max-mini:order-3">
        <small className="block text-balance mini:vw-max-w mini:[--max-w:130] tablet:[--max-w:170]" dangerouslySetInnerHTML={{ __html: data.lightBeige.text }}></small>
      </div>

      <div className="aspect-[312/185] mini:aspect-square col-span-1 self-end max-mini:order-2 parallax-image-wrapper">
        <img
          className="w-full h-full object-cover parallax-image"
          src={data.image1.sizes.thumbnail}
          srcSet={`${data.image1.sizes.thumbnail} 640w, ${data.image1.sizes.medium} 1280w, ${data.image1.sizes.large} 2560w`}
          sizes="100vw"
          alt={data.image1.alt} loading="lazy"
          style={{ objectPosition: "50% 10%" }}
        />
      </div>

      <div className="aspect-[312/185] mini:aspect-square col-span-1 self-end max-mini:order-2 parallax-image-wrapper">
        <img
          className="w-full h-full object-cover parallax-image"
          src={data.image2.sizes.thumbnail}
          srcSet={`${data.image2.sizes.thumbnail} 640w, ${data.image2.sizes.medium} 1280w, ${data.image2.sizes.large} 2560w`}
          sizes="100vw"
          alt={data.image1.alt} loading="lazy"
          style={{ objectPosition: "50% 0%" }}
        />
      </div>

      <div className="flex flex-col items-start mini:items-center mini:text-center justify-around aspect-[312/185] mini:aspect-square bg-deepBlue text-white col-span-1 self-end vw-text [--text:22] tablet:[--text:26] laptop:[--text:32] desktop:[--text:36] leading-[135%] font-medium vw-px [--px:16] vw-py [--py:60] [&_small]:vw-text [&_small]:[--text:19] laptop:[&_small]:[--text:24] desktop:[&_small]:[--text:26] [&_small]:vw-leading laptop:[&_small]:leading-tight [&_small]:[--leading:24] [&_small]:font-normal max-mini:order-4 mini:[&_small]:mb-[-25%] laptop:[&_small]:mb-[-15%]">
        <span dangerouslySetInnerHTML={{ __html: data.deepBlue.text }}></span>
        <small dangerouslySetInnerHTML={{ __html: data.deepBlue.small }}></small>
      </div>

      <div className="aspect-[312/185] mini:aspect-square col-span-1 self-end max-mini:order-6 parallax-image-wrapper">
        <img
          className="w-full h-full object-cover parallax-image"
          src={data.image3.sizes.thumbnail}
          srcSet={`${data.image3.sizes.thumbnail} 640w, ${data.image3.sizes.medium} 1280w, ${data.image3.sizes.large} 2560w`}
          sizes="100vw"
          alt={data.image3.alt} loading="lazy"
          style={{ objectPosition: "50% 0%" }}
        />
      </div>

      <div className="flex flex-col items-start mini:items-center mini:text-center justify-around aspect-[312/185] mini:aspect-square bg-lightBlue col-span-1 self-end vw-text [--text:22] tablet:[--text:26] laptop:[--text:32] desktop:[--text:36] leading-[135%] font-medium vw-px [--px:16] mini:[--px:24] vw-py [--py:60] max-mini:order-5">
        <span dangerouslySetInnerHTML={{ __html: data.lightBlue.text }}></span>
      </div>
    </section>
  );
};

export default Mozaic;
