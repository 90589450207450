import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import CTA from "../components/globals/CTA";
import Footer from "../components/globals/Footer";

import Jumbotron from "../components/partials/Jumbotron";
import SingleFeatureWithIcons from "../components/partials/SingleFeatureWithIcons";
import Advantages from "../components/partials/Advantages";
import Range from "../components/partials/Range";
import Mozaic from "../components/partials/Mozaic";
import Quote from "../components/partials/Quote";
import RelatedJobs from "../components/partials/RelatedJobs";

const JoinOurTeam = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const [relatedJobOffers, setRelatedJobOffers] = useState([]);
  const intl = useIntl();

  const fetchRelatedJobs = () => {
    sh.get("/sander_job_offers", {
      authenticity_token: authenticityToken,
    }).then((res) => {
      setRelatedJobOffers(res?.data);
    });
  };

  const jumbotronData = {
    background: {
      url: "/static/images/joinourteam-jumbotron.jpg",
      alt: intl.formatMessage({ id: "join_our_team.jumbotron.background_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/joinourteam-jumbotron_640.webp",
        medium: "/static/images/scaled/joinourteam-jumbotron_1280.webp",
        large: "/static/images/scaled/joinourteam-jumbotron_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "join_our_team.jumbotron.title" }),
    label: intl.formatMessage({ id: "join_our_team.jumbotron.label" }),
    leftButton: {
      url: `/${i18nLocale}/jobs`,
      title: intl.formatMessage({ id: "join_our_team.jumbotron.left_button.title" }),
      target: "_self"
    },
    rightButton: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "join_our_team.jumbotron.right_button.title" }),
      target: "_self"
    }
  };

  const quoteData = {
    additionalClasses: "bg-lightGreen text-darkGreen",
    image: {
      url: "/static/images/Ines.jpg",
      alt: "",
      sizes: {
        thumbnail: "/static/images/scaled/Ines_640.webp",
        medium: "/static/images/scaled/Ines_1280.webp",
        large: "/static/images/scaled/Ines_2560.webp"
      }
    },
    content: intl.formatMessage({ id: "join_our_team.quote.content" }),
    name: intl.formatMessage({ id: "join_our_team.quote.name" }),
    position: intl.formatMessage({ id: "join_our_team.quote.position" })
  };

  const relatedJobsData = {
    additionalClasses: "bg-white text-darkGreen",
    title: intl.formatMessage({ id: "join_our_team.related_jobs.title" }),
    cards: relatedJobOffers,
    button: {
      url: `/${i18nLocale}/jobs`,
      title: intl.formatMessage({ id: "join_our_team.related_jobs.button.title" }),
      target: "_self"
    },
    button_label: intl.formatMessage({ id: "join_our_team.related_jobs.button_label" }),
    managed_by_label: intl.formatMessage({ id: "join_our_team.related_jobs.managed_by_label" })
  };

  const ctaData = {
    image: {
      url: "/static/images/joinourteam-cta.jpg",
      alt: intl.formatMessage({ id: "join_our_team.cta.image_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/joinourteam-cta_640.webp",
        medium: "/static/images/scaled/joinourteam-cta_1280.webp",
        large: "/static/images/scaled/joinourteam-cta_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "join_our_team.cta.title" }),
    content: intl.formatMessage({ id: "join_our_team.cta.content" }),
    button: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "join_our_team.cta.button.title" }),
      target: "_self"
    }
  };

  useEffect(() => {
    fetchRelatedJobs();
  }, []);

  return (
    <div id="join-our-team">
      <Menu />
      <Header />

      <main>
        <Jumbotron data={jumbotronData} />
        <SingleFeatureWithIcons />
        <Advantages />
        <Range />
        <Mozaic />
        <Quote data={quoteData} />
        <RelatedJobs data={relatedJobsData} />
      </main>

      <CTA data={ctaData} />
      <Footer />
    </div>
  );
};

export default JoinOurTeam;
