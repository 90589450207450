import React from "react";
import { useIntl } from "react-intl";

const SingleFeatureLogos = () => {
  const intl = useIntl();

  const data = {
    subtitle: intl.formatMessage({ id: "single_feature_logos.subtitle" }),
    title: intl.formatMessage({ id: "single_feature_logos.title" }),
    content: intl.formatMessage({ id: "single_feature_logos.content" }),
    logos: [
      "/static/images/logos/ICT_Jobs.svg",
      "/static/images/logos/Indeed.svg",
      "/static/images/logos/StepStone.svg",
      "/static/images/logos/Jobat.svg",
      "/static/images/logos/Linkedin.svg",
      "/static/images/logos/Squarehub.svg"
    ]
  };

  return (
    <section id="single-feature-logos" className="vw-py [--py:50] tablet:[--py:130] desktop:[--py:150] text-darkGreen">
      <div id="single-feature-logos-container" className="gg-container">
        <div id="single-feature-logos-grid" className="gg-grid !vw-gap-y [--gap-y:42] mini:[--gap-y:80] tablet:items-center">
          <div className="col-start-1 col-span-12 mini:col-start-2 mini:col-span-16 tablet:col-start-14 tablet:col-span-11 laptop:col-start-14 laptop:col-span-10 tablet:order-2 flex flex-col vw-gap [--gap:24] mini:[--gap:42]">
            <div id="single-feature-logos-subtitle" className="subtitle uppercase text-deepBlue fadeInUp" dangerouslySetInnerHTML={{ __html: data.subtitle }} />
            <h3 id="single-feature-logos-title" className="h1 fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }} />
            <div id="single-feature-logos-content" className="richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{ __html: data.content }} />
          </div>
          <div id="single-feature-logos-wrapper" className="col-start-1 col-span-12 mini:col-start-1 mini:col-span-24 tablet:col-start-1 tablet:col-span-11 tablet:order-1 flex flex-col vw-gap [--gap:28]">
            <div id="single-feature-logos-logos-1" className="flex items-center justify-center tablet:justify-end">
              <div className="flex vw-gap [--gap:24] laptop:[--gap:30] desktop:[--gap:33] tablet:[&>*]:hidden tablet:[&>*:nth-last-child(-n+3)]:inline-flex">
                {data.logos.slice(0, 3).map((logoUrl, index) => (
                  <div key={index + 1} className="inline-flex items-center justify-center aspect-square vw-w [--w:105] mini:[--w:140] tablet:[--w:130] laptop:[--w:156] desktop:[--w:180] border border-blue vw-rounded [--rounded:14] shrink-0 grow-0">
                    <img
                      className="vw-w [--w:60] mini:[--w:90] laptop:[--w:100] desktop:[--w:110] aspect-square object-contain"
                      src={logoUrl}
                      alt={`Logo ${index + 1}`}
                      sizes="100vw"
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div id="single-feature-logos-logos-2" className="flex items-center justify-center tablet:justify-end">
              <div className="flex vw-gap [--gap:24] laptop:[--gap:30] desktop:[--gap:33] tablet:[&>*]:hidden tablet:[&>*:nth-last-child(-n+3)]:inline-flex">
                {data.logos.slice(3, 5).map((logoUrl, index) => (
                  <div key={index + 1} className="inline-flex items-center justify-center aspect-square vw-w [--w:105] mini:[--w:140] tablet:[--w:130] laptop:[--w:156] desktop:[--w:180] border border-blue vw-rounded [--rounded:14] shrink-0 grow-0">
                    <img
                      className="vw-w [--w:60] mini:[--w:90] laptop:[--w:100] desktop:[--w:110] aspect-square object-contain"
                      src={logoUrl}
                      alt={`Logo ${index + 4}`}
                      sizes="100vw"
                      loading="lazy"
                    />
                  </div>
                ))}
                <a href="https://squarehub.eu" target="_blank" rel="noopener noreferrer" className="inline-flex items-center justify-center aspect-square vw-w [--w:105] mini:[--w:140] tablet:[--w:130] laptop:[--w:156] desktop:[--w:180] border border-blue vw-rounded [--rounded:14] shrink-0 grow-0">
                  <img
                    className="vw-w [--w:60] mini:[--w:90] laptop:[--w:100] desktop:[--w:110] aspect-square object-contain"
                    src={data.logos[5]}
                    alt="Logo 6"
                    sizes="100vw"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleFeatureLogos;
