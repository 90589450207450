import React from "react";
import { useIntl } from "react-intl";

const Range = () => {
  const intl = useIntl();

  const data = {
    subtitle: intl.formatMessage({ id: "range.subtitle" }),
    title: intl.formatMessage({ id: "range.title" }),
    content: intl.formatMessage({ id: "range.content" }),
    top: {
      label: intl.formatMessage({ id: "range.top.label" }),
      number: "€88,788"
    },
    progressBar: {
      position: "15%"
    },
    left: {
      label: intl.formatMessage({ id: "range.left.label" }),
      number: "€15,670.50"
    },
    right: {
      label: intl.formatMessage({ id: "range.right.label" }),
      number: "€62,682.00"
    }
  };

  return (
    <section id="range" className="vw-py [--py:60] laptop:[--py:105] text-darkGreen">
      <div id="range-container" className="gg-container">
        <div id="range-grid" className="gg-grid !vw-gap-y [--gap-y:82] mini:[--gap-y:94]">
          <div className="col-start-1 col-span-12 mini:col-start-3 mini:col-span-20 laptop:col-start-5 laptop:col-span-16 desktop:col-start-6 desktop:col-span-14 text-center flex flex-col items-center justify-center vw-gap [--gap:24] mini:[--gap:42]">
            <div id="range-subtitle" className="subtitle uppercase text-deepBlue fadeInUp">
              {data.subtitle}
            </div>
            <h3 className="h1">
              <span className="block text-balance fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }}></span>
            </h3>
            <div id="range-content" className="richtext body-paragraph mini:vw-max-w mini:[--max-w:440] tablet:[--max-w:564] desktop:[--max-w:660] fadeInUp">
              <span className="block text-balance" dangerouslySetInnerHTML={{ __html: data.content }}></span>
            </div>
          </div>
          <div className="col-start-1 col-span-12 mini:col-start-3 mini:col-span-20 tablet:col-start-5 tablet:col-span-16 laptop:col-start-7 laptop:col-span-12 desktop:col-start-6 desktop:col-span-14 flex flex-col mini:flex-row mini:flex-wrap mini:justify-between vw-gap [--gap:48]">
            <div className="flex flex-col vw-gap [--gap:14] mini:w-full fadeInUp">
              <div className="vw-text [--text:20] leading-[175%]">
                {data.top.label}
              </div>
              <div className="font-medium vw-text [--text:36] leading-[110%] text-deepBlue">
                {data.top.number}
              </div>
            </div>
            <div className="w-full vw-h [--h:16] border-x border-deepBlue relative before:absolute before:top-1/2 before:left-0 before:transform before:-translate-y-1/2 before:w-full before:h-[1px] before:bg-deepBlue before:content-[''] mini:w-full fadeInUp">
              <div style={{ left: data.progressBar.position }} className="vw-w [--w:14] absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 before:content-[''] before:vw-w before:[--w:46] before:bg-lightBlue/50 before:aspect-square before:rounded-full before:absolute before:top-1/2 before:left-1/2 before:transform before:-translate-x-1/2 before:-translate-y-1/2 before:-z-50 after:content-[''] after:rounded-full after:aspect-square after:vw-w after:[--w:14] after:bg-deepBlue after:inline-block after:absolute after:top-1/2 after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:z-10"></div>
            </div>
            <div className="flex flex-col vw-gap [--gap:14] mini:vw-pr mini:[--pr:64] fadeInUp">
              <div className="vw-text [--text:20] leading-[175%]">
                {data.left.label}
              </div>
              <div className="font-extralight vw-text [--text:32] leading-[110%]">
                {data.left.number}
              </div>
            </div>
            <div className="flex flex-col vw-gap [--gap:14] mini:vw-pr mini:[--pr:64] fadeInUp mini:animate-delay-150">
              <div className="vw-text [--text:20] leading-[175%]">
                {data.right.label}
              </div>
              <div className="font-extralight vw-text [--text:32] leading-[110%]">
                {data.right.number}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Range;
