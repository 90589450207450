import React from "react";

const Cover = ({ data }) => {

  return (
    <section id="cover" className={`relative vw-py [--py:60] laptop:[--py:110] desktop:[--py:140] bg-black/30 text-white ${data.additionalClasses}`}>
      <div id="cover-background" className="overflow-hidden absolute top-0 left-0 w-full h-full -z-[1] parallax-image-wrapper">
        <img
          className="w-full h-full object-cover parallax-image"
          src={data.background.sizes.thumbnail}
          srcSet={`${data.background.sizes.thumbnail} 640w, ${data.background.sizes.medium} 1280w, ${data.background.sizes.large} 2560w`}
          sizes="100vw"
          alt={data.background.alt}
        />
      </div>
      <div id="cover-container" className="gg-container">
        <div id="cover-grid" className="gg-grid">
          <div className="col-span-12 mini:col-span-24 tablet:col-start-2 tablet:col-span-17 laptop:col-start-3 laptop:col-span-11 flex flex-col [.centered_&]:items-center vw-gap [--gap:42] [.centered_&]:text-center mini:[.centered_&]:col-start-4 mini:[.centered_&]:col-span-18 tablet:[.centered_&]:col-start-5 tablet:[.centered_&]:col-span-16 laptop:[.centered_&]:col-start-6 laptop:[.centered_&]:col-span-14 desktop:[.centered_&]:col-start-7 desktop:[.centered_&]:col-span-12">
            <div id="cover-subtitle" className="subtitle uppercase text-deepBlue fadeInUp">
              {data.subtitle}
            </div>
            <h3 id="cover-title" className="h1 text-white fadeInUp">
              <span className="block text-balance" dangerouslySetInnerHTML={{ __html: data.title }}></span>
            </h3>
            <div id="cover-content" className="richtext body-paragraph max-mini:hidden fadeInUp max-desktop:laptop:[.centered_&]:vw-max-w max-desktop:laptop:[.centered_&]:[--max-w:512]" dangerouslySetInnerHTML={{ __html: data.content }}></div>

            {data.button && (
              <div id="cover-button" className="fadeInUp max-mini:hidden">
                <a href={data.button.url} className="button border-deepBlue bg-deepBlue text-white hover:border-white hover:bg-white hover:text-deepBlue">
                  <svg className="button-icon" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span className="button-text">
                    {data.button.title}
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cover;
