import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import Footer from "../components/globals/Footer";
import CTA from "../components/globals/CTA";

import Jumbotron from "../components/partials/Jumbotron";
import OurTeam from "../components/partials/OurTeam";

const Team = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const jumbotronData = {
    background: {
      url: "/static/images/team-jumbotron.jpg",
      alt: "",
      sizes: {
        thumbnail: "/static/images/scaled/team-jumbotron_640.webp",
        medium: "/static/images/scaled/team-jumbotron_1280.webp",
        large: "/static/images/scaled/team-jumbotron_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "team.jumbotron.title" }),
    label: intl.formatMessage({ id: "team.jumbotron.label" })
  };

  const ctaData = {
    image: {
      url: "/static/images/whyuscandidat-cta.jpg",
      alt: "",
      sizes: {
        thumbnail: "/static/images/scaled/whyuscandidat-cta_640.webp",
        medium: "/static/images/scaled/whyuscandidat-cta_1280.webp",
        large: "/static/images/scaled/whyuscandidat-cta_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "team.cta.title" }),
    content: intl.formatMessage({ id: "team.cta.content" }),
    button: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "team.cta.button.title" }),
      target: "_self"
    }
  };

  return (
    <div id="team">
      <Menu />
      <Header />

      <main>
        <Jumbotron data={jumbotronData} />
        <OurTeam />
      </main>

      <CTA data={ctaData} />
      <Footer />
    </div>
  );
};

export default Team;
