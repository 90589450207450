import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import Footer from "../components/globals/Footer";

import Jumbotron from "../components/partials/Jumbotron";
import Form from "../components/partials/Form";
import HowItWorks from "../components/partials/HowItWorks";

const Referral = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const jumbotronData = {
    background: {
      url: "/static/images/referral-jumbotron.jpg",
      alt: intl.formatMessage({ id: "referral.jumbotron.background_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/referral-jumbotron_1280.webp",
        medium: "/static/images/scaled/referral-jumbotron_1280.webp",
        large: "/static/images/scaled/referral-jumbotron_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "referral.jumbotron.title" }),
    label: intl.formatMessage({ id: "referral.jumbotron.label" }),
  };

  const formData = {
    title: intl.formatMessage({ id: "referral.form.title" })
  };

  return (
    <div id="referral">
      <Menu />
      <Header />

      <main>
        <Jumbotron data={jumbotronData} aboutUs={true} />
        <Form data={formData} type={"referral"} />
        <HowItWorks />
      </main>

      <Footer />
    </div>
  );
};

export default Referral;
