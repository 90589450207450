import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const Intro = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const data = {
    title: intl.formatMessage({ id: "homepage.intro.title" }),
    content: intl.formatMessage({ id: "homepage.intro.content" }),
    button: {
      url: `/${i18nLocale}/about-us`,
      title: intl.formatMessage({ id: "homepage.intro.explore_our_company" }),
    },
    image: {
      url: "/static/images/home-intro.jpg",
      alt: intl.formatMessage({ id: "homepage.intro.image_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/home-intro_640.webp",
        medium: "/static/images/scaled/home-intro_1280.webp",
        large: "/static/images/scaled/home-intro_2560.webp",
      },
    },
  };

  return (
    <section
      id="intro"
      className="relative vw-py [--py:40] mini:[--py:140] laptop:[--py:150] desktop:[--py:180] text-darkGreen"
    >
      <svg
        className="max-mini:hidden absolute top-0 right-0 vw-w [--w:240] tablet:[--w:338] vw-h [--h:435] tablet:[--h:551] z-10"
        viewBox="0 0 240 435"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M217.5 434.5C97.6542 434.5 0.500001 337.346 0.500002 217.5C0.500003 97.6542 97.6542 0.500001 217.5 0.500003L337.5 0.500005L337.5 434.5L217.5 434.5Z"
          stroke="#346DE1"
        />
      </svg>
      <div id="intro-container" className="gg-container">
        <div id="intro-grid" className="gg-grid !vw-gap-y [--gap-y:40]">
          <div
            id="intro-text_wrapper"
            className="flex flex-col vw-gap [--gap:24] mini:[--gap:40] desktop:[--gap:50] col-start-1 col-span-12 mini:col-start-1 mini:col-span-13 tablet:col-start-1 tablet:col-span-12 laptop:col-start-2 laptop:col-span-10"
          >
            <h2 id="intro-title" className="h1 fadeInUp" dangerouslySetInnerHTML={{__html: data.title}}></h2>
            <div id="intro-content" className="richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{__html: data.content}}></div>
            {data.button && data.button.url && data.button.title && (
              <div id="intro-button" className="fadeInUp">
                <a
                  href={data.button.url}
                  className="button max-mini:w-full border-lightBlue bg-lightBlue text-black hover:border-deepBlue hover:bg-deepBlue hover:text-white"
                >
                  <svg
                    className="button-icon"
                    viewBox="0 0 8 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="button-text">{data.button.title}</span>
                </a>
              </div>
            )}
          </div>
          {data.image && data.image.url && (
            <div
              id="intro-image_wrapper"
              className="col-start-1 col-span-12 mini:col-start-15 mini:col-span-10 fadeInUp"
            >
              <div
                id="intro-image"
                className="overflow-hidden aspect-[280/330] mini:aspect-[324/452] tablet:aspect-[436/556] laptop:aspect-[480/571] desktop:aspect-[569/677] vw-w [--w:324] tablet:[--w:436] laptop:[--w:480] desktop:[--w:569] vw-rounded-bl [--rounded-bl:100] tablet:[--rounded-bl:150] laptop:[--rounded-bl:200]  vw-rounded-tr [--rounded-tr:100] tablet:[--rounded-tr:150] laptop:[--rounded-tr:200] parallax-image-wrapper"
              >
                <img
                  className="w-full h-full object-cover parallax-image"
                  src={data.image.sizes.thumbnail}
                  srcSet={`${data.image.sizes.thumbnail} 640w, ${data.image.sizes.medium} 1280w, ${data.image.sizes.large} 2560w`}
                  sizes="100vw"
                  alt={data.image.alt}
                  loading="lazy"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Intro;
