import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import CTA from "../components/globals/CTA";
import Footer from "../components/globals/Footer";

import Jumbotron from "../components/partials/Jumbotron";
import WhyUsDetailed from "../components/partials/WhyUsDetailed";
import SingleFeatureLogos from "../components/partials/SingleFeatureLogos";
import Cover from "../components/partials/Cover";
import Numbers from "../components/partials/Numbers";
import Quote from "../components/partials/Quote";
import Logos from "../components/partials/Logos";

const WhyUsCompany = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const jumbotronData = {
    background: {
      url: "/static/images/whyuscompany-jumbotron.jpg",
      alt: intl.formatMessage({ id: "why_us_company.jumbotron.background_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscompany-jumbotron_640.webp",
        medium: "/static/images/scaled/whyuscompany-jumbotron_1280.webp",
        large: "/static/images/scaled/whyuscompany-jumbotron_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "why_us_company.jumbotron.title" }),
    label: intl.formatMessage({ id: "why_us_company.jumbotron.label" }),
    leftButton: {
      url: `/${i18nLocale}/contact`,
      title: intl.formatMessage({ id: "why_us_company.jumbotron.left_button.title" }),
      target: "_self"
    }
  };

  const whyUsData = {
    subtitle: intl.formatMessage({ id: "why_us_detailed.subtitle" }),
    title: intl.formatMessage({ id: "why_us_detailed.title" }),
    content: intl.formatMessage({ id: "why_us_detailed.content" }),
    button: {
      url: `/${i18nLocale}/contact`,
      title: intl.formatMessage({ id: "why_us_detailed.button.title" }),
      target: "_self"
    },
    cards: [
      {
        icon: { url: "/static/images/icons/why-us-detailed-1.svg", alt: "" },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_1.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-2.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_2.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-3.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_3.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-4.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_4.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-5.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "why_us_detailed.cards.card_5.label" })
      }
    ],
    image1: {
      url: "/static/images/whyuscompany-whyus-1.jpg",
      alt: intl.formatMessage({ id: "why_us_detailed.image1_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscompany-whyus-1_640.webp",
        medium: "/static/images/scaled/whyuscompany-whyus-1_1280.webp",
        large: "/static/images/scaled/whyuscompany-whyus-1_2560.webp"
      }
    },
    image2: {
      url: "/static/images/whyuscompany-whyus-2.jpg",
      alt: intl.formatMessage({ id: "why_us_detailed.image2_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscompany-whyus-2_640.webp",
        medium: "/static/images/scaled/whyuscompany-whyus-2_1280.webp",
        large: "/static/images/scaled/whyuscompany-whyus-2_2560.webp"
      }
    }
  };

  const coverData = {
    additionalClasses: "centered",
    background: {
      url: "/static/images/whyuscompany-cover.jpg",
      alt: intl.formatMessage({ id: "why_us_company.cover.background_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscompany-cover_640.webp",
        medium: "/static/images/scaled/whyuscompany-cover_1280.webp",
        large: "/static/images/scaled/whyuscompany-cover_2560.webp"
      }
    },
    subtitle: intl.formatMessage({ id: "why_us_company.cover.subtitle" }),
    title: intl.formatMessage({ id: "why_us_company.cover.title" }),
    content: intl.formatMessage({ id: "why_us_company.cover.content" })
  };

  const quoteData = {
    additionalClasses: "bg-lightBeige text-darkGreen",
    image: {
      url: "/static/images/jade.jpg",
      alt: intl.formatMessage({ id: "why_us_company.quote.image_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/jade_640.webp",
        medium: "/static/images/scaled/jade_1280.webp",
        large: "/static/images/scaled/jade_2560.webp"
      }
    },
    content: intl.formatMessage({ id: "why_us_company.quote.content" }),
    name: intl.formatMessage({ id: "why_us_company.quote.name" }),
    position: intl.formatMessage({ id: "why_us_company.quote.position" })
  };

  const ctaData = {
    additionalClasses: "bg-lightBlue",
    image: {
      url: "/static/images/whyuscompany-cta.jpg",
      alt: "",
      sizes: {
        thumbnail: "/static/images/scaled/whyuscompany-cta_640.webp",
        medium: "/static/images/scaled/whyuscompany-cta_1280.webp",
        large: "/static/images/scaled/whyuscompany-cta_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "why_us_company.cta.title" }),
    content: intl.formatMessage({ id: "why_us_company.cta.content" }),
    button: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "why_us_company.cta.button.title" }),
      target: "_self"
    }
  };

  return (
    <div id="why-us-company">
      <Menu />
      <Header />

      <main>
        <Jumbotron data={jumbotronData} />
        <WhyUsDetailed data={whyUsData} />
        <SingleFeatureLogos />
        <Cover data={coverData} />
        <Numbers />
        <Quote data={quoteData} />
        <Logos />
      </main>

      <CTA data={ctaData} />
      <Footer />
    </div>
  );
};

export default WhyUsCompany;
