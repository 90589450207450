import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const AdvantagesExtended = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const data = {
    subtitle: intl.formatMessage({ id: "advantages_extended.subtitle" }),
    title: intl.formatMessage({ id: "advantages_extended.title" }),
    content: intl.formatMessage({ id: "advantages_extended.content" }),
    button: {
      url: `/${i18nLocale}/contact`,
      title: intl.formatMessage({ id: "advantages_extended.button.title" }),
      target: "_self"
    },
    cards: [
      {
        icon: {
          url: "/static/images/icons/advantages-extended-1.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "advantages_extended.cards.card_1.text" })
      },
      {
        icon: {
          url: "/static/images/icons/advantages-extended-2.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "advantages_extended.cards.card_2.text" })
      },
      {
        icon: {
          url: "/static/images/icons/advantages-extended-3.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "advantages_extended.cards.card_3.text" })
      },
      {
        icon: {
          url: "/static/images/icons/advantages-extended-4.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "advantages_extended.cards.card_4.text" })
      }
    ]
  };

  return (
    <div id="advantages" className="relative vw-py [--py:60] mini:[--py:90] tablet:[--py:110] laptop:[--py:140] desktop:[--py:160]">
      <div id="advantages-extended-container" className="gg-container">
        <div id="advantages-extended-grid" className="gg-grid !vw-gap-y [--gap-y:24] tablet:[--gap-y:42]">
          <div id="advantages-extended-heading" className="col-start-1 col-span-12 mini:col-start-3 mini:col-span-20 tablet:col-start-1 tablet:col-span-18 laptop:col-start-3 laptop:col-span-16 desktop:col-start-2 desktop:col-span-16 flex flex-col vw-gap [--gap:24] tablet:[--gap:42]">
            <div id="advantages-extended-subtitle" className="subtitle uppercase text-deepBlue fadeInUp">
              {data.subtitle}
            </div>
            <h3 id="advantages-extended-title" className="h1 fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }}></h3>
          </div>
          <div id="advantages-extended-text" className="col-start-1 col-span-12 mini:col-start-3 mini:col-span-20 tablet:col-start-1 tablet:col-span-9 laptop:col-start-3 laptop:col-span-9 desktop:col-start-2 desktop:col-span-8 flex flex-col vw-gap [--gap:24] tablet:[--gap:42]">
            <div id="advantages-extended-content" className="richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{ __html: data.content }}></div>
            {data.button &&
              <div id="advantages-extended-button" className="fadeInUp">
                <a href={data.button.url} target={data.button.target} className="inline-flex text-center justify-center items-center max-mini:w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full transition-colors duration-150 ease-out-cubic bg-lightBlue text-darkGreen hover:bg-deepBlue hover:text-white">
                  <svg className="vw-w [--w:7] vw-h [--h:13] object-contain overflow-visible" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999996 1.09796L6.40204 6.5L0.999997 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span dangerouslySetInnerHTML={{ __html: data.button.title }}></span>
                </a>
              </div>
            }
          </div>
          <div id="advantages-extended-cards" className="vw-pt [--pt:24] mini:[--pt:56] laptop:[--pt:24] desktop:[--pt:56] col-start-1 col-span-12 mini:col-start-3 mini:col-span-20 tablet:col-start-12 tablet:col-span-13 laptop:col-start-14 laptop:col-span-11 desktop:col-start-12 desktop:col-span-12 flex flex-col mini:flex-row mini:flex-wrap mini:justify-between mini:items-start vw-gap [--gap:52]">
            {data.cards.map((card, index) => (
              <div key={index} id="advantages-extended-card" className="flex flex-col items-center justify-center vw-gap [--gap:24] mini:vw-w mini:[--w:248] tablet:[--w:178] laptop:[--w:216] desktop:[--w:254] fadeInUp mini:even:animate-delay-150">
                <img className="aspect-square object-contain vw-w [--w:110]" src={card.icon.url} alt={card.icon.alt} loading="lazy" />
                <div className="body-paragraph text-center" dangerouslySetInnerHTML={{ __html: card.text }}></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <svg id="advantages-extended-decoration" className="pointer-events-none max-tablet:hidden aspect-[409/289] vw-w tablet:[--w:370] laptop:[--w:409] desktop:[--w:490] absolute bottom-0 left-0" viewBox="0 0 409 289" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M120.5 576.5H-22.5L-22.5 0.5L120.5 0.500006C279.558 0.500013 408.5 129.442 408.5 288.5C408.5 447.558 279.558 576.5 120.5 576.5Z" stroke="#346DE1" />
      </svg>
    </div>
  );
};

export default AdvantagesExtended;
