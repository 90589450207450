import React, { useState, useEffect } from "react";
import { belgianRegions, frenchRegions, jobStatuses } from "bundles/common/utils/constants";
import { capitalize, formatRegion, organizeCategoriesByDepartment } from "bundles/common/utils/helpers";

const CareersFilters = ({ onCategoryChange, onRegionChange, onContractChange, categories, selectedCategories, selectedRegions, selectedContracts, handleCategoryRemove, handleRegionRemove, handleContractRemove, removeAllFilters }) => {
  const [organizedCategories, setOrganizedCategories] = useState({});
  const [selectedOption, setSelectedOption] = useState("");

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory !== "") {
      const updatedSelectedCategories = [...selectedCategories];

      if (selectedCategory.startsWith("selectAll-")) {
        const department = selectedCategory.split("-")[1];
        const deptCategories = organizedCategories[department];
        const allCategoryIds = deptCategories.map((category) => category.id);

        const allSelected = allCategoryIds.every((id) => updatedSelectedCategories.includes(id));

        if (allSelected) {
          onCategoryChange(updatedSelectedCategories.filter((id) => !allCategoryIds.includes(id)));
        } else {
          onCategoryChange([...new Set([...updatedSelectedCategories, ...allCategoryIds])]);
        }
      } else {
        const categoryId = Number(selectedCategory);
        if (updatedSelectedCategories.includes(categoryId)) {
          updatedSelectedCategories.splice(
            updatedSelectedCategories.indexOf(categoryId),
            1
          );
        } else {
          updatedSelectedCategories.push(categoryId);
        }
        onCategoryChange(updatedSelectedCategories);
      }
    }
  };

  const handleRegionChange = (event) => {
    const selectedRegion = event.target.value;
    if (selectedRegion !== "") {
      const updatedSelectedRegions = [...selectedRegions];
      if (updatedSelectedRegions.includes(selectedRegion)) {
        updatedSelectedRegions.splice(
          updatedSelectedRegions.indexOf(selectedRegion),
          1
        );
      } else {
        updatedSelectedRegions.push(selectedRegion);
      }
      onRegionChange(updatedSelectedRegions);
    }
  };

  const handleContractChange = (event) => {
    const selectedContract = event.target.value;
    if (selectedContract !== "") {
      const updatedSelectedContracts = [...selectedContracts];
      if (updatedSelectedContracts.includes(selectedContract)) {
        updatedSelectedContracts.splice(
          updatedSelectedContracts.indexOf(selectedContract),
          1
        );
      } else {
        updatedSelectedContracts.push(selectedContract);
      }
      onContractChange(updatedSelectedContracts);
    }
  };

  const getCategoryNameFromId = (categoryId) => {
    categoryId = Number(categoryId);
    const category = categories.find((c) => c.id === categoryId);
    return category ? category.name : "Category Not Found";
  };

  const handleOptionChange = (event) => {
    const field = event.target.selectedOptions[0].parentElement.getAttribute("data-type");
    setSelectedOption(selectedOption);

    if (field === "Specialization") {
      handleCategoryChange(event);
    } else if (field === "Location") {
      handleRegionChange(event);
    } else if (field === "Type of Contract") {
      handleContractChange(event);
    }
  };

  useEffect(() => {
    if (categories) {
      setOrganizedCategories(organizeCategoriesByDepartment(categories));
    }
  }, [categories]);

  return (
    <section id="careers-filters" className="bg-lightBlue vw-py [--py:30]">
      <div id="careers-filter-container" className="gg-container">
        <div id="careers-filter-grid" className="gg-grid !vw-gap-y [--gap-y:15] relative">
          {/* Filters for Larger Screens */}
          <div id="careers-filters-grouped" className="max-tablet:hidden flex vw-gap [--gap:16] col-start-1 col-span-12 mini:col-start-1 mini:col-span-24 laptop:col-start-2 laptop:col-span-18 desktop:col-start-2 desktop:col-span-15">
            <div className="select relative grow fadeInUp animation-delay-0">
              <select
                name="careersFilterSpecialization"
                id="careers-filter-specialization"
                className="appearance-none w-full button-cta !leading-normal vw-h [--h:49] vw-px [--px:24] rounded-full border bg-transparent border-blue text-deepBlue"
                onChange={handleCategoryChange}
                value={[]}
              >
                <option value="">Specialization</option>
                {Object.entries(organizedCategories).map(([department, categories]) => {
                  const allSelected = categories.every((category) => selectedCategories.includes(category.id));
                  return (
                    <optgroup key={department} label={department}>
                      <option
                        value={`selectAll-${department}`}
                      >
                        {allSelected ? "☑ " : "□ "}Select All
                      </option>
                      {categories.map((category) => {
                        const isSelected = selectedCategories.includes(category.id);
                        return (
                          <option
                            key={category.id}
                            value={category.id}
                          >
                            {isSelected ? "☑ " : "□ "}{category.name}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </select>
              <svg className="vw-w [--w:13] vw-h [--h:7] absolute vw-right [--right:24] top-1/2 transform -translate-y-1/2" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0.499943L6.59796 5.90199L1.19592 0.499943" stroke="#346DE1" strokeWidth="0.884544" strokeLinecap="round" />
              </svg>
            </div>
            <div className="select relative grow fadeInUp animation-delay-150">
              <select
                name="careersFilterLocation"
                id="careers-filter-location"
                className="appearance-none w-full button-cta !leading-normal vw-h [--h:49] vw-px [--px:24] rounded-full border bg-transparent border-blue text-deepBlue"
                onChange={handleRegionChange}
                value={[]}
              >
                <option value="">Location</option>
                <optgroup label="Belgium">
                  {belgianRegions.map((region) => {
                    const isSelected = selectedRegions.includes(region);
                    return (
                      <option
                        key={`job-d-select-${region}`}
                        value={region}
                      >
                        {isSelected ? "☑ " : "□ "}{formatRegion(region)}
                      </option>
                    );
                  })}
                </optgroup>
                <optgroup label="France">
                  {frenchRegions.map((region) => {
                    const isSelected = selectedRegions.includes(region);
                    return (
                      <option
                        key={`job-d-select-${region}`}
                        value={region}
                      >
                        {isSelected ? "☑ " : "□ "}{formatRegion(region)}
                      </option>
                    );
                  })}
                </optgroup>
              </select>
              <svg className="vw-w [--w:13] vw-h [--h:7] absolute vw-right [--right:24] top-1/2 transform -translate-y-1/2" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0.499943L6.59796 5.90199L1.19592 0.499943" stroke="#346DE1" strokeWidth="0.884544" strokeLinecap="round" />
              </svg>
            </div>
            <div className="select relative grow fadeInUp animation-delay-300">
              <select
                name="careersFilterTypeOfContract"
                id="careers-filter-type-of-contract"
                className="appearance-none w-full button-cta !leading-normal vw-h [--h:49] vw-px [--px:24] rounded-full border bg-transparent border-blue text-deepBlue"
                onChange={handleContractChange}
                value={[]}
              >
                <option value="">Contract</option>
                {jobStatuses.map((status) => {
                  const isSelected = selectedContracts.includes(status);
                  return (
                    <option
                      key={`status-${status}`}
                      value={status}
                    >
                      {isSelected ? "☑ " : "□ "}{status.split("_").map((r) => capitalize(r)).join(" ")}
                    </option>
                  );
                })}
              </select>
              <svg className="vw-w [--w:13] vw-h [--h:7] absolute vw-right [--right:24] top-1/2 transform -translate-y-1/2" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0.499943L6.59796 5.90199L1.19592 0.499943" stroke="#346DE1" strokeWidth="0.884544" strokeLinecap="round" />
              </svg>
            </div>
            <div className="select relative grow fadeInUp animation-delay-[450ms]">
              {/* <select name="careersFilterLanguage" id="careers-filter-language" className="appearance-none w-full button-cta !leading-normal vw-h [--h:49] vw-px [--px:24] rounded-full border bg-transparent border-blue text-deepBlue">
                <option value="">Language</option>
                <option value="loremIpsumDolor">Lorem ipsum dolor</option>
                <option value="loremIpsumDolor">Lorem ipsum dolor</option>
                <option value="loremIpsumDolor">Lorem ipsum dolor</option>
                <option value="loremIpsumDolor">Lorem ipsum dolor</option>
              </select>
              <svg className="vw-w [--w:13] vw-h [--h:7] absolute vw-right [--right:24] top-1/2 transform -translate-y-1/2" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 0.499943L6.59796 5.90199L1.19592 0.499943" stroke="#346DE1" strokeWidth="0.884544" strokeLinecap="round" />
              </svg> */}
            </div>
          </div>

          {/* Mobile Dropdown */}
          <div className="select relative col-start-1 col-span-8 mini:col-start-1 mini:col-span-20 tablet:hidden">
            <select
              name="careersFilterAll"
              id="careers-filter-mobile"
              className="appearance-none w-full button-cta vw-py [--py:18] vw-px [--px:24] rounded-full border bg-transparent border-blue text-deepBlue"
              onChange={handleOptionChange}
              value={selectedOption}
            >
              <optgroup label="Specialization" data-type="Specialization">
                <option value="all">Select filters</option>
                {Object.entries(organizedCategories).map(([department, categories]) => (
                  categories.map((category) => {
                    const isSelected = selectedCategories.includes(category.id);
                    return (
                      <option
                        key={category.id}
                        value={category.id}
                      >
                        {isSelected ? "☑ " : "□ "}{category.name}
                      </option>
                    );
                  })
                ))}
              </optgroup>
              <optgroup label="Location" data-type="Location">
                {belgianRegions.map((region) => {
                  const isSelected = selectedRegions.includes(region);
                  return (
                    <option
                      key={`job-d-select-${region}`}
                      value={region}
                    >
                      {isSelected ? "☑ " : "□ "}{formatRegion(region)}
                    </option>
                  );
                })}
                {frenchRegions.map((region) => {
                  const isSelected = selectedRegions.includes(region);
                  return (
                    <option
                      key={`job-d-select-${region}`}
                      value={region}
                    >
                      {isSelected ? "☑ " : "□ "}{formatRegion(region)}
                    </option>
                  );
                })}
              </optgroup>
              <optgroup label="Type of Contract" data-type="Typeof Contract">
                {jobStatuses.map((status) => {
                  const isSelected = selectedContracts.includes(status);
                  return (
                    <option
                      key={`status-${status}`}
                      value={status}
                    >
                      {isSelected ? "☑ " : "□ "}{status.split("_").map((r) => capitalize(r)).join(" ")}
                    </option>
                  );
                })}
              </optgroup>
            </select>
            <svg className="vw-w [--w:13] vw-h [--h:7] absolute vw-right [--right:24] top-1/2 transform -translate-y-1/2" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 0.499943L6.59796 5.90199L1.19592 0.499943" stroke="#346DE1" strokeWidth="0.884544" strokeLinecap="round" />
            </svg>
          </div>

          {/* Clear Filter Button */}
          <button
            className="underline font-medium vw-text [--text:15] vw-leading [--leading:30] text-deepBlue hover:text-darkGreen col-span-4 text-right max-laptop:tablet:absolute max-laptop:tablet:vw-top max-laptop:tablet:[--top:67] tablet:right-0 desktop:col-start-21 fadeInUp tablet:animation-delay-600"
            onClick={removeAllFilters}
          >
            Clear filter
          </button>

          {/* Applied Filters Display */}
          <div id="careers-filters-filters" className="flex flex-wrap items-start justify-start col-start-1 col-span-12 mini:col-start-1 mini:col-span-24 tablet:col-span-20 laptop:col-start-2 laptop:col-span-22 vw-gap [--gap:24]">
            {selectedCategories.map((category) => (
              <div key={category} className="selected-filter inline-flex items-center justify-center vw-gap [--gap:3] vw-px [--px:14] vw-py [--py:4] rounded-full bg-deepBlue text-white">
                <span className="vw-text [--text:12] vw-leading [--leading:24]">{getCategoryNameFromId(category)}</span>

                <svg
                  className="vw-w [--w:17] vw-h [--h:10]"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17 10"
                  fill="none"
                  onClick={() => handleCategoryRemove(category)}
                  style={{ cursor: "pointer" }}
                >
                  <path d="M12.5485 1.05207L8.60061 5L12.5485 8.94793" stroke="white" />
                  <path d="M4.84602 1.05207L8.79395 5L4.84602 8.94793" stroke="white" />
                </svg>
              </div>
            ))}
            {selectedRegions.map((region) => (
              <div key={region} className="selected-filter inline-flex items-center justify-center vw-gap [--gap:3] vw-px [--px:14] vw-py [--py:4] rounded-full bg-deepBlue text-white">
                <span className="vw-text [--text:12] vw-leading [--leading:24]">{formatRegion(region)}</span>
                <svg
                  className="vw-w [--w:17] vw-h [--h:10]"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17 10"
                  fill="none"
                  onClick={() => handleRegionRemove(region)}
                  style={{ cursor: "pointer" }}
                >
                  <path d="M12.5485 1.05207L8.60061 5L12.5485 8.94793" stroke="white" />
                  <path d="M4.84602 1.05207L8.79395 5L4.84602 8.94793" stroke="white" />
                </svg>
              </div>
            ))}
            {selectedContracts.map((contract) => (
              <div key={contract} className="selected-filter inline-flex items-center justify-center vw-gap [--gap:3] vw-px [--px:14] vw-py [--py:4] rounded-full bg-deepBlue text-white">
                <span className="vw-text [--text:12] vw-leading [--leading:24]">{contract.split("_").map((r) => capitalize(r)).join(" ")}</span>

                <svg
                  className="vw-w [--w:17] vw-h [--h:10]"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17 10"
                  fill="none"
                  onClick={() => handleContractRemove(contract)}
                  style={{ cursor: "pointer" }}
                >
                  <path d="M12.5485 1.05207L8.60061 5L12.5485 8.94793" stroke="white" />
                  <path d="M4.84602 1.05207L8.79395 5L4.84602 8.94793" stroke="white" />
                </svg>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareersFilters;
