import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import validator from "validator";
import {
  Box,
  Chip,
  TextField,
  Typography,
  Button,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import { snakeCase } from "bundles/common/utils/helpers";

const EditDepartmentModal = ({ department, closeModal, fetchAllDepartments }) => {
  const isEdit = department != null;
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "8px",
      padding: isDesktop ? "32px 64px" : "32px",
      minWidth: isDesktop ? "620px" : "unset",
      width: isDesktop ? "620px" : "calc(100% - 32px) !important",
      height: isDesktop ? "unset" : "fit-content",
    },
    modalBox: {},
    headerBox: {
      textAlign: "center",
      marginBottom: "32px",
    },
    inputBox: {
      marginBottom: isDesktop ? "24px" : "16px",
    },
  }));
  const classes = useStyles();

  const initialValues = {
    id: department?.id || null,
    name: department?.name || "",
    email: department?.email || "",
    categories: department?.categories?.map((category) => {
      return { id: category.id, code: category.code, name: category.name };
    }) || []
  };

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = "Required";
    let not_an_email = "This does not look like an email";

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : required;
    }

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
      if (fieldValues.email && !validator.isEmail(fieldValues.email))
        temp.email = not_an_email;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });
  };

  const addCategory = (e) => {
    let newCategory = e?.target?.previousSibling?.value;
    if (newCategory) {
      e.target.previousSibling.value = "";  
      e.target.previousSibling.focus();  
      newCategory = { name: newCategory, code: snakeCase(newCategory) };
      let categories = values.categories;
      categories.push(newCategory);
      let newValues = { ...values, categories: categories };
      setValues(newValues);
      validate({ categories: categories });
    }
  };

  const deleteCategory = (deletedCategory) => {
    if (deletedCategory) {
      let categories = values.categories.filter((category) => !category.id ? category.name != deletedCategory.name : category.id != deletedCategory.id);
      let existingDeletedCategory = values.categories.find((category) => category.name == deletedCategory.name && category.id == deletedCategory.id);
      if (existingDeletedCategory && deletedCategory.id) {
        existingDeletedCategory["_destroy"] = true;
        categories.push(existingDeletedCategory);
      }
      let newValues = { ...values, categories: categories };
      setValues(newValues);
      validate({ categories: categories });
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      let updatedDepartment = {
        name: values.name,
        code: snakeCase(values.name),
        email: values.email,
        categories_attributes: values.categories.map((category) => {
          return { id: category.id, name: category.name, code: category.code, _destroy: category["_destroy"] || false };
        }),
      };

      if (values.id) {
        sh.put(`internal/departments/${department.id}`, {
          authenticity_token: authenticityToken,
          department: updatedDepartment,
        })
          .then(() => {
            closeModal();
            fetchAllDepartments();
            enqueueSnackbar(
              "Save successful!",
              {
                variant: "success",
              }
            );
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar(
              "Oops, there was an error.",
              { variant: "error" }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        sh.post("internal/departments", {
          authenticity_token: authenticityToken,
          department: updatedDepartment,
        })
          .then(() => {
            closeModal();
            fetchAllDepartments();
            enqueueSnackbar(
              "Save successful!",
              {
                variant: "success",
              }
            );
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar(
              "Oops, there was an error.",
              { variant: "error" }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.modalContainer}>
      <form className={classes.modalBox}>
        <Box className={classes.headerBox}>
          <Typography variant="h4">
            {isEdit ? `Edit the ${department.name} department` : "Create a new department"}
          </Typography>
        </Box>

        <Grid
          container
          spacing={2}
          className={classes.inputBox}
        >
          <Grid item xs={12}>
            <Box style={{ display: "flex", columnGap: "16px", alignItems: "center", justifyContent: "space-between" }}>
              <Box>
                Name:
              </Box>
              <Box style={{ width: "360px" }}>
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  value={values.name}
                  name="name"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  {...(errors["name"] && {
                    error: true,
                    helperText: errors["name"],
                  })}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: "flex", columnGap: "16px", alignItems: "center", justifyContent: "space-between" }}>
              <Box>
                Email:
              </Box>
              <Box style={{ width: "360px" }}>
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  {...(errors["email"] && {
                    error: true,
                    helperText: errors["email"],
                  })}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: "flex", columnGap: "16px", alignItems: "center", justifyContent: "space-between" }}>
              <Box>
                Add category:
              </Box>
              <Box style={{ width: "360px" }}>
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  InputProps={{
                    endAdornment: <Button size="xsmall" variant="rounded" color="primaryContained" onClick={addCategory}>Add</Button>
                  }}
                />
              </Box>
            </Box>
            <Box style={{ display: "flex", flexWrap: "wrap", columnGap: "8px", rowGap: "4px", marginTop: "16px" }}>
              {values.categories.filter((category) => !category["_destroy"]).map((category) => {
                return (
                  <Chip key={`${category.code}-modal-cat`} label={category.name} onDelete={() => deleteCategory(category)}/>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              type="submit"
              fullWidth
              variant="rounded"
              color="primaryContained"
              disabled={loading}
              loading={loading}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="rounded"
              color="secondaryOutlined"
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditDepartmentModal;
