import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import CTA from "../components/globals/CTA";
import Footer from "../components/globals/Footer";

import Hero from "../components/partials/Hero";
import Intro from "../components/partials/Intro";
import Expertise from "../components/partials/Expertise";
import Logos from "../components/partials/Logos";

const Home = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const ctaData = {
    additionalClasses: "bg-lightBlue",
    image: {
      url: "/static/images/home-cta.jpg",
      alt: intl.formatMessage({ id: "homepage.cta.image_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/home-cta_640.webp",
        medium: "/static/images/scaled/home-cta_1280.webp",
        large: "/static/images/scaled/home-cta_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "homepage.cta.title" }),
    content: intl.formatMessage({ id: "homepage.cta.content" }),
    button: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "homepage.cta.button" }),
      target: "_self"
    }
  };

  return (
    <div id="home">
      <Menu />
      <Header />

      <main>
        <Hero />
        <Intro />
        <Expertise />
        <Logos />
      </main>

      <CTA data={ctaData} />
      <Footer />
    </div>
  );
};

export default Home;
