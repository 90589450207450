import React from "react";

const TitleHero = ({ data }) => {
  return (
    <section id="title-hero" className="bg-deepBlue text-white vw-pt [--pt:120] mini:[--pt:155] laptop:[--pt:184] vw-pb [--pb:45] mini:[--pb:70] desktop:[--pb:80] relative overflow-hidden">
      <div id="title-container" className="gg-container relative z-10">
        <div id="title-grid" className="gg-grid !vw-gap-y [--gap-y:70] mini:[--gap-y:40] laptop:[--gap-y:54] desktop:[--gap-y:64]">
          <div id="title-heading-wrapper" className="flex flex-col vw-gap [--gap:32] laptop:[--gap:42] desktop:[--gap:48] col-start-1 col-span-12 mini:col-start-1 mini:col-span-21 tablet:col-start-1 tablet:col-span-22 laptop:col-start-3 laptop:col-span-20 desktop:col-start-3 desktop:col-span-21">
            <h1 id="title-heading-title" className="grand-titre fadeInUp">
              <span className="block text-balance" dangerouslySetInnerHTML={{ __html: data.title }} />
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TitleHero;
