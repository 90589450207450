import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import CTA from "../components/globals/CTA";
import Footer from "../components/globals/Footer";

import Jumbotron from "../components/partials/Jumbotron";
import AdvantagesExtended from "../components/partials/AdvantagesExtended";
import Cover from "../components/partials/Cover";
import NumbersExtended from "../components/partials/NumbersExtended";
import Expertise from "../components/partials/Expertise";

const WhyUsCandidate = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();
  const jumbotronData = {
    background: {
      url: "/static/images/whyuscandidat-jumbotron.jpg",
      alt: intl.formatMessage({ id: "why_us_candidate.jumbotron.background_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscandidat-jumbotron_640.webp",
        medium: "/static/images/scaled/whyuscandidat-jumbotron_1280.webp",
        large: "/static/images/scaled/whyuscandidat-jumbotron_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "why_us_candidate.jumbotron.title" }),
    label: intl.formatMessage({ id: "why_us_candidate.jumbotron.label" }),
    leftButton: {
      url: `/${i18nLocale}/jobs`,
      title: intl.formatMessage({ id: "why_us_candidate.jumbotron.left_button.title" }),
      target: "_self"
    },
    rightButton: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "why_us_candidate.jumbotron.right_button.title" }),
      target: "_self"
    }
  };

  const coverData = {
    additionalClasses: "centered",
    background: {
      url: "/static/images/whyuscandidat-cover.jpg",
      alt: intl.formatMessage({ id: "why_us_candidate.cover.background_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscandidat-cover_640.webp",
        medium: "/static/images/scaled/whyuscandidat-cover_1280.webp",
        large: "/static/images/scaled/whyuscandidat-cover_2560.webp"
      }
    },
    subtitle: intl.formatMessage({ id: "why_us_candidate.cover.subtitle" }),
    title: intl.formatMessage({ id: "why_us_candidate.cover.title" }),
    content: intl.formatMessage({ id: "why_us_candidate.cover.content" })
  };

  const ctaData = {
    additionalClasses: "bg-lightBlue",
    image: {
      url: "/static/images/whyuscandidat-cta.jpg",
      alt: intl.formatMessage({ id: "why_us_candidate.cta.image_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/whyuscandidat-cta_640.webp",
        medium: "/static/images/scaled/whyuscandidat-cta_1280.webp",
        large: "/static/images/scaled/whyuscandidat-cta_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "why_us_candidate.cta.title" }),
    content: intl.formatMessage({ id: "why_us_candidate.cta.content" }),
    button: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "why_us_candidate.cta.button.title" }),
      target: "_self"
    }
  };

  return (
    <div id="why-us-candidate">
      <Menu />
      <Header />

      <main>
        <Jumbotron data={jumbotronData} />
        <AdvantagesExtended />
        <Cover data={coverData} />
        <NumbersExtended />
        <Expertise />
      </main>

      <CTA data={ctaData} />
      <Footer />
    </div>
  );
};

export default WhyUsCandidate;
