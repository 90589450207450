import sh from "bundles/common/utils/sh";

export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

export const updateCurrentUser = (
  dispatch,
  authenticity_token,
  successCallback = () => {}
) => {
  sh.get("internal/current_user", {
    authenticity_token,
  })
    .then((res) => {
      dispatch({
        type: UPDATE_CURRENT_USER,
        payload: res.data,
      });
      successCallback();
    })
    .catch((err) => {
      console.log(err);
    });
};
