import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getFormattedDate } from "bundles/common/utils/helpers";
import { Box, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Button, Grid } from "@mui/material";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { archiveOffer } from "bundles/common/utils/actions/job_offers";
import { updateCurrentUser } from "bundles/common/reducers/user";
import ConfirmModal from "bundles/Internal/components/ConfirmModal";

const Scheduler = ({ jobOffer, values, setValues, loading, setLoading, handleSave, goPreviousStep}) => {
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const calculateNextRoundHour = () => {
    const now = new Date();
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    if (new Date().getMinutes() > 0 || new Date().getSeconds() > 0 || new Date().getMilliseconds() > 0) {
      now.setHours(now.getHours() + 1);
    }
    return now;
  };

  const [isArchiveConfirmModalOpen, setIsArchiveConfirmModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [postOption, setPostOption] = useState(values?.scheduled_at ? "schedule" : "now");
  const [scheduledDateTime, setScheduledDateTime] = useState(values?.scheduled_at ? values?.scheduled_at : calculateNextRoundHour);
  const minScheduledTime = calculateNextRoundHour();

  const handleInputValue = (value) => {
    setScheduledDateTime(value);
  };

  const save = (publish = false) => {
    if (publish) {
      setIsPublishModalOpen(true);
    } else {
      handleSave(publish);
    }
  };

  const schedule = () => {
    setIsScheduleModalOpen(true);
  };

  const onScheduleConfirmed = () => {
    handleSave(false, true);
  };

  const onPublishConfirmed = () => {
    handleSave(true);
  };

  const archive = () => {
    setIsArchiveConfirmModalOpen(true);
  };

  const onArchiveConfirmed = () => {
    archiveOffer(jobOffer.id, setLoading, authenticityToken, onArchiveSuccessful, onArchiveFail);
  };

  const onArchiveSuccessful = () => {
    updateCurrentUser(dispatch, authenticityToken);
    enqueueSnackbar("Offer successfully archived!", {
      variant: "success",
    });
    navigate("/internal");
  };

  const onArchiveFail = () => {
    enqueueSnackbar(
      "Oops, an error occured.",
      { variant: "error" }
    );
  };

  useEffect(() => {
    if (postOption === "schedule") {
      setValues({ ...values, scheduled_at: scheduledDateTime });
    } else if (postOption === "now") {
      setValues({ ...values, scheduled_at: null });
    }
  }, [postOption, scheduledDateTime]);

  return (
    <>
      <Box>
        {jobOffer.offer_status === "online" ? (
          <Typography>This job offer was posted on {getFormattedDate(jobOffer.published_at)}.</Typography>
        ) : (
          <FormControl>
            <Typography style={{ fontSize: "16px", marginBottom: "16px" }}>
              Do you want to schedule the post time of your job offer?
            </Typography>
            <RadioGroup
              row
              value={postOption}
              onChange={(e) => setPostOption(e.target.value)}
              style={{ marginBottom: "24px" }}
            >
              <FormControlLabel style={{ marginRight: "4rem" }} value="schedule" control={<Radio />} label="Yes, post this job offer in the future" />
              <FormControlLabel value="now" control={<Radio />} label="No, post it now" />
            </RadioGroup>
            {postOption === "schedule" && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  )}
                  label="Scheduled Post Time"
                  value={scheduledDateTime}
                  onChange={handleInputValue}
                  inputFormat="dd/MM/yyyy - ha"
                  views={["year", "month", "day", "hours"]}
                  minDateTime={minScheduledTime}
                />
              </LocalizationProvider>
            )}
          </FormControl>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <hr style={{  marginTop: "16px", marginBottom: "32px" }} />
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="squared" color="primaryOutlined" onClick={goPreviousStep}>Back</Button>
              <Box style={{ display: "flex", columnGap: "16px" }}>
                {postOption === "schedule" && values.offer_status !== "online" &&
                  <Button variant="squared" color="primaryContained" onClick={() => schedule()} disabled={loading}>Schedule</Button>
                }
                {postOption === "now" && values.offer_status !== "online" &&
                  <>
                    <Button variant="squared" color="primaryOutlined" onClick={() => save(false)} disabled={loading}>Save as Draft</Button>
                    <Button variant="squared" color="primaryContained" onClick={() => save(true)} disabled={loading}>Publish</Button>
                  </>
                }
                {values.offer_status === "online" &&
                  <>
                    <Button variant="squared" color="primaryOutlined" onClick={() => handleSave(true)} disabled={loading}>Save</Button>
                    <Button variant="squared" color="primaryContained" onClick={archive} disabled={loading}>Archive</Button>
                  </>
                }
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ConfirmModal
        isOpen={isArchiveConfirmModalOpen}
        setIsOpen={setIsArchiveConfirmModalOpen}
        handleConfirm={onArchiveConfirmed}
        label="You're about to archive this offer."
      />
      <ConfirmModal
        isOpen={isScheduleModalOpen}
        setIsOpen={setIsScheduleModalOpen}
        handleConfirm={onScheduleConfirmed}
        label={`Your job offer will be published on ${getFormattedDate(scheduledDateTime)}.`}
      />
      <ConfirmModal
        isOpen={isPublishModalOpen}
        setIsOpen={setIsPublishModalOpen}
        handleConfirm={onPublishConfirmed}
        label="You're about to publish this offer."
      />
    </>
  );
};

export default Scheduler;
