import React from "react";

const CTA = ({ data }) => {

  return (
    <div
      id="cta"
      className={`relative overflow-hidden text-darkGreen mini:before:content-[''] before:absolute before:vw-top before:[--top:0] mini:before:[--top:237] tablet:before:[--top:197] desktop:before:[--top:260] before:left-0 before:w-full before:h-full before:bg-white ${data.additionalClasses}`}
    >
      {data.image && (
        <div
          id="cta-image"
          className="w-screen aspect-[312/330] mini:vw-w [--w:548] tablet:[--w:760] laptop:[--w:912] desktop:[--w:976] mini:aspect-[548/453] tablet:aspect-[760/517] laptop:aspect-[912/589] desktop:aspect-[976/698] mini:absolute mini:top-0 mini:left-0 parallax-image-wrapper"
        >
          <img
            className="w-full h-full object-cover parallax-image"
            src={data.image.sizes.thumbnail}
            srcSet={`${data.image.sizes.thumbnail} 640w, ${data.image.sizes.medium} 1280w, ${data.image.sizes.large} 2560w`}
            sizes="100vw"
            alt={data.image.alt}
            loading="lazy"
          />
        </div>
      )}
      <div
        id="cta-decoration"
        className="max-mini:hidden absolute vw-top [--top:99] tablet:[--top:87] laptop:[--top:84] vw-left [--left:165] tablet:[--left:220] laptop:[--left:259] desktop:[--top:144] desktop:[--left:270] z-10"
      >
        <svg className="vw-w vw-h [--w:327] [--h:413] tablet:[--w:389] tablet:[--h:507] laptop:[--w:432] laptop:[--h:577] desktop:[--w:548] desktop:[--h:680]" viewBox="0 0 327 413" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M206.5 412.5C92.7293 412.5 0.500001 320.271 0.500002 206.5C0.500004 92.7294 92.7293 0.500001 206.5 0.500002L326.5 0.500004L326.5 412.5L206.5 412.5Z" stroke="#346DE1" />
        </svg>
      </div>
      <div
        id="cta-container"
        className="gg-container max-mini:w-screen max-mini:max-w-[unset] max-mini:!px-0"
      >
        <div id="cta-grid" className="gg-grid">
          <div
            id="cta-content-wrapper"
            className="bg-lightBeige flex flex-col vw-gap [--gap:32] mini:[--gap:42] desktop:[--gap:56] max-mini:vw-rounded-bl max-mini:[--rounded-bl:50] vw-rounded-br [--rounded-br:60] mini:[--rounded-br:150] vw-py [--py:66] tablet:[--py:85] vw-px [--px:16] mini:[--px:56] tablet:[--px:72] laptop:[--py:100] max-mini:text-center col-start-1 col-span-12 mini:col-start-10 mini:col-span-15 tablet:col-start-12 tablet:col-span-13 laptop:col-start-12 laptop:col-span-12 mini:z-10 mini:vw-mt [--mt:237] tablet:[--mt:197] desktop:[--mt:260]"
          >
            <div
              id="cta-title"
              className="text-balance vw-text [--text:36] mini:[--text:42] tablet:[--text:48] laptop:[--text:60] desktop:[--text:68] vw-leading [--leading:39] mini:[--leading:45] tablet:[--leading:52] laptop:[--leading:65] desktop:[--leading:73] laptop:vw-max-w laptop:[--max-w:378] desktop:[--max-w:448] text-darkGreen fadeInUp"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
            <div
              id="cta-content"
              className="body-paragraph laptop:vw-max-w laptop:[--max-w:319] desktop:[--max-w:380] fadeInUp"
            >
              <p>{data.content}</p>
            </div>
            {data.button && (
              <div id="cta-button" className="fadeInUp">
                <a href={data.button.url} className="button max-mini:w-full border-lightBlue bg-lightBlue text-black hover:border-deepBlue hover:bg-deepBlue hover:text-white" target={data.button.target}>
                  <svg className="button-icon" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span className="button-text">
                    {data.button.title}
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
