import React from "react";
import { useIntl } from "react-intl";

const Numbers = () => {
  const intl = useIntl();

  const data = {
    subtitle: intl.formatMessage({ id: "numbers.subtitle" }),
    title: intl.formatMessage({ id: "numbers.title" }),
    content: intl.formatMessage({ id: "numbers.content" }),
    cards: [
      {
        number: {
          prefix: "+",
          value: 587,
          suffix: ""
        },
        label: intl.formatMessage({ id: "numbers.cards.placements.label" })
      },
      {
        number: {
          prefix: "+",
          value: 184,
          suffix: ""
        },
        label: intl.formatMessage({ id: "numbers.cards.partners.label" })
      },
      {
        number: {
          prefix: "+",
          value: 3500,
          suffix: ""
        },
        label: intl.formatMessage({ id: "numbers.cards.interviews.label" })
      }
    ]
  };

  return (
    <section id="numbers" className="vw-py [--py:50] mini:[--py:110] desktop:[--py:150] text-darkGreen">
      <div id="numbers-container" className="gg-container">
        <div id="numbers-grid" className="gg-grid !vw-gap-y [--gap-y:70]">
          <div id="numbers-text" className="col-start-1 col-span-12 laptop:col-start-3 laptop:col-span-10 flex flex-col vw-gap [--gap:24] mini:[--gap:32]">
            <div id="numbers-subtitle" className="subtitle uppercase text-deepBlue fadeInUp" dangerouslySetInnerHTML={{ __html: data.subtitle }} />
            <h3 id="numbers-title" className="h1 fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }} />
            <div id="numbers-content" className="richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{ __html: data.content }} />
          </div>
          <div id="numbers-cards" className="col-start-1 col-span-12 mini:col-start-14 mini:col-span-11 laptop:col-start-16 laptop:col-span-8 flex flex-col vw-gap [--gap:48] mini:[--gap:56] laptop:[--gap:70] desktop:[--gap:90] mini:vw-pt mini:[--pt:70] laptop:[--pt:90] desktop:[--pt:110]">
            {data.cards.map((card, index) => (
              <div key={index} id="numbers-card" className="flex items-center justify-between vw-pb [--pb:16] tablet:[--pb:18] desktop:[--pb:22] border-b border-softGreen fadeInUp">
                <div id="numbers-card-number" className="category-number">
                  {card.number.prefix}<span data-counter={card.number.value}>{card.number.value}</span>{card.number.suffix}
                </div>
                <div id="numbers-card-label" className="vw-text [--text:16] tablet:[--text:18] laptop:[--text:20] vw-leading [--leading:35]" dangerouslySetInnerHTML={{ __html: card.label }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Numbers;
