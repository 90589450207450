import React from "react";
import { useIntl } from "react-intl";

const Logos = () => {
  const intl = useIntl();
  const data = {
    title: intl.formatMessage({ id: "logos.title" }),
    appendText: intl.formatMessage({ id: "logos.many_more" }),
    logos: [
      "/static/images/logos/Amarris_Logo.svg",
      "/static/images/logos/BBDO_Logo.svg",
      "/static/images/logos/Beobank_Logo.svg",
      "/static/images/logos/Build_Up_Logo.svg",
      "/static/images/logos/Edenred_Logo.svg",
      "/static/images/logos/KPMG_Logo.svg",
      "/static/images/logos/RSM_Logo.svg",
      "/static/images/logos/Skeyes1_Logo.svg",
      "/static/images/logos/Tax_Consult_Logo.svg",
      "/static/images/logos/Veolia_Logo.svg",
      "/static/images/logos/VRT_Logo.svg",
      "/static/images/logos/We_Invest_Logo.svg",
    ]
  };

  return (
    <section id="logos" className="bg-lightBlue text-darkGreen vw-py [--py:75] mini:[--py:125] tablet:[--py:130] laptop:[--py:150]">
      <div id="logos-container" className="gg-container flex flex-col vw-gap [--gap:28] fadeInUp">
        <h3 id="logos-title" className="h1 text-center vw-mb [--mb:30]" dangerouslySetInnerHTML={{ __html: data.title }} />

        <div id="logos-logos-1" className="flex items-center justify-center">
          <div className="flex vw-gap [--gap:26] laptop:[--gap:30] desktop:[--gap:33]">
            {data.logos.slice(0, 6).map((logoUrl, index) => (
              <div key={index + 1} className="inline-flex items-center justify-center aspect-square vw-w [--w:105] mini:[--w:140] tablet:[--w:165] laptop:[--w:190] desktop:[--w:210] border border-blue vw-rounded [--rounded:14] shrink-0 grow-0">
                <img
                  className="vw-w [--w:60] mini:[--w:90] laptop:[--w:100] desktop:[--w:110] aspect-square object-contain"
                  src={logoUrl}
                  alt={`Logo ${ index + 1}`}
                  sizes="100vw"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>

        <div id="logos-logos-2" className="flex items-center justify-center">
          <div className="flex vw-gap [--gap:26] laptop:[--gap:30] desktop:[--gap:33]">
            {data.logos.slice(6, 12).map((logoUrl, index) => (
              <div key={index + 1} className="inline-flex items-center justify-center aspect-square vw-w [--w:105] mini:[--w:140] tablet:[--w:165] laptop:[--w:190] desktop:[--w:210] border border-blue vw-rounded [--rounded:14] shrink-0 grow-0">
                <img
                  className="vw-w [--w:60] mini:[--w:90] laptop:[--w:100] desktop:[--w:110] aspect-square object-contain"
                  src={logoUrl}
                  alt={`Logo ${index + 1}`}
                  sizes="100vw"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>

        <div id="logos-append_text" className="category-title text-center vw-mt [--mt:40] fadeInUp">
          {data.appendText}
        </div>
      </div>
    </section>
  );
};

export default Logos;
