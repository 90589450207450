import createBreakpoints from "@mui/system/createTheme/createBreakpoints";
import { variants as buttonVariants } from "./button";

const breakpoints = createBreakpoints({});

const theme = {
  palette: {
    common: {
      black: "#000",
      white: "#fff",
      blue: "#2f324d",
      sh_lightgrey: "rgba(0, 0, 0, 0.06)",
      grey_background: "rgba(255, 255, 255, 0.15)",
      sh_grey: "#B2B2B2",
      main: "#079344",
      blue_grey: "#D3E0EA",
    },
    primary: {
      lighter: "#3e4c7440",
      light: "#4C5E85",
      main: "#2f324d",
      dark: "#182038",
      smoke: "#eef0f6",
      contrastText: "#fff",
    },
    error: {
      smoke: "rgba(248, 128, 120, 0.1)",
      light: "rgba(248, 128, 120, 1)",
      main: "#f44336",
      dark: "rgba(227, 27, 12, 1)",
      contrastText: "#fff",
    },
    text: {
      blue: "#2A334E",
      light_blue: "#94A3D0",
      light_green: "#C0DED6",
      white: "rgba(255, 255, 255, 0.75)",
      dark: "rgba(0, 0, 0)",
      primary: "rgba(0, 0, 0, 0.75)",
      placeholder: "rgba(0, 0, 0, 0.625)",
      green: "rgba(7, 147, 68, 1)",
      secondary: "rgba(0, 0, 0, 0.5)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      warning: "#E4A11B",
    },
  },
  components: {
    MuiButton: {
      variants: buttonVariants,
    },
  },
};
export default theme;
