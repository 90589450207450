import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import validator from "validator";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import { snakeCase, capitalize } from "bundles/common/utils/helpers";
import { departmentRegions } from "bundles/common/utils/constants";

const EditRegionalDepartmentModal = ({ regionalDepartment, departments, fetchAllRegionalDepartments, closeModal }) => {
  const isEdit = regionalDepartment != null;
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "8px",
      padding: isDesktop ? "32px 64px" : "32px",
      minWidth: isDesktop ? "660px" : "unset",
      width: isDesktop ? "660px" : "calc(100% - 32px) !important",
      height: isDesktop ? "unset" : "fit-content",
    },
    modalBox: {},
    headerBox: {
      textAlign: "center",
      marginBottom: "32px",
    },
    inputBox: {
      marginBottom: isDesktop ? "24px" : "16px",
    },
  }));
  const classes = useStyles();

  const initialValues = {
    id: regionalDepartment?.id || null,
    region: regionalDepartment?.region || departmentRegions[0],
    email: regionalDepartment?.email || "",
    name: regionalDepartment?.name || "",
    code: regionalDepartment?.code || "",
    department_id: regionalDepartment?.department_id || "",
  };

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = "Required";
    let not_an_email = "This does not look like an email";

    if ("region" in fieldValues) {
      temp.name = fieldValues.name ? "" : required;
    }

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
      if (fieldValues.email && !validator.isEmail(fieldValues.email))
        temp.email = not_an_email;
    }

    if ("department_id" in fieldValues) {
      temp.department_id = fieldValues.department_id ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    let newValues = { ...values, [name]: value };
    setValues(newValues);
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      let updatedRegionalDepartment = {
        name: values.name,
        region: values.region,
        department_id: values.department_id,
        code: snakeCase(values.name),
        email: values.email,
      };

      if (values.id) {
        sh.put(`internal/regional_departments/${regionalDepartment.id}`, {
          authenticity_token: authenticityToken,
          department: updatedRegionalDepartment,
        })
          .then(() => {
            closeModal();
            fetchAllRegionalDepartments();
            enqueueSnackbar(
              "Save successful!",
              {
                variant: "success",
              }
            );
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar(
              "Oops, there was an error.",
              { variant: "error" }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        sh.post("internal/regional_departments", {
          authenticity_token: authenticityToken,
          department: updatedRegionalDepartment,
        })
          .then(() => {
            closeModal();
            fetchAllRegionalDepartments();
            enqueueSnackbar(
              "Save successful!",
              {
                variant: "success",
              }
            );
          })
          .catch((err) => {
            console.error(err);
            enqueueSnackbar(
              "Oops, there was an error.",
              { variant: "error" }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {   
    const departmentName = departments.find((d) => d.id == values.department_id)?.name;
    const newName = `${departmentName} ${capitalize(values.region)}`;
    let newValues = { ...values, name: newName };
    setValues(newValues);
  }, [values.region, values.department_id]);

  return (
    <Box className={classes.modalContainer}>
      <form className={classes.modalBox}>
        <Box className={classes.headerBox}>
          <Typography variant="h4" style={{ whiteSpace: "nowrap" }}>
            {isEdit ? `Edit ${values.name} department` : "Create a new regional department"}
          </Typography>
        </Box>

        <Grid
          container
          spacing={2}
          className={classes.inputBox}
        >
          <Grid item xs={12}>
            <Box style={{ display: "flex", columnGap: "16px", alignItems: "center", justifyContent: "space-between" }}>
              <Box>
                Department:
              </Box>
              <Box style={{ width: "360px" }}>
                <TextField
                  select
                  style={{ width: "100%" }}
                  size="small"
                  label="Department"
                  name="department_id"
                  value={values.department_id}
                  onChange={handleInputValue}
                  {...(errors["department_id"] && {
                    error: true,
                    helperText: errors["department_id"],
                  })}
                >
                  {departments.map((department) => {
                    return (
                      <MenuItem value={department.id} key={`dept-d-select-${department.id}`}>
                        {department.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: "flex", columnGap: "16px", alignItems: "center", justifyContent: "space-between" }}>
              <Box>
                Region:
              </Box>
              <Box style={{ width: "360px" }}>
                <TextField
                  select
                  style={{ width: "100%" }}
                  size="small"
                  label="Region"
                  name="region"
                  value={values.region}
                  onChange={handleInputValue}
                >
                  {departmentRegions.map((region) => {
                    return (
                      <MenuItem value={region} key={`reg-d-select-${region}`}>
                        {capitalize(region)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: "flex", columnGap: "16px", alignItems: "center", justifyContent: "space-between" }}>
              <Box>
                Email:
              </Box>
              <Box style={{ width: "360px" }}>
                <TextField
                  style={{ width: "100%" }}
                  size="small"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  {...(errors["email"] && {
                    error: true,
                    helperText: errors["email"],
                  })}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              type="submit"
              fullWidth
              variant="rounded"
              color="primaryContained"
              disabled={loading}
              loading={loading}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="rounded"
              color="secondaryOutlined"
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditRegionalDepartmentModal;
