import React from "react";

const Pagination = ({ currentPage, setPage, pageCount }) => {
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pageCount) {
      setPage(newPage);
    }
  };

  const paginationItems = () => {
    const pages = [];
    let startPage = Math.max(2, currentPage - 2);
    let endPage = Math.min(pageCount - 1, currentPage + 2);

    if (currentPage > 4) {
      pages.push(1, "break");
    } else {
      pages.push(1);
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(page);
    }

    if (currentPage < pageCount - 3) {
      pages.push("break", pageCount);
    } else {
      pages.push(pageCount);
    }

    return pages;
  };

  return (
    <section id="pagination" className="fadeInUp">
      <div id="pagination-container" className="gg-container">
        <div id="pagination-grid" className="gg-grid">
          <nav className="flex items-center justify-center col-start-1 col-span-12 mini:col--start-1 mini:col-span-24 select-none vw-gap [--gap:16] [&_ol]:flex [&_ol]:items-center [&_ol]:justify-center [&_ol_li]:inline-flex [&_ol_a]:inline-flex [&_ol_a]:items-center [&_ol_a]:justify-center [&_ol_li]:aspect-square [&_ol_a]:aspect-square [&_ol_a]:vw-w [&_ol_a]:[--w:28] [&_ol_a]:rounded-full [&_ol_a]:text-darkGreen [&_ol_a.active]:bg-lightBlue [&_ol_a]:vw-text [&_ol_a]:[--text:18]">
            <a
              onClick={() => handlePageChange(currentPage - 1)}
              aria-label="Previous page"
              title="Previous page"
              className={currentPage === 1 ? "disabled" : ""}
            >
              <svg className="aspect-square object-contain overflow-visible vw-w [--w:30] rounded-full bg-transparent border border-lightBlue hover:bg-lightBlue transition-colord duration-150 ease-out-cubic" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.75 19.25L12 15.5L15.75 11.75" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
            <ol>
              {paginationItems().map((item, index) => (
                <li key={index} style={{ aspectRatio: "auto" }}>
                  {item === "break" ? (
                    <span style={{ fontSize: "1.5rem", color: "black", marginBottom: "6px" }}>...</span>
                  ) : (
                    <a
                      onClick={() => handlePageChange(item)}
                      className={currentPage === item ? "active" : ""}
                      style={{ width: "2.2rem", cursor: "pointer" }}
                    >
                      {item}
                    </a>
                  )}
                </li>
              ))}
            </ol>
            <a
              onClick={() => handlePageChange(currentPage + 1)}
              aria-label="Next page"
              title="Next page"
              className={currentPage === pageCount ? "disabled" : ""}
            >
              <svg className="aspect-square object-contain overflow-visible vw-w [--w:30] rounded-full bg-transparent border border-lightBlue hover:bg-lightBlue transition-colord duration-150 ease-out-cubic" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.25 11.75L19 15.5L15.25 19.25" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default Pagination;
