import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  editor: {
    height: "calc(100% - 44px)"
  },
  errorState: {
    "& > .ql-toolbar": {
      borderBottomColor: "red"
    },
    "& > .ql-container": {
      borderColor: "red"
    },
  },
}));

const TextEditor = ({ value, error, handleChange }) => {
  const classes = useStyles();

  const onChange = (content, delta, source, editor) => {
    handleChange(content);
  };

  return (
    <ReactQuill 
      theme="snow"
      value={value}
      onChange={onChange}
      className={`${classes.editor} ${error ? classes.errorState : ""}`}
    />
  );
};

export default TextEditor;