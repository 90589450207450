import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import validator from "validator";
import { Box, Button, CircularProgress, Grid, Link, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import ForgotPassword from "internal/forgot-password.svg";

const useStyles = makeStyles(() => ({
  mainBox: {
    height: "100vh",
    backgroundColor: "white",
    display: "flex"
  },
  sideBox: {
    width: "50%",
    backgroundColor: "#2A334E",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#FFF"
  },
  rightBox: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  }
}));

const ResetPassword = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const resetPasswordToken = new URLSearchParams(
    window?.location?.search
  )?.get("reset_password_token");

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
 
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = "Required";
    let notAStrongPassword = "Your password should be at least 8 characters long and include 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol";
    let passwordDoesNotMatch = "Password don't match";

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
      if (fieldValues.password) {
        if (!validator.isStrongPassword(fieldValues.password))
          temp.password = notAStrongPassword;
        else {
          temp.password = "";
          // Recalculates password match in case passwordConfirmation was already provided
          if (values.passwordConfirmation) {
            temp.passwordConfirmation = validator.equals(
              fieldValues.password,
              values.passwordConfirmation
            )
              ? ""
              : passwordDoesNotMatch;
          }
        }
      }
    }

    if ("passwordConfirmation" in fieldValues) {
      temp.passwordConfirmation = fieldValues.passwordConfirmation ? "" : required;
      if (fieldValues.passwordConfirmation) {
        if (!validator.equals(values.password, fieldValues.passwordConfirmation))
          temp.passwordConfirmation = passwordDoesNotMatch;
        else temp.passwordConfirmation = "";
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      sh.put("users/password", {
        authenticity_token: authenticityToken,
        user: {
          password: values.password,
          password_confirmation: values.passwordConfirmation,
          reset_password_token: resetPasswordToken,
        },
      })
        .then(() => {
          enqueueSnackbar(
            "Your password has been updated!",
            { variant: "success" }
          );
        })
        .catch(() => {
          enqueueSnackbar(
            "Oops, an error occured.",
            { variant: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.sideBox}>
        <h1 style={{ marginBottom: "32px", fontStyle: "italic" }}>Reset your password</h1>
        <img src={ForgotPassword} style={{ width: "40%" }}/>
      </Box>
      <Box className={classes.rightBox}>
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <TextField
                style={{ width: "400px" }}
                required
                size="small"
                type="password"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                label="Password"
                name="password"
                {...(errors["password"] && {
                  error: true,
                  helperText: errors["password"],
                })}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <TextField
                style={{ width: "400px" }}
                required
                size="small"
                type="password"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                label="Password confirmation"
                name="passwordConfirmation"
                {...(errors["passwordConfirmation"] && {
                  error: true,
                  helperText: errors["passwordConfirmation"],
                })}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                style={{ width: "400px", marginTop: "4px"}}
                type="submit"
                variant="squared"
                color="primaryContained"
                size="medium"
                disabled={loading}
              >
                <Box justifyContent="center">
                  {loading && (
                    <CircularProgress size={16} color="secondary">
                        Changing…
                    </CircularProgress>
                  )}
                  {!loading && "Change password"}
                </Box>
              </Button>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              Want to log in?{" "}
              <Link style={{ cursor: "pointer" }} href="/users/sign_in">
                Click here
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default ResetPassword;