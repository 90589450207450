import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import DepartmentsIndex from "./Departments/DepartmentsIndex";
import RegionalDepartmentsIndex from "./Departments/RegionalDepartmentsIndex";
import sh from "bundles/common/utils/sh";

const Departments = () => {
  const [departments, setDepartments] = useState([]);
  const [regionalDepartments, setRegionalDepartments] = useState([]);

  const fetchAllDepartments = () => {
    sh.get("internal/all_departments").then((res) => {
      setDepartments(res?.data?.departments || []);
    });
  };

  const fetchAllRegionalDepartments = () => {
    sh.get("internal/all_regional_departments").then((res) => {
      setRegionalDepartments(res?.data?.regional_departments || []);
    });
  };

  return (
    <Container maxWidth="xl" style={{ paddingTop: "32px" }}>
      <Box>
        <RegionalDepartmentsIndex
          departments={departments}
          regionalDepartments={regionalDepartments}
          fetchAllRegionalDepartments={fetchAllRegionalDepartments}
        />
      </Box>
      <hr style={{ marginTop: "64px", marginBottom: "16px" }} />
      <Box style={{ paddingBottom: "64px" }}>
        <DepartmentsIndex
          departments={departments}
          fetchAllDepartments={fetchAllDepartments}
        />
      </Box>
    </Container>
  );
};

export default Departments;
