import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const HowItWorks = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const data = {
    title: intl.formatMessage({ id: "how_it_works.title" }),
    cards: [
      {
        icon: {
          url: "/static/images/icons/how-it-works-1.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "how_it_works.cards.card_1.text" })
      },
      {
        icon: {
          url: "/static/images/icons/how-it-works-2.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "how_it_works.cards.card_2.text" })
      },
      {
        icon: {
          url: "/static/images/icons/how-it-works-3.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "how_it_works.cards.card_3.text" })
      }
    ]
  };

  return (
    <div id="advantages" className="relative vw-py bg-lightGreen [--py:60] mini:[--py:90] tablet:[--py:110] laptop:[--py:140] desktop:[--py:160]">
      <div id="advantages-extended-container" className="gg-container">
        <div id="advantages-extended-grid" className="gg-grid !vw-gap-y [--gap-y:24] tablet:[--gap-y:42]">
          <div id="advantages-extended-heading" className="col-start-1 col-span-24 mini:col-start-3 mini:col-span-20 tablet:col-start-1 tablet:col-span-18 laptop:col-start-3 laptop:col-span-24 desktop:col-start-1 desktop:col-span-24 flex flex-col vw-gap [--gap:24] tablet:[--gap:42]">
            <h3 id="advantages-extended-title" className="h2 fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }}></h3>
          </div>
          <div id="advantages-extended-cards" className="vw-pt [--pt:24] mini:[--pt:56] laptop:[--pt:24] desktop:[--pt:56] col-start-1 col-span-24 mini:col-start-3 mini:col-span-24 tablet:col-start-1 tablet:col-span-24 laptop:col-start-1 laptop:col-span-24 desktop:col-start-1 desktop:col-span-24 flex flex-col mini:flex-row mini:flex-wrap mini:justify-between mini:items-start vw-gap [--gap:52] laptop:flex-row laptop:flex-nowrap desktop:flex-row desktop:flex-nowrap">
            {data.cards.map((card, index) => (
              <div key={index} id="advantages-extended-card" className="flex flex-col laptop:flex-row items-center justify-center vw-gap [--gap:24] mini:vw-w mini:[--w:248] tablet:[--w:178] laptop:[--w:calc(100%/3)] desktop:[--w:calc(100%/3)] fadeInUp mini:even:animate-delay-150">
                <img className="aspect-square object-contain vw-w [--w:60] tablet:[--w:90] laptop:[--w:100] laptop:mr-2" src={card.icon.url} alt={card.icon.alt} loading="lazy" />
                <div className="body-paragraph text-center laptop:text-left" dangerouslySetInnerHTML={{ __html: card.text }}></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
