import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  MenuItem,
  ListSubheader,
  Checkbox,
  ListItemText,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
  Divider
} from "@mui/material";
import CardSelect from "bundles/Internal/components/CardSelect";
import { makeStyles, useTheme } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import { offerLanguages } from "bundles/common/utils/constants";
import { capitalize } from "bundles/common/utils/helpers";

const EditPresentationModal = ({ presentation, organizedCategories, organizedRegions, fetchAllPresentations, closeModal }) => {
  const isEdit = presentation != null;
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [selectedCategories, setSelectedCategories] = useState(presentation?.category_ids || []);
  const [selectedRegions, setSelectedRegions] = useState(presentation?.region_ids || []);
  const [resume, setResume] = useState(null);
  const [resumeFileName, setResumeFileName] = useState("No file selected");

  const useStyles = makeStyles(() => ({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "white",
      borderRadius: "8px",
      padding: isDesktop ? "32px 64px" : "32px",
      minWidth: isDesktop ? "660px" : "unset",
      width: isDesktop ? "660px" : "calc(100% - 32px) !important",
      height: isDesktop ? "unset" : "fit-content",
    },
    modalBox: {},
    headerBox: {
      textAlign: "center",
      marginBottom: "32px",
    },
    inputBox: {
      marginBottom: isDesktop ? "24px" : "16px",
    },
    uploadBtn: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 16px",
      backgroundColor: "#3f51b5",
      color: "white",
      cursor: "pointer",
      borderRadius: "4px",
      marginTop: "8px"
    },
    hiddenInput: {
      display: "none",
    },
  }));

  const classes = useStyles();

  const initialValues = {
    id: presentation?.id || null,
    candidate_name: presentation?.candidate_name || "",
    job_title: presentation?.job_title || "",
    language: presentation?.language || ""
  };

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = "Required";

    if ("candidate_name" in fieldValues) {
      temp.candidate_name = fieldValues.candidate_name ? "" : required;
    }

    if ("job_title" in fieldValues) {
      temp.job_title = fieldValues.job_title ? "" : required;
    }

    if ("language" in fieldValues) {
      temp.language = fieldValues.language ? "" : required;
    }

    if (!resume && !isEdit) {
      temp.resume = required;
    } else {
      temp.resume = "";
    }

    if (selectedCategories.length === 0) {
      temp.selectedCategories = "At least one category must be selected";
    } else {
      temp.selectedCategories = "";
    }

    if (selectedRegions.length === 0) {
      temp.selectedRegions = "At least one region must be selected";
    } else {
      temp.selectedRegions = "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };


  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCategoryChange = (selectedCategories) => {
    setSelectedCategories(selectedCategories);
  };

  const handleRegionChange = (event) => {
    setSelectedRegions(event.target.value);
  };

  const handleCardSelected = (name, value) => {
    setValues({ ...values, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setResume(file);
    setResumeFileName(file ? file.name : "No file selected");

    const tempErrors = { ...errors };
    tempErrors.resume = file ? "" : "Required";
    setErrors(tempErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (validate(values)) {
      const formData = new FormData();
      formData.append("authenticity_token", authenticityToken);
      formData.append("presentation[candidate_name]", values.candidate_name);
      formData.append("presentation[job_title]", values.job_title);
      formData.append("presentation[language]", values.language);

      selectedCategories.forEach((categoryId) => {
        formData.append("presentation[category_ids][]", categoryId);
      });
      selectedRegions.forEach((regionId) => {
        formData.append("presentation[region_ids][]", regionId);
      });

      if (resume) {
        formData.append("presentation[resume]", resume);
      }

      const request = values.id
        ? sh.put(`internal/api/presentations/${presentation.id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        : sh.post("internal/api/presentations", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

      request
        .then(() => {
          closeModal();
          fetchAllPresentations();
          enqueueSnackbar("Save successful!", { variant: "success" });
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar("Oops, there was an error.", { variant: "error" });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.modalContainer}>
      <form className={classes.modalBox}>
        <Box className={classes.headerBox}>
          <Typography
            variant="h4"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {isEdit ? `Edit Presentation for ${values.candidate_name}` : "Create a new presentation"}
          </Typography>
        </Box>

        <Grid container spacing={2} className={classes.inputBox}>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              size="small"
              label="Candidate Name"
              name="candidate_name"
              value={values.candidate_name}
              onChange={handleInputValue}
              {...(errors["candidate_name"] && {
                error: true,
                helperText: errors["candidate_name"],
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              size="small"
              label="Job Title"
              name="job_title"
              value={values.job_title}
              onChange={handleInputValue}
              {...(errors["job_title"] && {
                error: true,
                helperText: errors["job_title"],
              })}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Select Categories</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedCategories}
                onChange={(event) => handleCategoryChange(event.target.value)}
                input={<OutlinedInput label="Select Categories" />}
                renderValue={(selected) =>
                  selected
                    .map((id) => {
                      const category = Object.values(organizedCategories).flat().find((cat) => cat.id === id);
                      return category ? category.name : "";
                    })
                    .join(", ")
                }
              >
                {Object.entries(organizedCategories).map(([department, categories]) => [
                  <ListSubheader key={department}>{department}</ListSubheader>,
                  ...categories.map((category) => (
                    <MenuItem key={category.id} value={category.id} sx={{ padding: "0px 8px" }}>
                      <Checkbox checked={selectedCategories.includes(category.id)} />
                      <ListItemText primary={category.name} />
                    </MenuItem>
                  ))
                ])}
              </Select>
              {errors.selectedCategories && <div style={{ color: "red", fontSize: "12px" }}>{errors.selectedCategories}</div>}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Select Regions</InputLabel>
              <Select
                multiple
                value={selectedRegions}
                onChange={handleRegionChange}
                input={<OutlinedInput label="Select Regions" />}
                renderValue={(selected) =>
                  selected
                    .map((id) => {
                      const region = Object.values(organizedRegions).flat().find((cat) => cat.id === id);
                      return region ? region.name : "";
                    })
                    .join(", ")
                }
              >
                {Object.entries(organizedRegions).map(([country, regions]) => [
                  <ListSubheader key={country}>{capitalize(country)}</ListSubheader>,
                  ...regions.map((region) => (
                    <MenuItem key={region.id} value={region.id} sx={{ padding: "0px 8px" }}>
                      <Checkbox checked={selectedRegions.includes(region.id)} />
                      <ListItemText primary={region.name} />
                    </MenuItem>
                  ))
                ])}
              </Select>
              {errors.selectedRegions && <div style={{ color: "red", fontSize: "12px" }}>{errors.selectedRegions}</div>}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Box style={{ display: "flex", rowGap: "12px", alignItems: "start", flexDirection: "column" }}>
              <Box style={{ minWidth: "100px" }}>
                <h3>Please select the language of the presentation:</h3>
              </Box>
              <Grid item xs={12}>
                <Box style={{ flexGrow: 1, maxWidth: "600px" }}>
                  <CardSelect
                    fieldName="language"
                    selected={values.language}
                    values={offerLanguages.map((language) => ({
                      code: language.code,
                      label: language.value,
                    }))}
                    onChange={handleCardSelected}
                  />

                  {errors.language && <div style={{ color: "red", fontSize: "12px", marginTop: "8px" }}>{errors.language}</div>}
                </Box>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ marginTop: "8px", marginBottom: "8px" }} />
            <input
              type="file"
              id="resume"
              name="resume"
              className={classes.hiddenInput}
              onChange={handleFileChange}
              accept="application/pdf"
            />
            <label htmlFor="resume" className={classes.uploadBtn}>
              {isEdit & resume == null ? "Edit Presentation (PDF)*" : "Upload Presentation (PDF)"}
            </label>
            <Typography variant="body2" color="textSecondary" style={{ marginTop: "8px" }}>
              {isEdit & resume == null ? "*This action will remove the previously uploaded presentation." : resumeFileName}
            </Typography>
            {errors.resume && <div style={{ color: "red", fontSize: "12px" }}>{errors.resume}</div>}
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={handleSubmit}
            >
              {isEdit ? "Save" : "Create"}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth variant="outlined" onClick={closeModal}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default EditPresentationModal;
