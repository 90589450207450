import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getDepartmentBgColorClass, getDepartmentIconColor } from "bundles/common/utils/helpers";
import slugify from "slugify";
import { capitalize, formatRegion } from "bundles/common/utils/helpers";

const JobListings = ({ jobOffers }) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const data = {
    cta: {
      title: intl.formatMessage({ id: "job_listings.cta.title" }),
      button: {
        url: `/${i18nLocale}/contact`,
        title: intl.formatMessage({ id: "job_listings.cta.button" }),
        target: "_self"
      }
    }
  };

  const slugifyOptions = {
    lower: true,
    strict: true,
    remove: /[*+~.()'"!:@]/g
  };

  return (
    <section id="job-listings" className="vw-py [--py:50] mini:[--py:90]">
      <div id="job-listings-container" className="gg-container">
        <div id="job-listings-grid" className="grid grid-cols-12 mini:grid-cols-24 vw-gap-x [--gap-x:20] laptop:[--gap-x:36] vw-gap-y [--gap-y:20] mini:[--gap-y:40] desktop:[--gap-y:48] laptop:vw-px laptop:[--px:48] desktop:[--px:54] [&_*]:order-7 [&_*:nth-child(-n+5)]:order-1">
          {/* Job Listing Cards */}
          {jobOffers?.map((jobOffer, index) => (
            <a
              href={`/${i18nLocale}/jobs/${slugify(jobOffer.job_title, slugifyOptions)}/${jobOffer.id}`}
              target="_blank"
              rel="noreferrer"
              key={`card-${index + 1}`}
              id="job-listing"
              className="col-span-12 tablet:col-span-8 vw-p [--p:20] laptop:[--p:26] vw-rounded-tr [--rounded-tr:50] laptop:[--rounded-tr:60] vw-rounded-bl [--rounded-bl:50] laptop:[--rounded-bl:60] border border-lightBlue hover:border-deepBlue/50 flex flex-col items-center justify-between vw-gap [--gap:20] transition-colors duration-150 ease-out-cubic fadeInUp"
            >
              <div id="job-listing-head" className="flex flex-col items-start justify-start vw-gap [--gap:20] laptop:[--gap:24]">
                <div id="job-listing-tag" className={`inline-block vw-text [--text:14] leading-none vw-px [--px:8] vw-py [--py:4] text-darkGreen ${getDepartmentBgColorClass(jobOffer.category?.department?.code)}`}>
                  {jobOffer.category?.name}
                </div>
                <div id="job-listing-title" className="category-title text-darkGreen">
                  {jobOffer.job_title}
                </div>
                <div id="job-listing-infos" className="flex flex-col vw-gap [--gap:6]">
                  <div id="job-listing-location" className="inline-flex justify-start items-center vw-gap [--gap:12] vw-text [--text:14] vw-leading [--leading:16] text-deepBlue">
                    <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.318008 7.82315C0.318008 3.90339 3.33913 0.513428 7.39598 0.513428C11.4363 0.513428 14.4707 3.957 14.4707 7.82315C14.4707 12.7962 7.39598 18.5134 7.39598 18.5134C7.39598 18.5134 0.318008 12.7962 0.318008 7.82315ZM7.39494 9.23956C8.34352 9.23956 9.1125 8.47058 9.1125 7.522C9.1125 6.57342 8.34352 5.80444 7.39494 5.80444C6.44636 5.80444 5.67738 6.57342 5.67738 7.522C5.67738 8.47058 6.44636 9.23956 7.39494 9.23956Z" fill={getDepartmentIconColor(jobOffer.category?.department?.code)} />
                    </svg>
                    <span>
                      {jobOffer.city}, {formatRegion(jobOffer.region)}
                    </span>
                  </div>
                  <div id="job-listing-type" className="inline-flex justify-start items-center vw-gap [--gap:12] vw-text [--text:14] vw-leading [--leading:16] text-deepBlue">
                    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.33975 1.51343L1.31801 6.53517V17.4917C1.31801 17.7742 1.43023 18.0451 1.63 18.2449C1.82977 18.4447 2.10071 18.5569 2.38323 18.5569H14.2528C14.5353 18.5569 14.8062 18.4447 15.006 18.2449C15.2058 18.0451 15.318 17.7742 15.318 17.4917V2.57865C15.318 2.29613 15.2058 2.02519 15.006 1.82542C14.8062 1.62565 14.5353 1.51343 14.2528 1.51343H6.33975Z" stroke={getDepartmentIconColor(jobOffer.category?.department?.code)} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.47296 6.53517H5.04905C5.43246 6.53517 5.80017 6.38286 6.07128 6.11175C6.34239 5.84063 6.4947 5.47293 6.4947 5.08951V1.51343L1.47296 6.53517Z" fill={getDepartmentIconColor(jobOffer.category?.department?.code)} stroke={getDepartmentIconColor(jobOffer.category?.department?.code)} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span>
                      {jobOffer.job_status.charAt(0).toUpperCase() + jobOffer.job_status.slice(1)}
                    </span>
                  </div>
                </div>
                {jobOffer?.salary_type != "no-salary" &&
                  <div id="job-listing-salary" className="vw-text [--text:14] vw-leading [--leading:16] text-deepBlue">
                    {jobOffer.min_salary === jobOffer.max_salary
                      ? `${jobOffer.min_salary}${jobOffer.salary_type === "daily" ? "€ / day" : ".000€ / year"}`
                      : `${jobOffer.min_salary}${jobOffer.salary_type === "daily" ? "€ - " : ".000€ - "}${jobOffer.max_salary}${jobOffer.salary_type === "daily" ? "€ / day" : ".000€ / year"}`}
                  </div>
                }
                <div
                  id="job-listing-excerpt"
                  className="small-paragraph !font-light"
                  dangerouslySetInnerHTML={{ __html: jobOffer.summary }}
                />
              </div>
              <div id="job-listing-bottom" className="flex justify-between items-center w-full max-tablet:mini:vw-max-w mini:max-tablet:[--max-w:230]">
                <div id="job-listing-button">
                  <div className="inline-flex vw-gap [--gap:6] items-center justify-start text-deepBlue button-cta !leading-none">
                    <span>
                      Apply
                    </span>
                    <svg className="vw-w [--w:20] object-contain overflow-visible" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="1.18213" width="18.5" height="18.5" rx="9.25" stroke="#CAE5FF" />
                      <path d="M8.75 6.68213L12.5 10.4321L8.75 14.1821" stroke="#346DE1" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </div>
                {jobOffer.user && (
                  <div id="job-listing-manager" className="flex items-center justify-end vw-gap [--gap:10]">
                    <div id="job-listing-manager-name" className="text-right font-light vw-text [--text:10] leading-loose">
                      Managed by<br />
                      {jobOffer.user.first_name}&nbsp;{jobOffer.user.last_name}
                    </div>
                    <div id="job-listing-manager-image" className="aspect-square vw-w [--w:44] rounded-full overflow-hidden shrink-0">
                      <img
                        className="w-full h-full object-cover"
                        src={jobOffer.user.avatar_url}
                        alt={`${jobOffer.user.first_name}'s profile picture`} loading="lazy"
                      />
                    </div>
                  </div>
                )}
              </div>
            </a>
          ))}

          {/* CTA Section */}
          <div
            id="job-listing-cta"
            className="col-span-12 tablet:col-span-8 vw-min-h [--min-h:420] vw-px [--px:24] mini:[--px:35] vw-pt [--pt:50] vw-pb [--pb:26] vw-rounded-tl [--rounded-tl:50] laptop:[--rounded-tl:60] vw-rounded-br [--rounded-br:50] laptop:[--rounded-br:60] bg-lightBlue flex flex-col justify-between vw-gap [--gap:20] fadeInUp !order-6"
          >
            <div id="job-listing-cta-title" className="vw-text [--text:26] laptop:[--text:32] vw-leading [--leading:33] laptop:[--leading:40] text-deepBlue max-mini:vw-max-w max-mini:[--max-w:220]">
              {data.cta.title}
            </div>
            <div id="job-listing-cta-button" className="w-full">
              <a href={data.cta.button.url} target={data.cta.button.target} className="inline-flex text-center justify-center items-center w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full bg-white text-deepBlue">
                <span>
                  {data.cta.button.title}
                </span>
              </a>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default JobListings;
