import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import CTA from "../components/globals/CTA";
import Footer from "../components/globals/Footer";

import Jumbotron from "../components/partials/Jumbotron";
import Results from "../components/partials/Results";
import ContentWithLargeAndSmallImg from "../components/partials/ContentWithLargeAndSmallImg";
import Logos from "../components/partials/Logos";
import Expertise from "../components/partials/Expertise";
import Cover from "../components/partials/Cover";
import SingleFeature from "../components/partials/SingleFeature";

const AboutUs = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const jumbotronData = {
    background: {
      url: "/static/images/4_managers.jpg",
      alt: intl.formatMessage({ id: "about_us.jumbotron.background_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/4_managers_640.webp",
        medium: "/static/images/scaled/4_managers_1280.webp",
        large: "/static/images/scaled/4_managers_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "about_us.jumbotron.title" }),
    label: intl.formatMessage({ id: "about_us.jumbotron.label" }),
    rightButton: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "about_us.jumbotron.right_button.title" }),
      target: "_self"
    }
  };

  const coverData = {
    background: {
      url: "/static/images/about-cover.jpg",
      alt: intl.formatMessage({ id: "about_us.cover.background_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/about-cover_640.webp",
        medium: "/static/images/scaled/about-cover_1280.webp",
        large: "/static/images/scaled/about-cover_2560.webp"
      }
    },
    subtitle: intl.formatMessage({ id: "about_us.cover.subtitle" }),
    title: intl.formatMessage({ id: "about_us.cover.title" }),
    content: intl.formatMessage({ id: "about_us.cover.content" }),
    button: {
      url: `/${i18nLocale}/team`,
      title: intl.formatMessage({ id: "about_us.cover.button.title" }),
      target: "_self"
    }
  };

  const ctaData = {
    additionalClasses: "bg-lightBeige",
    image: {
      url: "/static/images/about-cta.jpg",
      alt: intl.formatMessage({ id: "about_us.cta.image_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/about-cta_640.webp",
        medium: "/static/images/scaled/about-cta_1280.webp",
        large: "/static/images/scaled/about-cta_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "about_us.cta.title" }),
    content: intl.formatMessage({ id: "about_us.cta.content" }),
    button: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "about_us.cta.button.title" }),
      target: "_self"
    }
  };

  return (
    <div id="about-us">
      <Menu />
      <Header />

      <main>
        <Jumbotron data={jumbotronData} aboutUs={true} />
        <Results />
        <ContentWithLargeAndSmallImg />
        <Logos />
        <Expertise />
        <Cover data={coverData} />
        <SingleFeature />
      </main>

      <CTA data={ctaData} />
      <Footer />
    </div>
  );
};

export default AboutUs;
