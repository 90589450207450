import React from "react";
import { useIntl } from "react-intl";

const SingleFeature = () => {
  const intl = useIntl();

  const data = {
    image: {
      url: "/static/images/about-partners.jpg",
      alt: intl.formatMessage({ id: "single_feature.image_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/about-partners_640.webp",
        medium: "/static/images/scaled/about-partners_1280.webp",
        large: "/static/images/scaled/about-partners_2560.webp"
      }
    },
    subtitle: intl.formatMessage({ id: "single_feature.subtitle" }),
    title: intl.formatMessage({ id: "single_feature.title" }),
    content: intl.formatMessage({ id: "single_feature.content" })
  };

  return (
    <section id="single-feature" className="relative vw-py [--py:60] mini:[--py:130] desktop:[--py:170] bg-lightBeige text-darkGreen">
      {data.image && (
        <div id="single-feature-image" className="max-mini:static mini:absolute mini:vw-top mini:[--top:130] desktop:[--top:170] mini:left-0 mini:vw-w mini:[--w:436] tablet:[--w:516] laptop:[--w:636] desktop:[--w:754] max-mini:vw-mx max-mini:[--mx:16] aspect-[28/33] mini:aspect-[436/551] tablet:aspect-[516/551] laptop:aspect-[636/620] desktop:aspect-[754/735] vw-rounded-br [--rounded-br:90] mini:[--rounded-br:150] parallax-image-wrapper">
          <div id="single-feature-image-overlay" className="absolute top-0 right-0 w-3/4 h-full z-[1] opacity-[.75]" style={{ background: "linear-gradient(to right, transparent 0%, white 100%)" }}></div>
          <img
            className="w-full mini:h-full object-cover parallax-image"
            src={data.image.sizes.thumbnail}
            srcSet={`${data.image.sizes.thumbnail} 640w, ${data.image.sizes.medium} 1280w, ${data.image.sizes.large} 2560w`}
            sizes="100vw"
            alt={data.image.alt}
          />
        </div>
      )}
      <div id="single-feature-container" className="gg-container relative z-10 mini:vw-py mini:[--py:24] tablet:[--py:36] laptop:[--py:60] desktop:[--py:80]">
        <div id="single-feature-grid" className="gg-grid">
          <div className="col-span-12 mini:col-start-12 mini:col-span-13 laptop:col-start-12 laptop:col-span-11 flex flex-col vw-gap [--gap:42] max-mini:vw-pt max-mini:[--pt:56]">
            <div id="single-feature-subtitle" className="subtitle uppercase text-deepBlue fadeInUp">{data.subtitle}</div>
            <h3 id="single-feature-title" className="h1 fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }}></h3>
            <div id="single-feature-content" className="richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{ __html: data.content }}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleFeature;
