import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Box, Grid, Button, CircularProgress, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import validator from "validator";
import sh from "bundles/common/utils/sh";
import WelcomeImg from "internal/happy-announcement.svg";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    height: "100vh",
    backgroundColor: "white",
    display: "flex"
  },
  sideBox: {
    width: "50%",
    overflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.dark,
  },
  formBox: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  loginCard: {
    width: "440px"
  }
}));

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const AcceptInvitation = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const currentUser = useSelector((state) => state.current_user);
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    password: "",
    password_confirmation: "",
  };

  const invitationToken = query.get("invitation_token");

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = true;
    let notAStrongPassword = "Your password should be at least 8 characters long and include 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol";

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
      if (
        fieldValues.password &&
        !validator.isStrongPassword(fieldValues.password)
      ) {
        temp.password = notAStrongPassword;
      }
    }

    if ("password_confirmation" in fieldValues) {
      temp.password_confirmation = fieldValues.password_confirmation ? "" : required;
      if (fieldValues.password && fieldValues.password_confirmation != fieldValues.password) {
        temp.password_confirmation = "Your password do not match";
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      sh.put("/users/invitation", {
        authenticity_token: authenticityToken,
        password: values.password,
        password_confirmation: values.password_confirmation,
        invitation_token: invitationToken
      })
        .then(() => {
          setLoading(false);
          enqueueSnackbar("Invitation accepted!", {
            variant: "success",
          });
          setTimeout(() => {
            window.location.pathname = "/internal";
          }, 500);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.sideBox}>
        <h1 style={{ marginBottom: "8px" }}>Welcome, {currentUser.first_name} !</h1>
        <h3 style={{ marginBottom: "64px" }}>Please choose a password to activate your account</h3>
        <img style={{ height: "33%" }} src={WelcomeImg} />
      </Box>
      <Box className={classes.formBox}>
        <form onSubmit={handleSubmit} style={{ width: "min(50%, 600px)"}}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                type="password"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name="password"
                label="Password"
                {...(errors["password"] && {
                  error: true,
                  helperText: errors["password"],
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                type="password"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name="password_confirmation"
                label="Password confirmation"
                {...(errors["password_confirmation"] && {
                  error: true,
                  helperText: errors["password_confirmation"],
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="rounded"
                color="primaryContained"
                size="medium"
                disabled={loading}
              >
                <Box justifyContent="center">
                  {loading && (
                    <CircularProgress size={16} color="secondary">
                      Accepting…
                    </CircularProgress>
                  )}
                  {!loading && "Set your password"}
                </Box>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default AcceptInvitation;
