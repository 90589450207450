import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import sh from "bundles/common/utils/sh";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import Footer from "../components/globals/Footer";

import WhyUsDetailed from "../components/partials/WhyUsDetailed";
import TitleHeroBis from "../components/partials/TitleHeroBis";

const Presentation = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const { public_access_token } = useParams();
  const intl = useIntl();

  const [presentation, setPresentation] = useState(null);
  const [loading, setLoading] = useState(true);

  const whyUsData = {
    subtitle: intl.formatMessage({ id: "presentation.subtitle" }),
    title: intl.formatMessage({ id: "presentation.title" }),
    content: intl.formatMessage({ id: "presentation.content" }),
    highlights: intl.formatMessage({ id: "presentation.highlights" }),
    button: {
      url: presentation
        ? presentation?.resume_url
        : `/${i18nLocale}/contact`,
      title: presentation
        ? intl.formatMessage({ id: "presentation.open" })
        : intl.formatMessage({ id: "presentation.contact" }),
      target: presentation?.resume_url ? "_blank" : "_self",
    },
    cards: [
      {
        icon: { url: "/static/images/icons/why-us-detailed-1.svg", alt: "" },
        label: intl.formatMessage({ id: "presentation.cards.card_1.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-2.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "presentation.cards.card_2.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-3.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "presentation.cards.card_3.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-4.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "presentation.cards.card_4.label" })
      },
      {
        icon: {
          url: "/static/images/icons/why-us-detailed-5.svg",
          alt: ""
        },
        label: intl.formatMessage({ id: "presentation.cards.card_5.label" })
      }
    ]
  };

  const titleData = {
    title: presentation?.job_title,
    button: {
      url: presentation?.resume_url,
      title: presentation?.resume_url
        ? intl.formatMessage({ id: "presentation.open" })
        : intl.formatMessage({ id: "presentation.not_available" }),
      target: presentation?.resume_url ? "_blank" : "_self",
    },
  };

  const fetchPresentation = () => {
    sh.get(`/internal/api/presentations/${public_access_token}`, {
      authenticity_token: authenticityToken,
    }).then((res) => {
      setPresentation(res?.data);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchPresentation();
  }, []);

  return (
    <div id="contact">
      <Menu />
      <Header />

      <main>
        <TitleHeroBis data={titleData} />
        <WhyUsDetailed data={whyUsData} />
      </main>

      <Footer />
    </div>
  );
};

export default Presentation;
