import React from "react";
import { Provider } from "react-redux";
import appStore from "bundles/common/store/appStore";
import MarketingContainer from "bundles/Marketing/container/MarketingContainer";
import { SnackbarProvider } from "notistack";

import "../styles/app.scss";

const MarketingApp = (props, railsContext) => {
  return () => (
    <Provider store={appStore(Object.assign(railsContext, props))}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MarketingContainer {...props} />
      </SnackbarProvider>
    </Provider>
  );
};

export default MarketingApp;
