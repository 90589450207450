import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const CareersHero = ({ handleSearchSubmit, setSearchQuery, searchQuery }) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();


  const data = {
    title: intl.formatMessage({ id: "career_hero.title" }),
    content: intl.formatMessage({ id: "career_hero.content" }),
    cta: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "career_hero.cta.title" }),
      target: "_self"
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <section id="careers-hero" className="bg-deepBlue text-white vw-pt [--pt:120] mini:[--pt:155] laptop:[--pt:184] vw-pb [--pb:45] mini:[--pb:70] desktop:[--pb:80] relative overflow-hidden">
      <div id="careers-container" className="gg-container relative z-10">
        <div id="careers-grid" className="gg-grid !vw-gap-y [--gap-y:70] mini:[--gap-y:40] laptop:[--gap-y:54] desktop:[--gap-y:64]">
          <div id="careers-heading-wrapper" className="flex flex-col vw-gap [--gap:32] laptop:[--gap:42] desktop:[--gap:48] col-start-1 col-span-12 mini:col-start-1 mini:col-span-21 tablet:col-start-1 tablet:col-span-22 laptop:col-start-3 laptop:col-span-20 desktop:col-start-3 desktop:col-span-21">
            <h1 id="careers-heading-title" className="grand-titre fadeInUp">
              <span className="block text-balance" dangerouslySetInnerHTML={{ __html: data.title }} />
            </h1>
            <div id="careers-heading-content" className="chapeau tablet:vw-max-w tablet:[--max-w:560] desktop:[--max-w:640] fadeInUp animation-delay-150">
              {data.content}
            </div>
          </div>
          <search id="careers-search" className="relative col-start-1 col-span-12 mini:col-start-1 mini:col-span-10 tablet:col-start-1 tablet:col-span-8 laptop:col-start-3 laptop:col-span-6 fadeInUp animation-delay-300">
            <form action="" method="POST" onSubmit={handleSearchSubmit}>
              <input
                type="search"
                id="careers-search-input"
                placeholder="Search"
                className="vw-px [--px:24] vw-py [--py:13] w-full rounded-full border border-white bg-transparent text-white button-cta placeholder:text-white transition-colors duration-150 ease-out-cubic"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <button
                type="submit"
                id="careers-search-submit"
                className="absolute vw-right [--right:7] vw-top [--top:5] inline-flex aspect-square justify-center items-center rounded-full vw-w [--w:38] bg-lightBlue hover:bg-white"
              >
                <svg className="aspect-square vw-w [--w:18] object-contain overflow-visible" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="7.5" cy="7.5" r="7" stroke="#002830" />
                  <line x1="12.3536" y1="13.1464" x2="16.3536" y2="17.1464" stroke="black" />
                </svg>
              </button>
            </form>
          </search>
          {data.cta && (
            <div id="careers-cta_wrapper" className="max-mini:hidden absolute bottom-0 vw-right mini:[--right:52] tablet:[--right:85] laptop:[--right:108] desktop:[--right:150] transition-colors duration-150 ease-out-cubic fadeInUp animation-delay-500">
              <a href={data.cta.url} target={data.cta.target} className="button border-white bg-white text-deepBlue hover:border-lightBlue hover:bg-lightBlue">
                <svg className="button-icon" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.741943 9.125V14.125H16.5667V9.125" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M8.72437 11.2383L8.72436 1.21226" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.77979 2.94922L8.83658 0.999617L10.8934 2.94922" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="button-text">
                  {data.cta.title}
                </span>
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CareersHero;
