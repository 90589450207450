import React from "react";
import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  confirmModal: {
    padding: "16px !important",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "16px",
  },
}));

const ConfirmModal = ({ isOpen, setIsOpen, handleConfirm, label, onCloseCallback = () => {}}) => {
  const classes = useStyles();

  const handleConfirmClick = (e) => {
    e.preventDefault();
    setIsOpen(false);
    handleConfirm();
  };

  const onClose = () => {
    setIsOpen(false);
    onCloseCallback();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Box className={classes.confirmModal}>
        <DialogTitle className={classes.header}>
          <Typography variant="h4">
            Are you sure?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {label}
          </Typography>
          <Typography>
            Do you wish to proceed?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            variant="rounded"
            color="primaryContained"
            style={{ width: "140px" }}
            onClick={handleConfirmClick}
          >
            Confirm
          </Button>
          <Button
            variant="rounded"
            color="secondaryOutlined"
            style={{ width: "140px" }}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmModal;
