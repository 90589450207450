import React from "react";
import { useIntl } from "react-intl";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import Footer from "../components/globals/Footer";

import Jumbotron from "../components/partials/Jumbotron";
import Form from "../components/partials/Form";
import Maps from "../components/partials/Maps";

const Contact = () => {
  const intl = useIntl();

  const jumbotronData = {
    background: {
      url: "/static/images/contact-jumbotron.jpg",
      alt: intl.formatMessage({ id: "contact.jumbotron.background_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/contact-jumbotron_640.webp",
        medium: "/static/images/scaled/contact-jumbotron_1280.webp",
        large: "/static/images/scaled/contact-jumbotron_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "contact.jumbotron.title" }),
    label: intl.formatMessage({ id: "contact.jumbotron.label" })
  };

  const formData = {
    title: intl.formatMessage({ id: "contact.form.title" })
  };

  return (
    <div id="contact">
      <Menu />
      <Header />

      <main>
        <Jumbotron data={jumbotronData} />
        <Form data={formData} type={"contact"} />
        {/* <Maps /> */}
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
