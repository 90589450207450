import sh from "bundles/common/utils/sh";

export const duplicateOffer = (offerId, setLoading, authenticityToken, successCallback = () => {}, failCallback = () => {}) => {
  setLoading(true);
  sh.post(`internal/job_offers/duplicate/${offerId}`, {
    authenticity_token: authenticityToken
  }).then(() => {
    successCallback();
  }).catch(() => {
    failCallback();
  }).finally(() => {
    setLoading(false);
  });
};

export const archiveOffer = (offerId, setLoading, authenticityToken, successCallback = () => {}, failCallback = () => {}) => {
  setLoading(true);
  sh.put(`internal/job_offers/archive/${offerId}`, {
    authenticity_token: authenticityToken
  }).then(() => {
    successCallback();
  }).catch(() => {
    failCallback();
  }).finally(() => {
    setLoading(false);
  });
};

export const deleteOffer = (offerId, setLoading, authenticityToken, successCallback = () => {}, failCallback = () => {}) => {
  setLoading(true);
  sh.delete(`internal/job_offers/${offerId}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: {
      authenticity_token: authenticityToken
    },
  }).then(() => {
    successCallback();
  }).catch(() => {
    failCallback();
  }).finally(() => {
    setLoading(false);
  });
};