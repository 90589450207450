import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const Advantages = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const data = {
    subtitle: intl.formatMessage({ id: "advantages.subtitle" }),
    title: intl.formatMessage({ id: "advantages.title" }),
    content: intl.formatMessage({ id: "advantages.content" }),
    button: {
      url: `/${i18nLocale}/submit-your-cv`,
      title: intl.formatMessage({ id: "advantages.button.title" }),
      target: "_self",
    },
    cards: [
      {
        icon: {
          url: "/static/images/icons/advantages-challenges.svg",
          alt: "",
        },
        text: intl.formatMessage({ id: "advantages.cards.challenges.text" }),
      },
      {
        icon: {
          url: "/static/images/icons/advantages-growth.svg",
          alt: "",
        },
        text: intl.formatMessage({ id: "advantages.cards.growth.text" }),
      },
      {
        icon: {
          url: "/static/images/icons/advantages-commissions.svg",
          alt: "",
        },
        text: intl.formatMessage({ id: "advantages.cards.commissions.text" }),
      },
      {
        icon: {
          url: "/static/images/icons/advantages-training.svg",
          alt: "",
        },
        text: intl.formatMessage({ id: "advantages.cards.training.text" }),
      },
      {
        icon: {
          url: "/static/images/icons/advantages-benefits.svg",
          alt: "",
        },
        text: intl.formatMessage({ id: "advantages.cards.benefits.text" }),
      },
    ],
  };

  return (
    <div id="advantages" className="vw-py [--py:60] laptop:[--py:105]">
      <div id="advantages-container" className="gg-container">
        <div id="advantages-grid" className="gg-grid !vw-gap-y [--gap-y:64]">
          <div
            id="advantages-content"
            className="col-start-1 col-span-12 mini:col-start-3 mini:col-span-20 tablet:order-2 tablet:col-start-14 tablet:col-span-11 laptop:col-start-14 laptop:col-span-9 flex flex-col vw-gap [--gap:24] mini:[--gap:42]"
          >
            <div
              id="advantages-subtitle"
              className="subtitle uppercase text-deepBlue fadeInUp"
            >
              {data.subtitle}
            </div>
            <h3 id="advantages-title" className="h1 fadeInUp" dangerouslySetInnerHTML={{__html: data.title}}></h3>
            <div
              id="advantages-content"
              className="richtext body-paragraph fadeInUp"
              dangerouslySetInnerHTML={{__html: data.content}}
            ></div>
            {data.button && (
              <div id="advantages-button" className="fadeInUp">
                <a
                  href={data.button.url}
                  target={data.button.target}
                  className="inline-flex text-center justify-center items-center max-mini:w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full transition-colors duration-150 ease-out-cubic bg-lightBlue text-darkGreen hover:bg-deepBlue hover:text-white"
                >
                  <svg
                    className="vw-w [--w:7] vw-h [--h:13] object-contain overflow-visible"
                    viewBox="0 0 7 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.999996 1.09796L6.40204 6.5L0.999997 11.902"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>{data.button.title}</span>
                </a>
              </div>
            )}
          </div>
          <div className="col-start-1 col-span-12 mini:col-start-3 mini:col-span-20 tablet:col-start-1 tablet:col-span-12 laptop:col-start-2 laptop:col-span-10 desktop:col-start-2 desktop:col-span-9 tablet:order-1 flex flex-col mini:flex-row mini:flex-wrap mini:justify-between mini:items-start vw-gap [--gap:24]">
            {data.cards &&
              data.cards.map((card, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center justify-center vw-gap [--gap:24] mini:vw-w mini:[--w:192] tablet:[--w:205] desktop:[--w:215] fadeInUp mini:even:animate-delay-150"
                >
                  {card.icon && (
                    <img
                      className="aspect-square object-contain vw-w [--w:110]"
                      src={card.icon.url}
                      alt={card.icon.alt}
                      loading="lazy"
                    />
                  )}
                  <div className="body-paragraph text-center vw-max-w [--max-w:205]" dangerouslySetInnerHTML={{__html: card.text}}></div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
