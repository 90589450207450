import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Box, Grid, Button, CircularProgress, TextField, InputAdornment, IconButton, Link } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import sh from "bundles/common/utils/sh";
import Welcome from "internal/welcome.svg";

const useStyles = makeStyles(() => ({
  mainBox: {
    height: "100vh",
    backgroundColor: "white",
    display: "flex"
  },
  sideBox: {
    width: "50%",
    backgroundColor: "#2A334E",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#FFF"
  },
  loginBox: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  loginCard: {
    width: "440px"
  }
}));

const Login = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    email: "",
    password: "",
  };

  const [loading, setLoading] = useState(false);
  const [resetPasswordValues, setResetPasswordValues] = useState({ email: "" });
  const [loginValues, setLoginValues] = useState(initialFormValues);
  const [loginErrors, setLoginErrors] = useState({});
  const [resetPasswordErrors, setResetPasswordErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [currentSection, setCurrentSection] = useState("login");

  const validateLogin = (fieldValues = loginValues) => {
    let temp = { ...loginErrors };
    let required = "Required";
    
    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
    }
    
    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
    }
    
    setLoginErrors({
      ...temp,
    });
    
    return Object.values(temp).every((x) => x === "");
  };

  const validateResetPassword = (fieldValues = resetPasswordValues) => {
    let temp = { ...resetPasswordErrors };
    let required = "Required";
    
    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
    }
    
    setResetPasswordErrors({
      ...temp,
    });
    
    return Object.values(temp).every((x) => x === "");
  };
    
  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setLoginValues({
      ...loginValues,
      [name]: value,
    });
    validateLogin({ [name]: value });
  };

  const handleResetPasswordValue = (e) => {
    const { name, value } = e.target;
    setResetPasswordValues({
      ...resetPasswordValues,
      [name]: value,
    });
    validateResetPassword({ [name]: value });
  };
    
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validateLogin(loginValues)) {
      sh.post("/users/sign_in", {
        authenticity_token: authenticityToken,
        user: {
          email: loginValues.email,
          password: loginValues.password,
        },
      })
        .then(() => {
          setLoading(false);
          window.location.href = `${window.location.origin}/internal`;
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status == 401) {
            enqueueSnackbar(
              "The password or e-mail you've entered is incorrect.",
              { variant: "error" }
            );
          }
        });
    } else {
      setLoading(false);
    }
  };

  const resetPassword = (e) => {
    e.preventDefault();
    if (validateResetPassword(resetPasswordValues)) {
      setLoading(true);
      sh.post("/users/password", {
        authenticity_token: authenticityToken,
        user: {
          email: resetPasswordValues.email,
        },
      })
        .then(() => {
          enqueueSnackbar(
            "Reset instructions have been sent to the selected email.",
            { variant: "success" }
          );
        })
        .catch(() => {
          enqueueSnackbar(
            "Oops, an error occured.",
            { variant: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.sideBox}>
        <h1 style={{ marginBottom: "32px", fontStyle: "italic" }}>Welcome @Sander</h1>
        <img src={Welcome} style={{ width: "40%" }}/>
      </Box>
      <Box className={classes.loginBox}>
        {currentSection == "login" &&
          <form onSubmit={handleSubmit}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <TextField
                  style={{ width: "400px" }}
                  required
                  type="email"
                  variant="outlined"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  name="email"
                  label="Email"
                />
              </Grid>

              <Grid item xs={12} style={{ textAlign: "center" }}>
                <TextField
                  style={{ width: "400px", marginTop: "8px" }}
                  required
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  name="password"
                  label="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  style={{ width: "400px", marginTop: "4px"}}
                  type="submit"
                  variant="squared"
                  color="primaryContained"
                  size="medium"
                  disabled={loading}
                >
                  <Box justifyContent="center">
                    {loading && (
                      <CircularProgress size={16} color="secondary">
                          Login in…
                      </CircularProgress>
                    )}
                    {!loading && "Log in"}
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                Forgot your password?{" "}
                <Link style={{ cursor: "pointer" }}onClick={() => setCurrentSection("resetPassword")}>
                  Click here to change it
                </Link>
              </Grid>
            </Grid>
          </form>
        }
        {currentSection == "resetPassword" &&
          <form onSubmit={resetPassword}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}  style={{ textAlign: "center" }}>
                <TextField
                  style={{ width: "400px" }}
                  required
                  type="email"
                  margin="normal"
                  variant="outlined"
                  onBlur={handleResetPasswordValue}
                  onChange={handleResetPasswordValue}
                  name="email"
                  {...(resetPasswordErrors["email"] && {
                    error: true,
                    helperText: resetPasswordErrors["email"],
                  })}
                  label="Email"
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  style={{ width: "400px", marginTop: "4px"}}
                  type="submit"
                  variant="squared"
                  color="primaryContained"
                  size="medium"
                  disabled={loading}
                >
                  <Box justifyContent="center">
                    {loading && (
                      <CircularProgress size={16} color="secondary">
                          Sending…
                      </CircularProgress>
                    )}
                    {!loading && "Send reset instructions"}
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Link style={{ cursor: "pointer" }}onClick={() => setCurrentSection("login")}>
                  Go back to the log in
                </Link>
              </Grid>
            </Grid>
          </form>
        }
      </Box>
    </Box>
  );
};

export default Login;
