import React from "react";
import { responsiveFontSizes } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MuiTheme from "./theme.js";

const theme = responsiveFontSizes(createTheme(MuiTheme));

export default function InternalTheme({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
