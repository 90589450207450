import React from "react";
import { useIntl } from "react-intl";

const Results = () => {
  const intl = useIntl();

  const data = {
    subtitle: intl.formatMessage({ id: "results.subtitle" }),
    title: intl.formatMessage({ id: "results.title" }),
    content: intl.formatMessage({ id: "results.content" }),
    cards: {
      image: {
        url: "/static/images/about-numbers.jpg",
        alt: intl.formatMessage({ id: "results.cards.image_alt" }),
        sizes: {
          thumbnail: "/static/images/scaled/about-numbers_640.webp",
          medium: "/static/images/scaled/about-numbers_1280.webp",
          large: "/static/images/scaled/about-numbers_2560.webp"
        }
      },
      darkGreen1: {
        number: {
          prefix: "+",
          value: 200,
          suffix: ""
        },
        label: intl.formatMessage({ id: "results.cards.placements_year.label" })
      },
      lightBeige1: {
        number: {
          prefix: "",
          value: 95,
          suffix: "%"
        },
        label: intl.formatMessage({ id: "results.cards.success_rate.label" })
      },
      lightBlue: {
        label: intl.formatMessage({ id: "results.cards.advisors.label" })
      },
      lightBeige2: {
        number: {
          prefix: "+",
          value: 184,
          suffix: ""
        },
        label: intl.formatMessage({ id: "results.cards.partners.label" })
      },
      deepBlue: {
        number: {
          prefix: "",
          value: 200,
          suffix: "%"
        },
        label: intl.formatMessage({ id: "results.cards.growth.label" })
      },
      darkGreen2: {
        number: {
          prefix: "",
          value: 70,
          suffix: "%"
        },
        label: intl.formatMessage({ id: "results.cards.loyalty_rate.label" })
      }
    }
  };

  return (
    <section id="results" className="vw-pt [--pt:60] mini:[--pt:90] tablet:[--pt:130] flex flex-col vw-gap [--gap:80] mini:[--gap:130] tablet:[--gap:120] laptop:[--gap:150] desktop:[--gap:200]">
      <div id="results-container" className="gg-container">
        <div id="results-grid" className="gg-grid">
          <div className="col-span-12 mini:col-start-3 mini:col-span-20 tablet:col-start-1 tablet:col-span-16 laptop:col-start-3 laptop:col-span-12 flex flex-col vw-gap [--gap:24] mini:[--gap:32]">
            <div id="results-subtitle" className="subtitle uppercase text-deepBlue fadeInUp">{data.subtitle}</div>
            <h3 id="results-title" className="h1 fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }}></h3>
            <div id="results-content" className="richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{ __html: data.content }}></div>
          </div>
        </div>
      </div>

      <div id="results-cards" className="grid grid-cols-1 mini:grid-cols-3 tablet:grid-cols-4 tablet:vw-mt tablet:[--mt:-512] laptop:[--mt:-630] desktop:[--mt:-768]">
        {/* Image Card */}
        {data.cards.image && (
          <div id="results-cards-image" className="col-span-1 mini:col-span-3 tablet:col-start-1 tablet:col-span-2 tablet:row-span-2 max-tablet:vw-h max-tablet:[--h:312] max-tablet:mini:[--h:400] overflow-hidden vw-rounded-tl [--rounded-tl:50] mini:[--rounded-tl:110] tablet:[--rounded-tl:130] laptop:[--rounded-tl:150] mini:order-1 tablet:order-3 parallax-image-wrapper2">
            <img
              className="w-full h-full object-cover parallax-image"
              src={data.cards.image.sizes.thumbnail}
              srcSet={`${data.cards.image.sizes.thumbnail} 640w, ${data.cards.image.sizes.medium} 1280w, ${data.cards.image.sizes.large} 2560w`}
              sizes="100vw"
              alt={data.cards.image.alt}
              loading="lazy"
            />
          </div>
        )}

        {/* Dark Green 1 Card */}
        {data.cards.darkGreen1 && (
          <div id="results-cards-card" className="col-span-1 aspect-[312/186] mini:aspect-square flex flex-col items-start mini:items-center mini:text-center justify-center mini:justify-around vw-gap [--gap:30] vw-px [--px:16] mini:vw-py mini:[--py:90] laptop:[--py:110] desktop:[--py:130] mini:order-3 tablet:order-6 mini:[&_small]:mb-[-25%] laptop:[&_small]:mb-[-15%] bg-darkGreen text-white">
            {data.cards.darkGreen1.number && (
              <strong className="category-number fadeInUp">{data.cards.darkGreen1.number.prefix}<span data-counter={data.cards.darkGreen1.number.value}>{data.cards.darkGreen1.number.value}</span>{data.cards.darkGreen1.number.suffix}</strong>
            )}
            <small className="category-title fadeInUp">{data.cards.darkGreen1.label}</small>
          </div>
        )}

        {/* Light Beige 1 Card */}
        {data.cards.lightBeige1 && (
          <div id="results-cards-card" className="col-span-1 aspect-[312/186] mini:aspect-square flex flex-col items-start mini:items-center mini:text-center justify-center mini:justify-around vw-gap [--gap:30] vw-px [--px:16] mini:vw-py mini:[--py:90] laptop:[--py:110] desktop:[--py:130] mini:order-4 tablet:order-7 mini:[&_small]:mb-[-25%] laptop:[&_small]:mb-[-15%] bg-lightBeige text-darkGreen">
            {data.cards.lightBeige1.number && (
              <strong className="category-number fadeInUp">{data.cards.lightBeige1.number.prefix}<span data-counter={data.cards.lightBeige1.number.value}>{data.cards.lightBeige1.number.value}</span>{data.cards.lightBeige1.number.suffix}</strong>
            )}
            <small className="category-title fadeInUp">{data.cards.lightBeige1.label}</small>
          </div>
        )}

        {/* Light Blue Card */}
        {data.cards.lightBlue && (
          <div id="results-cards-card" className="col-span-1 aspect-[312/186] mini:aspect-square flex flex-col items-start mini:items-center mini:text-center justify-center vw-gap [--gap:30] vw-px [--px:16] mini:[--px:24] mini:order-2 tablet:order-4 bg-lightBlue text-darkGreen">
            <span
              className="vw-text [--text:22] tablet:[--text:26] laptop:[--text:32] desktop:[--text:36] leading-snug block text-balance fadeInUp"
              dangerouslySetInnerHTML={{ __html: data.cards.lightBlue.label }}>
            </span>
          </div>
        )}

        {/* Light Beige 2 Card */}
        {data.cards.lightBeige2 && (
          <div id="results-cards-card" className="col-span-1 tablet:col-start-4 aspect-[312/186] mini:aspect-square flex flex-col items-start mini:items-center mini:text-center justify-center mini:justify-around vw-gap [--gap:30] vw-px [--px:16] mini:vw-py mini:[--py:90] laptop:[--py:110] desktop:[--py:130] mini:order-5 tablet:order-1 mini:[&_small]:mb-[-25%] laptop:[&_small]:mb-[-15%] bg-lightBeige text-darkGreen">
            {data.cards.lightBeige2.number && (
              <strong className="category-number fadeInUp">{data.cards.lightBeige2.number.prefix}<span data-counter={data.cards.lightBeige2.number.value}>{data.cards.lightBeige2.number.value}</span>{data.cards.lightBeige2.number.suffix}</strong>
            )}
            <small className="category-title fadeInUp">{data.cards.lightBeige2.label}</small>
          </div>
        )}

        {/* Deep Blue Card */}
        {data.cards.deepBlue && (
          <div id="results-cards-card" className="col-span-1 tablet:col-start-4 aspect-[312/186] mini:aspect-square flex flex-col items-start mini:items-center mini:text-center justify-center mini:justify-around vw-gap [--gap:30] vw-px [--px:16] mini:vw-py mini:[--py:90] laptop:[--py:110] desktop:[--py:130] mini:order-6 tablet:order-2 mini:[&_small]:mb-[-25%] laptop:[&_small]:mb-[-15%] bg-deepBlue text-white">
            {data.cards.deepBlue.number && (
              <strong className="category-number fadeInUp">{data.cards.deepBlue.number.prefix}<span data-counter={data.cards.deepBlue.number.value}>{data.cards.deepBlue.number.value}</span>{data.cards.deepBlue.number.suffix}</strong>
            )}
            <small className="category-title fadeInUp">{data.cards.deepBlue.label}</small>
          </div>
        )}

        {/* Dark Green 2 Card */}
        {data.cards.darkGreen2 && (
          <div id="results-cards-card" className="col-span-1 aspect-[312/186] mini:aspect-square flex flex-col items-start mini:items-center mini:text-center justify-center mini:justify-around vw-gap [--gap:30] vw-px [--px:16] mini:vw-py mini:[--py:90] laptop:[--py:110] desktop:[--py:130] mini:order-7 tablet:order-5 mini:[&_small]:mb-[-25%] laptop:[&_small]:mb-[-15%] bg-darkGreen text-white">
            {data.cards.darkGreen2.number && (
              <strong className="category-number fadeInUp">{data.cards.darkGreen2.number.prefix}<span data-counter={data.cards.darkGreen2.number.value}>{data.cards.darkGreen2.number.value}</span>{data.cards.darkGreen2.number.suffix}</strong>
            )}
            <small className="category-title fadeInUp">{data.cards.darkGreen2.label}</small>
          </div>
        )}
      </div>
    </section>

  );
};

export default Results;
