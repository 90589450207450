import React from "react";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Link } from "@mui/material";

const ExpandedJobOffer = ({ data }) => {
  const logoMapping = {
    website: "/sander-logo.svg",
    indeed: "/static/images/logos/Indeed.svg",
    stepstone: "/static/images/logos/StepStone.svg"
  };
  const jobApplications = data.job_applications || [];

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <TableContainer component={Paper} sx={{ marginTop: 2, marginBottom: 2, width: "90%" }}>
        <Table aria-label="job applications table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>Email</TableCell>
              <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>Phone</TableCell>
              <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>Message</TableCell>
              <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>Resume</TableCell>
              <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>Country</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobApplications.map((application, index) => (
              <TableRow key={index}>
                <TableCell>
                  <img
                    src={logoMapping[application.origin] || "/sander-logo.svg"}
                    alt={application.origin || "Unknown"}
                    style={{ width: "80px", objectFit: "contain", }}
                  />
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {application.candidate_name || <span style={{ color: "grey" }}>Not provided</span>}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {application.candidate_email || <span style={{ color: "grey" }}>Not provided</span>}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {application.candidate_phone || <span style={{ color: "grey" }}>Not provided</span>}
                </TableCell>
                <TableCell sx={{ width: application.message ? "280px" : "inherit", textAlign: "center" }}>
                  {application.message ? (
                    application.message.length > 200
                      ? `${application.message.substring(0, 50)}...`
                      : application.message
                  ) : (
                    <span style={{ color: "grey" }}>No message included</span>
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {application.resume_url ? (
                    <Link href={application.resume_url} target="_blank" color="primary" sx={{ cursor: "pointer" }}>See CV</Link>
                  ) : "No resume attached"}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  {application.country || <span style={{ color: "grey" }}>Unknown</span>}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ExpandedJobOffer;
