import React from "react";
import { useIntl } from "react-intl";

const ContentWithLargeAndSmallImg = () => {
  const intl = useIntl();

  const data = {
    subtitle: intl.formatMessage({ id: "content_with_large_and_small_img.subtitle" }),
    title: intl.formatMessage({ id: "content_with_large_and_small_img.title" }),
    content: intl.formatMessage({ id: "content_with_large_and_small_img.content" }),
    image1: {
      url: "/static/images/about-success-1.jpg",
      alt: intl.formatMessage({ id: "content_with_large_and_small_img.image1_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/about-success-1_640.webp",
        medium: "/static/images/scaled/about-success-1_1280.webp",
        large: "/static/images/scaled/about-success-1_2560.webp"
      }
    },
    image2: {
      url: "/static/images/about-success-2.jpg",
      alt: intl.formatMessage({ id: "content_with_large_and_small_img.image2_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/about-success-2_640.webp",
        medium: "/static/images/scaled/about-success-2_1280.webp",
        large: "/static/images/scaled/about-success-2_2560.webp"
      }
    }
  };

  return (
    <section id="content-with-large-and-small-img" className="relative max-mini:vw-pt max-mini:[--pt:100] text-darkGreen flex flex-col max-mini:vw-gap max-mini:[--gap:100] after:content-[''] after:block max-tablet:mini:after:hidden after:bg-lightBlue after:w-full after:vw-h after:[--h:100] tablet:after:[--h:122] laptop:after:[--h:150]">
      <div id="content-with-large-and-small-img-container" className="gg-container mini:vw-py mini:[--py:100] laptop:[--py:130]">
        <div id="content-with-large-and-small-img-grid" className="gg-grid">
          <div id="content-with-large-and-small-img-text" className="col-span-12 laptop:col-start-3 laptop:col-span-11 flex flex-col vw-gap [--gap:24]">
            <div id="content-with-large-and-small-img-subtitle" className="subtitle uppercase text-deepBlue fadeInUp">{data.subtitle}</div>
            <h3 id="content-with-large-and-small-img-title" className="h1 fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }}></h3>
            <div id="content-with-large-and-small-img-content" className="richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{ __html: data.content }}></div>
          </div>
        </div>
      </div>
      {data.image1 && (
        <div id="content-with-large-and-small-img-image-large" className="max-mini:vw-mb max-mini:[--mb:-100] w-full mini:vw-w mini:[--w:325] tablet:[--w:372] laptop:[--w:480] desktop:[--w:540] aspect-[312/424] mini:aspect-[325/425] tablet:aspect-[372/487] laptop:aspect-[480/620] desktop:aspect-[540/720] vw-rounded-tl [--rounded-tl:150] overflow-hidden parallax-image-wrapper mini:absolute max-tablet:mini:top-1/2 tablet:vw-bottom tablet:[--bottom:122] laptop:[--bottom:150] mini:right-0 max-tablet:mini:transform max-tablet:mini:-translate-y-1/2">
          <img
            className="w-full h-full object-cover parallax-image"
            src={data.image1.sizes.thumbnail}
            srcSet={`${data.image1.sizes.thumbnail} 640w, ${data.image1.sizes.medium} 1280w, ${data.image1.sizes.large} 2560w`}
            sizes="100vw"
            alt={data.image1.alt}
          />
        </div>
      )}
      {data.image2 && (
        <div id="content-with-large-and-small-img-image-small" className="absolute bottom-0 right-0 tablet:vw-right tablet:[--right:235] laptop:[--right:300] desktop:[--right:360] vw-w [--w:202] mini:[--w:213] tablet:[--w:244] laptop:[--w:324] desktop:[--w:350] aspect-square mini:aspect-[213/202] tablet:aspect-[244/232] laptop:aspect-[324/295] desktop:aspect-square overflow-hidden vw-rounded-bl [--rounded-bl:70] parallax-image-wrapper">
          <img
            className="w-full h-full object-cover parallax-image"
            src={data.image2.sizes.thumbnail}
            srcSet={`${data.image2.sizes.thumbnail} 640w, ${data.image2.sizes.medium} 1280w, ${data.image2.sizes.large} 2560w`}
            sizes="100vw"
            alt={data.image2.alt}
          />
        </div>
      )}
    </section>
  );
};

export default ContentWithLargeAndSmallImg;
