import React from "react";
import { Provider } from "react-redux";
import appStore from "bundles/common/store/appStore";
import LoginContainer from "bundles/Login/container/LoginContainer";
import InternalTheme from "bundles/Layouts/InternalTheme";
import { SnackbarProvider } from "notistack";

const LoginApp = (props, railsContext) => {
  return (
    <Provider store={appStore(Object.assign(railsContext, props))}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <InternalTheme>
          <LoginContainer {...props} />
        </InternalTheme>
      </SnackbarProvider>
    </Provider>
  );
};

export default LoginApp;
