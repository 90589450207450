import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import sh from "bundles/common/utils/sh";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import Footer from "../components/globals/Footer";

import CareersHero from "../components/partials/CareersHero";
import CareersFilters from "../components/partials/CareersFilters";
import JobListings from "../components/partials/JobListings";
import Pagination from "../components/partials/Pagination";
import ColoredCtaCards from "../components/partials/ColoredCtaCards";

const Careers = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const [loading, setLoading] = useState(false);
  const [jobOffers, setJobOffers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [count, setCount] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedContracts, setSelectedContracts] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchJobOffers = ({
    page = currentPage,
    searchQuery,
    selectedCategories,
    selectedRegions,
    selectedContracts,
    scrollToJobListings = true
  } = {}) => {
    setLoading(true);
    sh.get("search/job_offers", {
      params: {
        authenticity_token: authenticityToken,
        query: searchQuery,
        selected_categories: selectedCategories,
        selected_regions: selectedRegions,
        selected_contracts: selectedContracts,
        page,
      },
    })
      .then((res) => {
        setJobOffers(res?.data?.data);
        setPageCount(res.data?.pagy?.pages);
        setCount(res?.data?.pagy?.count);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        const jobListingsElement = document.getElementById("job-listings");
        if (jobListingsElement && scrollToJobListings) {
          const offset = 100;
          const elementPosition = jobListingsElement.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
      }
      );
  };

  const options = {
    searchQuery,
    selectedCategories,
    selectedRegions,
    selectedContracts,
    page: currentPage,
    scrollToJobListings: true
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    fetchJobOffers(options);
  };

  const handleCategoryChange = (newSelectedCategories) => {
    setCurrentPage(1);
    fetchJobOffers({
      ...options,
      selectedCategories: newSelectedCategories
    });
    setSelectedCategories(newSelectedCategories);
    updateUrlParams(newSelectedCategories, selectedRegions, selectedContracts, categories);
  };

  const handleRegionChange = (selectedRegions) => {
    setCurrentPage(1);
    fetchJobOffers({
      ...options,
      selectedRegions: selectedRegions
    });
    setSelectedRegions(selectedRegions);
    updateUrlParams(selectedCategories, selectedRegions, selectedContracts, categories);
  };

  const handleContractChange = (selectedContracts) => {
    setCurrentPage(1);
    fetchJobOffers({
      ...options,
      selectedContracts: selectedContracts
    });
    setSelectedContracts(selectedContracts);
    updateUrlParams(selectedCategories, selectedRegions, selectedContracts, categories);
  };

  const handleCategoryRemove = (categoryId) => {
    setCurrentPage(1);
    const newCategories = selectedCategories.filter(category => category !== categoryId);
    fetchJobOffers({
      ...options,
      selectedCategories: newCategories
    });
    setSelectedCategories(newCategories);
    updateUrlParams(newCategories, selectedRegions, selectedContracts, categories);
  };

  const handleRegionRemove = (regionName) => {
    setCurrentPage(1);
    const newRegions = selectedRegions.filter(region => region !== regionName);
    fetchJobOffers({
      ...options,
      selectedRegions: newRegions
    });
    setSelectedRegions(newRegions);
    updateUrlParams(selectedCategories, newRegions, selectedContracts, categories);
  };

  const handleContractRemove = (contractName) => {
    setCurrentPage(1);
    const newContracts = selectedContracts.filter(contract => contract !== contractName);
    fetchJobOffers({
      ...options,
      selectedContracts: newContracts
    });
    setSelectedContracts(newContracts);
    updateUrlParams(selectedCategories, selectedRegions, newContracts, categories);
  };

  const removeAllFilters = () => {
    setCurrentPage(1);
    setSelectedCategories([]);
    setSelectedRegions([]);
    setSelectedContracts([]);
    fetchJobOffers();
    updateUrlParams([], [], [], categories);
  };

  const updateUrlParams = (selectedCategories, selectedRegions, selectedContracts, categories) => {
    const queryParams = new URLSearchParams();

    if (selectedCategories.length > 0) {
      const categoryCodes = selectedCategories.map(id => getCategoryCodesById(id, categories));
      queryParams.set("categories", categoryCodes);
    }

    if (selectedRegions.length > 0) {
      queryParams.set("regions", selectedRegions);
    }

    if (selectedContracts.length > 0) {
      queryParams.set("contracts", selectedContracts);
    }

    const newUrlBase = window.location.pathname;
    const newUrl = queryParams.toString() ? `${newUrlBase}?${queryParams.toString()}` : newUrlBase;

    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const convertCategoryCodeToId = (code, fetchedCategories) => {
    const category = fetchedCategories.find(c => c.code === code);
    return category ? category.id : null;
  };

  const getCategoryCodesById = (id, categories) => {
    const category = categories.find(category => category.id === Number(id));
    return category ? category.code : undefined;
  };


  useEffect(() => {
    sh.get("/categories", {
      authenticity_token: authenticityToken,
    }).then((res) => {
      const fetchedCategories = res?.data?.categories;
      setCategories(fetchedCategories);

      const queryParams = new URLSearchParams(window.location.search);
      const categoriesFromURL = queryParams.get("categories") ? queryParams.get("categories").split(",").map(decodeURIComponent) : [];
      const regionsFromURL = queryParams.get("regions") ? queryParams.get("regions").split(",") : [];
      const contractsFromURL = queryParams.get("contracts") ? queryParams.get("contracts").split(",") : [];

      const categoryIdsFromCodes = categoriesFromURL.map(code => convertCategoryCodeToId(code, fetchedCategories));

      setSelectedCategories(categoryIdsFromCodes);
      setSelectedRegions(regionsFromURL);
      setSelectedContracts(contractsFromURL);

      const options = {
        selectedCategories: categoryIdsFromCodes,
        selectedRegions: regionsFromURL,
        selectedContracts: contractsFromURL,
        scrollToJobListings: !firstLoad
      };

      if (firstLoad) {
        setFirstLoad(false);
      }

      fetchJobOffers(options);
    });
  }, [currentPage]);

  return (
    <div id="careers">
      <Menu />
      <Header />

      <main>
        <CareersHero setSearchQuery={setSearchQuery} handleSearchSubmit={handleSearchSubmit} searchQuery={searchQuery} />
        <CareersFilters onCategoryChange={handleCategoryChange} onRegionChange={handleRegionChange} onContractChange={handleContractChange} categories={categories} selectedCategories={selectedCategories} selectedRegions={selectedRegions} selectedContracts={selectedContracts} handleCategoryRemove={handleCategoryRemove} handleRegionRemove={handleRegionRemove} handleContractRemove={handleContractRemove} removeAllFilters={removeAllFilters} />
        <JobListings jobOffers={jobOffers} />
        <Pagination currentPage={currentPage} setPage={setCurrentPage} pageCount={pageCount} />
        <ColoredCtaCards />
      </main>

      <Footer />
    </div>
  );
};

export default Careers;
