export const variants = [
  {
    props: { variant: "rounded" },
    style: {
      borderRadius: "30px",
    },
  },
  {
    props: { variant: "lessRounded" },
    style: {
      borderRadius: "10px !important",
    },
  },
  {
    props: { variant: "squared" },
    style: {
      borderRadius: "4px !important",
    },
  },
  {
    props: { variant: "text" },
    style: ({ theme: t }) => ({
      color: "#2A334E",
      marginEight: "16px",
      textTransform: "none",
      "&:hover": {
        color: "#182038",
      },
    }),
  },
  {
    props: { variant: "textUnderline" },
    style: ({ theme: t }) => ({
      color: "#2A334E",
      marginEight: "16px",
      textTransform: "none",
      textDecoration: "underline !important",
      "&:hover": {
        backgroundColor: "inherit",
        opacity: 0.86,
        textDecoration: "underline !important",
      },
    }),
  },

  /*
    COLORS
   */
  {
    props: { color: "primaryContained" },
    style: ({ theme: t }) => ({
      backgroundColor: t.palette.primary.main,
      color: t.palette.common.white,
      fontWeight: "600",
      "&:hover": {
        backgroundColor: t.palette.primary.dark,
      },
      "&.Mui-disabled": {
        color: t.palette.common.white,
        opacity: "0.8",
        cursor: "not-allowed",
        pointerEvents: "unset",
      },
      "& > .MuiLoadingButton-loadingIndicator": {
        color: t.palette.common.white,
      },
    }),
  },
  {
    props: { color: "primaryOutlined" },
    style: ({ theme: t }) => ({
      border: `1px solid ${t.palette.primary.main}`,
      backgroundColor: "transparent",
      color: t.palette.primary.main,
      fontWeight: "600",
      "&:hover": {
        backgroundColor: t.palette.primary.smoke,
      },
      "&.Mui-disabled": {
        color: t.palette.primary.main,
        opacity: "0.8",
        cursor: "not-allowed",
        pointerEvents: "unset",
      },
      "& > .MuiLoadingButton-loadingIndicator": {
        color: t.palette.primary.main,
      },
    }),
  },
  {
    props: { color: "successContained" },
    style: ({ theme: t }) => ({
      backgroundColor: t.palette.primary.main,
      color: t.palette.common.white,
      fontWeight: "600",
      "&:hover": {
        backgroundColor: t.palette.primary.dark,
      },
      "&.Mui-disabled": {
        color: t.palette.common.white,
        opacity: "0.8",
        cursor: "not-allowed",
        pointerEvents: "unset",
      },
      "& > .MuiLoadingButton-loadingIndicator": {
        color: t.palette.common.white,
      },
    }),
  },
  {
    props: { color: "successOutlined" },
    style: ({ theme: t }) => ({
      border: `1px solid ${t.palette.primary.main}`,
      backgroundColor: "transparent",
      color: t.palette.primary.main,
      fontWeight: "600",
      "&:hover": {
        backgroundColor: t.palette.primary.smoke,
      },
      "&.Mui-disabled": {
        color: t.palette.primary.main,
        opacity: "0.8",
        cursor: "not-allowed",
        pointerEvents: "unset",
      },
      "& > .MuiLoadingButton-loadingIndicator": {
        color: t.palette.primary.main,
      },
    }),
  },
  {
    props: { color: "dangerContained" },
    style: ({ theme: t }) => ({
      borderRadius: "30px",
      backgroundColor: t.palette.error.main,
      color: t.palette.common.white,
      fontWeight: "600",
      "&:hover": {
        backgroundColor: t.palette.error.dark,
      },
      "&.Mui-disabled": {
        color: t.palette.common.white,
        opacity: "0.8",
        cursor: "not-allowed",
        pointerEvents: "unset",
      },
    }),
  },
  {
    props: { color: "dangerOutlined" },
    style: ({ theme: t }) => ({
      border: `1px solid ${t.palette.error.main}`,
      borderRadius: "30px",
      backgroundColor: t.palette.common.white,
      color: t.palette.error.main,
      fontWeight: "600",

      "&:hover": {
        backgroundColor: t.palette.error.smoke,
      },
      "&.Mui-disabled": {
        color: t.palette.error.light,
        cursor: "not-allowed",
        pointerEvents: "unset",
      },
      "& > .MuiLoadingButton-loadingIndicator": {
        color: t.palette.error.main,
      },
    }),
  },

  /*
    SIZES
   */
  {
    props: { size: "xsmall" },
    style: {
      height: "24px",
    },
  },
  {
    props: { size: "small" },
    style: {
      height: "36px",
    },
  },
  {
    props: { size: "medium" },
    style: {
      height: "48px",
    },
  },
  {
    props: { size: "large" },
    style: {
      height: "56px",
    },
  },
];
