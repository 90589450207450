import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const SingleFeatureWithIcons = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const data = {
    subtitle: intl.formatMessage({ id: "single_feature_with_icons.subtitle" }),
    title: intl.formatMessage({ id: "single_feature_with_icons.title" }),
    content: intl.formatMessage({ id: "single_feature_with_icons.content" }),
    button: {
      url: `/${i18nLocale}/about-us`,
      title: intl.formatMessage({ id: "single_feature_with_icons.button.title" }),
      target: "_self"
    },
    image: {
      url: "/static/images/joinourteam-why-1.jpg",
      alt: intl.formatMessage({ id: "single_feature_with_icons.image_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/joinourteam-why-1_640.webp",
        medium: "/static/images/scaled/joinourteam-why-1_1280.webp",
        large: "/static/images/scaled/joinourteam-why-1_2560.webp"
      }
    },
    cards: [
      {
        icon: {
          url: "/static/images/icons/join-our-team-top-talents.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "single_feature_with_icons.cards.card_1.text" })
      },
      {
        icon: {
          url: "/static/images/icons/join-our-team-hr-tools.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "single_feature_with_icons.cards.card_2.text" })
      },
      {
        icon: {
          url: "/static/images/icons/join-our-team-consulting-services.svg",
          alt: ""
        },
        text: intl.formatMessage({ id: "single_feature_with_icons.cards.card_3.text" })
      }
    ],
    image2: {
      url: "/static/images/joinourteam-why-2.jpg",
      alt: intl.formatMessage({ id: "single_feature_with_icons.image2_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/^-why-2_640.webp",
        medium: "/static/images/scaled/joinourteam-why-2_1280.webp",
        large: "/static/images/scaled/joinourteam-why-2_2560.webp"
      }
    },
    // image2: {
    //   url: "/static/images/Ines.jpg",
    //   alt: "",
    //   sizes: {
    //     thumbnail: "/static/images/scaled/Ines_640.webp",
    //     medium: "/static/images/scaled/Ines_1280.webp",
    //     large: "/static/images/scaled/Ines_2560.webp"
    //   }
    // }
  };

  return (
    <section id="single-feature-with-icons" className="text-darkGreen relative vw-py [--py:60] laptop:[--py:105] flex flex-col vw-gap [--gap:56] mini:[--gap:105] tablet:[--gap:0]">
      <div id="single-feature-with-icons-container" className="gg-container">
        <div id="single-feature-with-icons-grid" className="gg-grid">
          <div className="col-start-1 col-span-12 mini:col-start-1 mini:col-span-11 laptop:col-start-3 laptop:col-span-10 flex flex-col vw-gap [--gap:24] mini:[--gap:32] tablet:[--gap:42] tablet:vw-pb tablet:[--pb:48] laptop:[--pb:48] tablet:vw-min-h tablet:[--min-h:722] laptop:[--min-h:644] desktop:[--min-h:766]">
            <div className="subtitle uppercase text-deepBlue fadeInUp" dangerouslySetInnerHTML={{ __html: data.subtitle }}></div>
            <h3 className="h1 fadeInUp" dangerouslySetInnerHTML={{ __html: data.title }}></h3>
            <div className="richtext body-paragraph fadeInUp" dangerouslySetInnerHTML={{ __html: data.content }}></div>

            {data.button && (
              <div id="button" className="fadeInUp">
                <a href={data.button.url} target={data.button.target} className="inline-flex text-center justify-center items-center max-mini:w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full transition-colors duration-150 ease-out-cubic bg-lightBlue text-darkGreen hover:bg-deepBlue hover:text-white">
                  <svg className="vw-w [--w:7] vw-h [--h:13] object-contain overflow-visible" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.999996 1.09796L6.40204 6.5L0.999997 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <span>
                    {data.button.title}
                  </span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      {data.image && (
        <div className="w-full aspect-[55/52] mini:aspect-[388/462] tablet:aspect-[516/722] laptop:aspect-[574/644] desktop:aspect-[696/766] static mini:absolute mini:right-0 mini:vw-top mini:[--top:60] laptop:[--top:105] mini:vw-w mini:[--w:390] tablet:[--w:516] laptop:[--w:574] desktop:[--w:696] parallax-image-wrapper">
          <img
            className="object-cover w-full h-full parallax-image"
            src={data.image.sizes.thumbnail}
            srcSet={`${data.image.sizes.thumbnail} 640w, ${data.image.sizes.medium} 1280w, ${data.image.sizes.large} 2560w`}
            sizes="100vw"
            alt={data.image.alt}
            loading="lazy"
          />
        </div>
      )}

      <div id="single-feature-with-icons" className="gg-container relative">
        <div id="single-feature-with-icons-grid" className="gg-grid max-mini:!vw-gap-y max-mini:[--gap-y:64] mini:vw-py mini:[--py:40] tablet:[--py:85] desktop:[--py:105]">
          <div className="col-start-1 col-span-12 mini:col-start-13 mini:col-span-12 tablet:col-start-15 tablet:col-span-9 laptop:col-start-16 laptop:col-span-7 flex flex-col vw-gap [--gap:20]">
            {data.cards.map((card, index) => (
              <div key={index} className="flex items-start justify-start vw-gap [--gap:16] mini:[--gap:24] tablet:[--gap:20] [&:not(:last-child)]:vw-pb [&:not(:last-child)]:[--pb:20] [&:not(:last-child)]:border-b [&:not(:last-child)]:border-lightBlue fadeInUp">
                <img
                  className="vw-w [--w:62] aspect-square grow-0 shrink-0 object-contain"
                  src={card.icon.url}
                  alt={card.icon.alt}
                  loading="lazy"
                />
                <div className="body-paragraph" dangerouslySetInnerHTML={{ __html: card.text }}></div>
              </div>
            ))}
          </div>

          {data.image2 && (
            <div className="aspect-square mini:aspect-[352/374] tablet:aspect-[327/394] laptop:aspect-[384/394] desktop:aspect-[504/481] max-mini:col-start-1 max-mini:col-span-12 vw-rounded-bl [--rounded-bl:60] mini:[--rounded-bl:80] overflow-hidden parallax-image-wrapper mini:absolute mini:top-0 mini:vw-left mini:[--left:0] tablet:[--left:180] laptop:[--left:300] desktop:[--left:316] mini:vw-w mini:[--w:352] tablet:[--w:327] laptop:[--w:384] desktop:[--w:504]">
              <img
                className="object-cover w-full h-full parallax-image"
                style={{ objectPosition: "50% 0%" }}
                src={data.image2.sizes.thumbnail}
                srcSet={`${data.image2.sizes.thumbnail} 640w, ${data.image2.sizes.medium} 1280w, ${data.image2.sizes.large} 2560w`}
                sizes="100vw"
                alt={data.image2.alt}
                loading="lazy"
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SingleFeatureWithIcons;
