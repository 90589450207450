import { UPDATE_CURRENT_USER } from "./user";

export const initialState = {};

export default function railsContextReducer(state = initialState, action) {
  switch (action.type) {
  case UPDATE_CURRENT_USER:
    return { ...state, current_user: action?.payload };
  default:
    return state;
  }
}
