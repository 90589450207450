import React from "react";
import { useIntl } from "react-intl";

import Menu from "../components/globals/Menu";
import Header from "../components/globals/Header";
import Footer from "../components/globals/Footer";

import Jumbotron from "../components/partials/Jumbotron";
import Form from "../components/partials/Form";

const SubmitYourCV = () => {
  const intl = useIntl();

  const jumbotronData = {
    background: {
      url: "/static/images/submityourcv-jumbotron.jpg",
      alt: intl.formatMessage({ id: "submit_your_cv.jumbotron.image_alt" }),
      sizes: {
        thumbnail: "/static/images/scaled/submityourcv-jumbotron_640.webp",
        medium: "/static/images/scaled/submityourcv-jumbotron_1280.webp",
        large: "/static/images/scaled/submityourcv-jumbotron_2560.webp"
      }
    },
    title: intl.formatMessage({ id: "submit_your_cv.jumbotron.title" }),
    label: intl.formatMessage({ id: "submit_your_cv.jumbotron.label" })
  };

  const formData = {
    title: intl.formatMessage({ id: "submit_your_cv.form.title" })
  };

  return (
    <div id="submit-your-cv">
      <Menu />
      <Header />

      <main>
        <Jumbotron data={jumbotronData} />
        <Form data={formData} type={"submit-your-cv"} />
      </main>

      <Footer />
    </div>
  );
};

export default SubmitYourCV;
