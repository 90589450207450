import React from "react";
import { clsx } from "clsx";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const Hero = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const data = {
    background: {
      // url: "/static/images/sander-placeholder.jpg",
      // alt: "",
      // sizes: {
      //   thumbnail: "/static/images/scaled/sander-placeholder_640.webp",
      //   medium: "/static/images/scaled/sander-placeholder_1280.webp",
      //   large: "/static/images/scaled/sander-placeholder_2560.webp",
      // },
      url: "/static/videos/home-hero-video.mp4",
    },
    title: intl.formatMessage({ id: "homepage.hero.title"}),
    buttons: [
      {
        isOutlined: false,
        url: `/${i18nLocale}/jobs`,
        title: intl.formatMessage({ id: "homepage.hero.button.find_job" }),
        target: "_self",
      },
      {
        isOutlined: true,
        url: `/${i18nLocale}/why-us-company`,
        title: intl.formatMessage({ id: "homepage.hero.button.hire_talent" }),
        target: "_self",
      },
    ],
    cta: {
      url: `/${i18nLocale}/submit-your-cv` ,
      title: intl.formatMessage({ id: "homepage.hero.send_cv" }),
      target: "_self",
    },
  };

  return (
    <section
      id="hero"
      className="relative bg-black/30 text-white flex items-center tablet:items-end vw-pt [--pt:90] mini:[--pt:130] vw-pb [--pb:90] max-mini:[--pb:40] mini:[--pb:75] tablet:[--pb:65] laptop:[--pb:70] desktop:[--pb:110]"
    >
      {data.background.url && (
        <div
          key="hero-background"
          className="-z-[1] parallax-image-wrapper absolute top-0 left-0 w-full h-full fadeIn"
        >
          {/* <img
            id="hero-background-img"
            className="parallax-image w-full h-full object-cover"
            src={data.background.sizes.thumbnail}
            srcSet={`${data.background.sizes.thumbnail} 640w, ${data.background.sizes.medium} 1280w, ${data.background.sizes.large} 2560w`}
            sizes="100vw"
            style={{ transform: "translateY(0%) scale(1.1666)" }}
            alt={data.background.alt}
            fetchpriority="high"
            decoding="async"
          /> */}
          <video
            id="hero-background-video"
            className="parallax-image w-full h-full object-cover"
            src={data.background.url}
            style={{ transform: "translateY(0%) scale(1.1666)" }}
            fetchpriority="high"
            autoPlay
            loop
            muted
            playsInline
          />
        </div>
      )}
      <div id="hero-container" className="gg-container">
        <div id="hero-grid" className="gg-grid">
          <div
            id="hero-heading_wrapper"
            className="col-start-1 col-span-12 mini:col-start-1 mini:col-span-19 tablet:col-start-1 tablet:col-span-15 laptop:col-start-1 laptop:col-span-14 flex flex-col vw-gap [--gap:70] laptop:[--gap:55] desktop:[--gap:100] fadeInUp"
          >
            <h1 id="hero-title" className="hero-title grand-titre">
              <span
                className="block text-balance"
                dangerouslySetInnerHTML={{ __html: data.title }}
              ></span>
            </h1>
            <div
              id="hero-buttons"
              className="hero-buttons col-start-1 col-span-12 mini:col-start-1 mini:col-span-19 flex flex-wrap vw-gap [--gap:35] max-mini:[--gap:16] fadeInUp"
            >
              {data.buttons.map((button, index) => (
                <a
                  key={`hero-button-${index}`}
                  href={button.url}
                  target={button.target}
                  className={clsx(
                    "button",
                    button.isOutlined
                      ? "border-white bg-transparent text-white hover:border-white hover:bg-white hover:text-deepBlue"
                      : "border-deepBlue bg-deepBlue text-white hover:border-white hover:bg-white hover:text-deepBlue"
                  )}
                >
                  <svg
                    className="button-icon"
                    viewBox="0 0 8 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    className="button-text"
                    dangerouslySetInnerHTML={{ __html: button.title }}
                  ></span>
                </a>
              ))}
            </div>
          </div>
          {data.cta && (
            <div
              id="hero-cta_wrapper"
              className="max-mini:hidden absolute vw-bottom mini:[--bottom:75] tablet:[--bottom:65] laptop:[--bottom:70] desktop:[--bottom:110] vw-right mini:[--right:52] tablet:[--right:85] laptop:[--right:60] desktop:[--right:92] fadeInUp"
            >
              <a
                href={data.cta.url}
                target={data.cta.target}
                className="button border-white bg-white text-deepBlue hover:border-deepBlue hover:bg-deepBlue hover:text-white"
              >
                <svg
                  className="button-icon"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.741943 9.125V14.125H16.5667V9.125"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.72437 11.2383L8.72436 1.21226"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.77979 2.94922L8.83658 0.999617L10.8934 2.94922"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span
                  className="button-text"
                  dangerouslySetInnerHTML={{ __html: data.cta.title }}
                ></span>
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Hero;
