import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button, Box, Chip, Modal } from "@mui/material";
import DataTable from "react-data-table-component";
import sh from "bundles/common/utils/sh";
import ConfirmModal from "bundles/Internal/components/ConfirmModal";
import EditDepartmentModal from "./EditDepartmentModal";

const DepartmentsIndex = ({ departments, fetchAllDepartments }) => {
  const currentUser = useSelector((state) => state.current_user);
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const columns = [
    {
      name: "Name",
      selector: row => row.name,
      width: "calc(25% - 160px)",
      sortable: true,
    },
    {
      name: "Email",
      selector: row => row.email,
      width: "calc(30% - 160px)",
      sortable: true,
    },
    {
      name: "Categories",
      selector: row => <Box style={{ display: "flex", columnGap: "8px", rowGap: "4px", flexWrap: "wrap", paddingTop: "4px", paddingBottom: "4px"}}>
        {row.categories.sort((c1, c2) => c1.name < c2.name ? -1 : 1).map((category) => {
          return (
            <Chip key={`${category.id}-index-cat`} label={category.name} />
          );
        })}
      </Box>,
      width: "calc(65% - 160px)"
    },
    // {
    //   name: "",
    //   selector: row => {
    //     return (<Box>
    //       <Button
    //         size="small"
    //         variant="text"
    //         onClick={() => editDepartment(row)}
    //       >
    //         Edit
    //       </Button>
    //       <Button
    //         size="small"
    //         variant="text"
    //         disabled={loading}
    //         onClick={() => deleteDepartment(row)}
    //       >
    //         Delete
    //       </Button>
    //     </Box>);
    //   },
    //   sortable: true,
    //   width: "160px"
    // },
  ];

  const redirectUnauthorizedUsed = () => {
    navigate("/internal");
  };

  const createDepartment = () => {
    editDepartment(null);
  };

  const editDepartment = (department) => {
    setSelectedDepartment(department);
    setIsEditModalOpen(true);
  };

  const deleteDepartment = (department) => {
    setSelectedDepartment(department);
    setIsConfirmModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    setLoading(true);
    sh.delete("internal/departments", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        authenticity_token: authenticityToken,
        department: { id: selectedDepartment?.id }
      },
    }).then(() => {
      fetchAllDepartments();
      setSelectedDepartment(null);
      enqueueSnackbar("Department succesfully deleted!", {
        variant: "success",
      });
    }).catch((err) => {
      let errorMessage = "Oops, an error occured.";
      if (err.response.status == 401) {
        errorMessage = "This department still has active users or job offers linked to it.";
      }
      enqueueSnackbar(
        errorMessage,
        { variant: "error" }
      );
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!currentUser || !currentUser["admin?"]) {
      redirectUnauthorizedUsed();
    } else {
      fetchAllDepartments();
    }
  }, []);

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "16px" }}>
        <h1>Departments</h1>
        <Button variant="squared" color="primaryOutlined" onClick={createDepartment}>Create a new department</Button>
      </Box>
      <DataTable
        columns={columns}
        data={departments}
        responsive
        striped
        highlightOnHover
        pagination
      />
      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        handleConfirm={handleDeleteConfirmed}
        label="You're about to delete this department."
      />
      <Modal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EditDepartmentModal
          closeModal={() => setIsEditModalOpen(false)}
          department={selectedDepartment}
          fetchAllDepartments={fetchAllDepartments}
        />
      </Modal>
    </>
  );
};

export default DepartmentsIndex;
