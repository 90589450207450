export const testUrl = (url) => {
  var http = new XMLHttpRequest();
  http.open("HEAD", url, false);
  http.send();
  return http.status;
};

export const capitalize = (str) => {
  if (!str) return "";

  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export const snakeCase = (string) => {
  if (!string || !typeof(string) == "string") return;

  return string.replace(/\W+/g, " ")
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join("_");
};

export const getFormattedDate = (date, withTime = true) => {
  if (!date) return "";

  if (withTime) {
    return new Intl.DateTimeFormat("en-GB", {
      weekday: "long",
      month: "long",
      day: "2-digit",
      hour: "numeric",
      minute: "2-digit",
    }).format(Date.parse(date));
  } else {
    return new Intl.DateTimeFormat("en-GB", {
      month: "long",
      day: "2-digit",
      year: "2-digit",
    }).format(Date.parse(date));
  }
};

export const sortJobOffersByStatus = (jobOffers) => {
  return jobOffers.sort((j1, j2) => {
    if (j1.offer_status == j2.offer_status) {
      if (j1.offer_status == "draft") {
        if (!j1.valid_offer && j2.valid_offer) {
          return -1;
        } else if (!j2.valid_offer && j1.valid_offer) {
          return 1;
        } else {
          return new Date(j2.updated_at) - new Date(j1.updated_at);
        }
      } else {
        return new Date(j2.updated_at) - new Date(j1.updated_at);
      }
    } else {
      if (j1.offer_status == "draft") {
        return -1;
      } else if (j2.offer_status == "draft") {
        return 1;
      } else if (j1.offer_status == "online") {
        return -1;
      } else {
        return 1;
      }
    }
  });
};

export const strip = (str) => {
  let div = document.createElement("div");
  div.innerHTML = str;
  let text = div.textContent || div.innerText || "";

  return text !== "" ? text : null;
};

export const stripHtml = (htmlString) => {
  if (!htmlString) return "";
  return htmlString.replace(/<[^>]*>/g, "");
};

export const formatEmploymentType = (status) => {
  const statusMap = {
    "employee": "FULL_TIME",
    "freelance": "CONTRACTOR",
    "part-time": "PART_TIME",
    "independant": "CONTRACTOR",
    "internship" : "INTERN",
    "student-job": "PER_DIEM"
  };
  return statusMap[status.toLowerCase()] || "OTHER";
};

export const getDepartmentBgColorClass = (departmentCode) => {
  switch (departmentCode) {
  case "fi":
    return "bg-lightGreen";
  case "it":
    return "bg-lightOrange";
  case "hr":
    return "bg-lightBeige";
  case "sa":
    return "bg-lightBlue";
  default:
    return "bg-white";
  }
};

export const getDepartmentIconColor = (departmentCode) => {
  switch (departmentCode) {
  case "fi":
    return "#52C1B0";
  case "it":
    return "#F1653D";
  case "hr":
    return "#F69206";
  case "sa":
    return "#356DE0";
  default:
    return "#356DE0";
  }
};

export const organizeCategoriesByDepartment = (categories) => {
  return categories.reduce((acc, category) => {
    const deptName = category.department.name;
    acc[deptName] = acc[deptName] || [];
    acc[deptName].push(category);
    return acc;
  }, {});
};

export const handleLinkedinShare = (url) => {
  const left = screen.width / 2 - 250;
  const top = screen.height / 2 - 250;
  window.open(
    "https://www.linkedin.com/sharing/share-offsite/?url=" + encodeURIComponent(url),
    "",
    `_blank, width=500, height=500, resizable=yes, scrollbars=yes, top=${top}, left=${left}`
  );
};

export const formatRegion = (region) => {
  const stopWords = ["et", "de"];
  return region
    .split("_")
    .map((r) =>
      r.split("-")
        .map((word) => (stopWords.includes(word.toLowerCase()) ? word : capitalize(word)))
        .join("-")
    )
    .join(" ");
};
