import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import sh from "bundles/common/utils/sh";
import DataTable from "react-data-table-component";
import { Typography, Button, Box, Link, Checkbox, Modal } from "@mui/material";
import { Divider } from "@mui/material";
import { organizeCategoriesByDepartment } from "bundles/common/utils/helpers";

import EditPresentationModal from "./Presentations/EditPresentationModal.jsx";

const Presentations = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const { enqueueSnackbar } = useSnackbar();

  const [selectedCandidatePresentation, setSelectedCandidatePresentation] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [organizedCategories, setOrganizedCategories] = useState({});
  const [organizedRegions, setOrganizedRegions] = useState([]);
  const [presentations, setPresentations] = useState([]);
  const [loading, setLoading] = useState(true);

  const customStyles = {
    headCells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-last-of-type(1)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      }
    },
    cells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-last-of-type(1)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      }
    }
  };

  const columns = [
    {
      name: "Candidate Name",
      selector: (row) => row.candidate_name,
      sortable: true,
    },
    {
      name: "Job Title",
      selector: (row) => row.job_title,
    },
    {
      name: "Categories",
      cell: (row) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.8,
            height: "100%",
            margin: "12px"
          }}
        >
          {row.categories && row.categories.length > 0
            ? row.categories.map((category) => (
              <span key={category.id}>{category.name}</span>
            ))
            : "No Categories"}
        </Box>
      ),
      sortable: false,
      grow: 1.2
    },
    {
      name: "Regions",
      cell: (row) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.8,
            height: "100%",
            margin: "12px"
          }}
        >
          {row.regions && row.regions.length > 0
            ? row.regions.map((region) => (
              <span key={region.id}>{region.name}</span>
            ))
            : "No Regions"}
        </Box>
      ),
      sortable: false,
    },
    {
      name: "See Presentation",
      cell: (row) => (
        <>
          {row?.resume_url ? (
            <Box sx={{ display: "flex", gap: 1 }}>
              <Link onClick={() => window.open(row.resume_url, "_blank")}>
                Open PDF
              </Link>
            </Box>
          ) : (
            "Not Available"
          )}
        </>
      ),
      sortable: false,
    },
    {
      name: "Available",
      selector: row => (
        <Checkbox
          checked={row.status === "available"}
          onChange={(event) => handleStatusCheckboxChange(event, row)}
          color="primary"
          sx={{ cursor: "pointer" }}
        />
      ),
      center: true,
      sortable: false,
      width: "120px"
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          {row?.resume_url ? (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => window.open(row.public_url, "_blank")}
                disabled={row?.status != "available"}
              >
                Open
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(row.public_url);
                  enqueueSnackbar("URL copied to clipboard!", {
                    variant: "success",
                  });
                }}
                disabled={row?.status != "available"}
              >
                Copy
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => editCandidatePresentation(row)}
              >
                Edit
              </Button>
            </Box>
          ) : (
            "Not Available"
          )}
        </>
      ),
      grow: 1.4
    },
  ];

  const createCandidatePresentation = () => {
    editCandidatePresentation(null);
  };

  const editCandidatePresentation = (candidatePresentation) => {
    setSelectedCandidatePresentation(candidatePresentation);
    setIsEditModalOpen(true);
  };

  const fetchCategories = () => {
    sh.get("/categories", {
      authenticity_token: authenticityToken,
    }).then((res) => {
      let fetchedCategories = res?.data?.categories;
      fetchedCategories = fetchedCategories.filter(category => category.code !== "wark_at_sander");
      setOrganizedCategories(organizeCategoriesByDepartment(fetchedCategories));
    });
  };

  const fetchRegions = () => {
    sh.get("/regions", {
      authenticity_token: authenticityToken,
    }).then((res) => {
      const fetchedRegions = res?.data?.regions;
      setOrganizedRegions(fetchedRegions);
    }).catch((error) => {
      console.error("Error fetching regions:", error);
    });
  };

  const fetchPresentations = () => {
    sh.get("/internal/api/presentations", {
      authenticity_token: authenticityToken,
    }).then((res) => {
      const fetchedPresentations = res?.data;
      setPresentations(fetchedPresentations);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  };

  const handleStatusCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;

    sh.post("/internal/api/presentations/status_toggle", {
      authenticity_token: authenticityToken,
      presentation_id: row.id,
      available: isChecked
    }).then(() => {
      enqueueSnackbar(
        `Presentation ${isChecked ? "available" : "closed"} !`,
        { variant: "success" }
      );
      setPresentations((prevPresentations) =>
        prevPresentations.map((presentation) =>
          presentation.id === row.id
            ? {
              ...presentation,
              status: isChecked ? "available" : "closed",
            }
            : presentation
        )
      );
    }).catch(() => {
      enqueueSnackbar(
        "Oops, an error occured.",
        { variant: "error" }
      );
    });
  };

  useEffect(() => {
    fetchCategories();
    fetchRegions();
    fetchPresentations();
  }, []);


  return (
    <Box sx={{ ml: 12, mr: 12, mt: 1 }}>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: "16px" }}>
        <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 4 }}>
          Candidate Presentations
        </Typography>
        <Button variant="squared" color="primaryOutlined" onClick={createCandidatePresentation}>Create a new presentation</Button>
      </Box>

      <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "24px" }}>

      </Box>
      <Divider style={{ marginTop: "8px" }} />
      <DataTable
        columns={columns}
        data={presentations}
        progressPending={loading}
        defaultSortField="candidate_name"
        pagination
        striped
        responsive
        highlightOnHover
        pointerOnHover
        noDataComponent="No presentations available"
        customStyles={customStyles}
      />
      <Modal
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EditPresentationModal
          closeModal={() => setIsEditModalOpen(false)}
          presentation={selectedCandidatePresentation}
          fetchAllPresentations={fetchPresentations}
          organizedCategories={organizedCategories}
          organizedRegions={organizedRegions}
        />
      </Modal>
    </Box>
  );
};

export default Presentations;
