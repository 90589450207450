import { createStore } from "redux";
import railsContextReducer from "../reducers/railsContextReducer";

// Documentation for redux store api
// https://github.com/shakacode/react_on_rails/blob/master/docs/api/redux-store-api.md
const appStore = (railsProps) => createStore(railsContextReducer, railsProps);

export const reducerFn = (prevState, nextState) => {
  return {
    ...prevState,
    ...nextState,
  };
};

export default appStore;
