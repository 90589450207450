import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const ColoredCtaCards = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const data = {
    blue: {
      title: intl.formatMessage({ id: "colored_cta_cards.blue.title" }),
      button: {
        url: `/${i18nLocale}/contact`,
        title: intl.formatMessage({ id: "colored_cta_cards.blue.button" }),
        target: "_self"
      }
    },
    green: {
      title: intl.formatMessage({ id: "colored_cta_cards.green.title" }),
      button: {
        url: `/${i18nLocale}/referral`,
        title: intl.formatMessage({ id: "colored_cta_cards.green.button" }),
        target: "_self"
      }
    }
  };

  return (
    <section id="colored-cta-cards" className="vw-py [--py:60] tablet:[--py:80] laptop:[--py:110]">
      <div className="gg-container">
        <div className="gg-grid !vw-gap-y [--gap-y:8]">

          {/* Blue Card */}
          <div id="colored-cta-card" className="col-span-12 bg-lightBlue flex flex-col items-center vw-gap [--gap:30] laptop:[--gap:42] desktop:[--gap:48] vw-py [--py:60] laptop:[--py:80] desktop:[--py:90] vw-px [--px:24] tablet:[--px:32] laptop:[--px:48] vw-rounded-tr [--rounded-tr:60] tablet:[--rounded-tr:70] laptop:[--rounded-tr:80] desktop:[--rounded-tr:90] relative fadeInUp">
            <div id="colored-cta-card-bg" className="absolute top-0 left-0 w-full h-full">
              <svg className="w-full h-full object-cover object-center mini:hidden" viewBox="0 0 280 265" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin slice">
                <path d="M81.0127 0V151.303C81.0127 180.128 57.6451 203.496 28.8197 203.496H0" stroke="#346DE1" strokeWidth="0.745614" />
                <path d="M280 149.582L191.94 149.582C163.114 149.582 139.747 172.95 139.747 201.775L139.747 264.998" stroke="#346DE1" strokeWidth="0.745614" />
              </svg>
              <svg className="w-full h-full object-cover object-center max-mini:hidden" viewBox="0 0 637 402" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin slice">
                <path d="M184.171 0V227.854C184.171 272.343 148.106 308.408 103.617 308.408H0.046875" stroke="#346DE1" strokeWidth="1.15078" />
                <path d="M636.427 226.699L398.216 226.699C353.727 226.699 317.661 262.764 317.661 307.253L317.661 401.617" stroke="#346DE1" strokeWidth="1.15078" />
              </svg>
            </div>
            <div id="colored-cta-card-title" className="h2 text-center z-10">
              <span className="block text-balance" dangerouslySetInnerHTML={{ __html: data.blue.title }}></span>
            </div>

            <div id="colored-cta-card-button" className="max-mini:w-full z-10">
              <a href={data.blue.button.url} target={data.blue.button.target} className="inline-flex text-center justify-center items-center max-mini:w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full bg-white text-deepBlue">
                <svg className="vw-w [--w:7] vw-h [--h:13] object-contain overflow-visible" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.999996 1.09796L6.40204 6.5L0.999997 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span>{data.blue.button.title}</span>
              </a>
            </div>
          </div>

          {/* Green Card */}
          <div id="colored-cta-card" className="col-span-12 bg-lightGreen flex flex-col items-center vw-gap [--gap:30] laptop:[--gap:42] desktop:[--gap:48] vw-py [--py:60] laptop:[--py:80] desktop:[--py:90] vw-px [--px:24] tablet:[--px:32] laptop:[--px:48] vw-rounded-bl [--rounded-bl:60] tablet:[--rounded-bl:70] laptop:[--rounded-bl:80] desktop:[--rounded-bl:90] relative fadeInUp">
            <div id="colored-cta-card-bg" className="absolute top-0 left-0 w-full h-full">
              <svg className="w-full h-full object-cover object-center mini:hidden" viewBox="0 0 280 265" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin slice">
                <path d="M136.709 0V97.3913C136.709 126.217 113.341 149.584 84.5159 149.584H0" stroke="#4CC1AE" strokeWidth="0.745614" />
                <path d="M280 149.585L259.282 149.585C230.456 149.585 207.089 172.952 207.089 201.778L207.089 265" stroke="#4CC1AE" strokeWidth="0.745614" />
              </svg>
              <svg className="w-full h-full object-cover object-center max-mini:hidden" viewBox="0 0 637 402" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin slice">
                <path d="M310.71 0V146.149C310.71 190.638 274.645 226.703 230.156 226.703H0" stroke="#4CC1AE" strokeWidth="1.15078" />
                <path d="M636.38 226.703L551.223 226.703C506.734 226.703 470.668 262.769 470.668 307.258L470.668 401.622" stroke="#4CC1AE" strokeWidth="1.15078" />
              </svg>
            </div>
            <div id="colored-cta-card-title" className="h2 text-center z-10">
              <span className="block text-balance" dangerouslySetInnerHTML={{ __html: data.green.title }}></span>
            </div>
            <div id="colored-cta-card-button" className="max-mini:w-full z-10">
              <a href={data.green.button.url} target={data.green.button.target} className="inline-flex text-center justify-center items-center max-mini:w-full vw-gap [--gap:14] vw-px [--px:24] vw-py [--py:18] button-cta !leading-none rounded-full bg-white text-deepBlue">
                <svg className="vw-w [--w:7] vw-h [--h:13] object-contain overflow-visible" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.999996 1.09796L6.40204 6.5L0.999997 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span>{data.green.button.title}</span>
              </a>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default ColoredCtaCards;
