import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import Login from "bundles/Login/components/Login";
import ResetPassword from "bundles/Login/components/ResetPassword";
import AcceptInvitation from "bundles/Login/components/AcceptInvitation";

const LoginContainer = () => {
  return (
    <BrowserRouter>
      <Box
        id="main-container-box-id"
        display="flex"
        flexDirection="column"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Box>
          <Routes>
            <Route
              path={"/users/password/edit"}
              element={<ResetPassword />}
            />
            <Route
              path={"/users/invitation/accept"}
              element={<AcceptInvitation />}
            />
            <Route
              path={"/users/sign_in"}
              element={<Login />}
            />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default LoginContainer;
