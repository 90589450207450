import React from "react";

const Jumbotron = ({ data, aboutUs = false }) => {

  return (
    <section id="jumbotron" className="text-white relative vw-pt [--pt:84] mini:[--pt:132] laptop:[--pt:140] desktop:[--pt:168] before:content-[''] before:block before:absolute before:top-0 before:left-0 before:w-full before:bg-deepBlue before:vw-h before:[--h:150] mini:before:[--h:337] laptop:[--h:356] desktop:[--h:428] before:-z-10">
      <div id="jumbotron-container" className="gg-container">
        <div id="jumbotron-wrapper" className={`relative overflow-hidden w-full aspect-[280/368] mini:aspect-[664/836] ${aboutUs ? "mobile:aspect-[740/836]" : ""} tablet:aspect-[856/580] laptop:aspect-[1140/580] desktop:aspect-[1332/715] vw-rounded-tl [--rounded-tl:50] mini:[--rounded-tl:120] desktop:[--rounded-tl:135] vw-rounded-br [--rounded-br:50] mini:[--rounded-br:120] desktop:[--rounded-br:135] vw-px [--px:16] mini:[--px:56] tablet:[--px:72] laptop:[--px:96] desktop:[--px:112] vw-py [--py:56] mini:[--py:96] tablet:[--py:64] laptop:[--py:56] desktop:[--py:80] bg-black/30`}>
          <div id="jumbotron-bg" className="-z-[1] w-full h-full absolute top-0 left-0 parallax-image-wrapper">
            <img
              id="jumbotron-background-img"
              className="parallax-image w-full h-full object-cover"
              src={data.background.sizes.thumbnail}
              srcSet={`${data.background.sizes.thumbnail} 640w, ${data.background.sizes.medium} 1280w, ${data.background.sizes.large} 2560w`}
              sizes="100vw"
              alt={data.background.alt}
            />
          </div>
          <div id="jumbotron-text" className="flex flex-col vw-gap [--gap:32] mini:[--gap:42] tablet:vw-max-w tablet:[--max-w:495] laptop:[--max-w:514] desktop:[--max-w:756]">
            <h1 id="jumbotron-title" className="grand-titre fadeInUp">
              <span className="block text-balance" dangerouslySetInnerHTML={{ __html: data.title }}></span>
            </h1>
            <div id="jumbotron-label" className="chapeau animate-delay-250 fadeInUp">
              <span className="block text-balance" dangerouslySetInnerHTML={{ __html: data.label }}></span>
            </div>
          </div>

          {data.leftButton && (
            <a href={data.leftButton.url} className="button max-mini:!hidden border-deepBlue bg-deepBlue text-white hover:border-white hover:bg-white hover:text-deepBlue absolute vw-bottom [--bottom:64] vw-left [--left:56] tablet:[--left:72] laptop:[--left:96] desktop:[--left:112]"
              target={data.leftButton.target}>
              <svg className="button-icon" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="button-text" dangerouslySetInnerHTML={{ __html: data.leftButton.title }}></span>
            </a>
          )}

          {data.rightButton && (
            <a href={data.rightButton.url} className="button max-mini:!hidden border-white bg-white text-deepBlue hover:border-deepBlue hover:bg-deepBlue hover:text-white absolute vw-bottom [--bottom:64] vw-right [--right:56] tablet:[--right:72] laptop:[--right:96] desktop:[--right:112]"
              target={data.rightButton.target}>
              <svg className="button-icon" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.40009 1.09796L6.80213 6.5L1.40009 11.902" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="button-text" dangerouslySetInnerHTML={{ __html: data.rightButton.title }}></span>
            </a>
          )}
        </div>
      </div>
      <svg id="jumbotron-scroll-icon" className="vw-w [--w:16] vw-h [--h:9] mx-auto vw-mt [--mt:12] mini:[--mt:24] tablet:[--mt:32] laptop:[--mt:46] desktop:[--mt:32] fadeInUp" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.1094 1.1094L8 7.21881L1.8906 1.1094" stroke="#2359C5" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </section>
  );
};

export default Jumbotron;
