import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import sh from "bundles/common/utils/sh";
import { Typography, Link, Box } from "@mui/material";
import { FormControl, InputLabel, Divider, Select, Checkbox, Button, ListItemText, ListSubheader, OutlinedInput, MenuItem } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import { belgianRegions, frenchRegions } from "bundles/common/utils/constants";
import { capitalize, formatRegion, organizeCategoriesByDepartment } from "bundles/common/utils/helpers";


const Resumes = () => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);

  const [organizedCategories, setOrganizedCategories] = useState({});

  const customStyles = {
    table: {
      style: {
        border: "1px solid #DDDDDD",
      },
    },
    headRow: {
      style: {
        borderBottom: "1px solid #DDDDDD",
      },
    },
    headCells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:nth-last-of-type(1)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
        "&:nth-last-of-type(2)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      },
    },
    cells: {
      style: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
        "&:nth-last-of-type(1)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
        "&:nth-last-of-type(2)": {
          borderLeft: "0.4px solid #DDDDDD",
        },
      },
    },
    rows: {
      style: {
        minHeight: "50px",
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #DDDDDD",
        },
      },
    },
  };

  const fetchCandidates = ({
    selectedCategories,
    selectedRegions,
  } = {}) => {
    setLoading(true);

    const params = {
      authenticity_token: authenticityToken,
      selected_categories: selectedCategories?.map(category => category.id),
      selected_regions: selectedRegions
    };

    sh.get("internal/candidates", { params })
      .then((res) => {
        setCandidates(res?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching candidates:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCategoryChange = (selectedCategories) => {
    fetchCandidates({
      selectedRegions,
      selectedCategories: selectedCategories
    });
    setSelectedCategories(selectedCategories);
  };

  const selectAllCategories = (department) => {
    const allCategoriesInDept = organizedCategories[department];
    const newSelectedCategories = [...selectedCategories, ...allCategoriesInDept];

    const uniqueSelectedCategories = Array.from(new Set(newSelectedCategories.map(category => category.id)))
      .map(id => newSelectedCategories.find(category => category.id === id));

    handleCategoryChange(uniqueSelectedCategories);
  };

  const handleRegionChange = (selectedRegions) => {
    fetchCandidates({
      selectedCategories,
      selectedRegions: selectedRegions
    });
    setSelectedRegions(selectedRegions);
  };

  const fetchCategories = () => {
    sh.get("internal/all_categories", {
      authenticity_token: authenticityToken,
    }).then((res) => {
      const fetchedCategories = res?.data?.categories;
      setOrganizedCategories(organizeCategoriesByDepartment(fetchedCategories));
    });
  };

  const removeAllFilters = () => {
    setSelectedCategories([]);
    setSelectedRegions([]);
    fetchCandidates();
  };

  useEffect(() => {
    fetchCandidates();
    fetchCategories();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (
        <Link href={`mailto:${row.email}`} target="_blank" rel="noopener noreferrer" sx={{ cursor: "pointer" }}>
          {row.email}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => (
        <Link href={`tel:${row.phone}`} sx={{ cursor: "pointer" }}>
          {row.phone}
        </Link>
      ),
      sortable: false,
    },
    {
      name: "Applied To",
      selector: (row) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            lineHeight: "1.5",
            textAlign: "center",
          }}
        >
          {row.job_titles.map((title, index) => (
            <div key={index}>{title}</div>
          ))}
        </Box>
      ),
      sortable: false,
    },
    {
      name: "Region(s)",
      selector: (row) => (
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          lineHeight: "1.5",
        }}>
          {row.regions.map((region, index) => (
            <div key={index}>
              {formatRegion(region)}
            </div>
          ))}
        </Box>
      ),
      sortable: false,
    },
    {
      name: "Resume",
      selector: (row) => (
        <Link href={row.resume_url} target="_blank" rel="noopener noreferrer" sx={{ cursor: "pointer" }}>
          View Resume
        </Link>
      ),
      sortable: false,
    },
    {
      name: "",
      selector: (row) => (
        <Link
          href={`https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(row.name)}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <LinkedInIcon sx={{ color: "#0077B5" }} />
        </Link>
      ),
      sortable: false,
      width: "50px",
    },
  ];

  return (
    <Box sx={{ ml: 12, mr: 12 }}>
      <Typography variant="h4" gutterBottom sx={{ mt: 4, mb: 4 }}>
        Job Applications & Resumes
      </Typography>
      <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "24px" }}>
        <Box style={{ display: "flex", columnGap: "16px", marginBottom: "16px" }}>
          <Box>
            <FormControl sx={{ width: "240px", ".MuiOutlinedInput-root": { height: "40px" } }}>
              <InputLabel id="demo-multiple-checkbox-label" sx={{ top: selectedCategories.length === 0 ? "-6px" : "0" }}>Select Categories</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedCategories}
                onChange={(event) => handleCategoryChange(event.target.value)}
                input={<OutlinedInput label="Select Categories" />}
                renderValue={(selected) => selected.map(item => item.name).join(", ")}
              >
                {Object.entries(organizedCategories).map(([department, categories]) => [
                  <ListSubheader key={department} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {department}
                    <Button
                      size="small"
                      onClick={() => selectAllCategories(department)}
                      sx={{ textTransform: "none" }}
                    >
                      Select All
                    </Button>
                  </ListSubheader>,
                  ...categories.map((category) => (
                    <MenuItem
                      key={category.id}
                      value={category}
                      sx={{
                        padding: "0px 8px",
                        "&.Mui-selected": {
                          backgroundColor: "transparent",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        },
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <Checkbox checked={selectedCategories.includes(category)} />
                      <ListItemText primary={category.name} />
                    </MenuItem>
                  ))
                ])}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl sx={{ width: "240px", ".MuiOutlinedInput-root": { height: "40px" } }}>
              <InputLabel id="demo-multiple-checkbox-label" sx={{ top: selectedRegions.length === 0 ? "-6px" : "0" }}>Select Regions</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={selectedRegions}
                onChange={(event) => handleRegionChange(event.target.value)}
                input={<OutlinedInput label="Select Regions" />}
                renderValue={(selected) => selected.map(item => formatRegion(item)).join(", ")}
              >
                <ListSubheader>Belgium</ListSubheader>
                {belgianRegions.map((name) => (
                  <MenuItem key={name} value={name} sx={{ padding: "0px 8px" }}>
                    <Checkbox checked={selectedRegions.indexOf(name) > -1} />
                    <ListItemText primary={formatRegion(name)} />
                  </MenuItem>
                ))}
                <ListSubheader>France</ListSubheader>
                {frenchRegions.map((name) => (
                  <MenuItem key={name} value={name} sx={{ padding: "0px 8px" }}>
                    <Checkbox checked={selectedRegions.indexOf(name) > -1} />
                    <ListItemText primary={formatRegion(name)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", marginLeft: "32px" }}>
            <Link onClick={removeAllFilters} sx={{ cursor: "pointer" }}>Clear all</Link>
          </Box>
        </Box>
      </Box>
      <Divider style={{ marginTop: "8px" }} />
      <DataTable
        columns={columns}
        data={candidates}
        progressPending={loading}
        defaultSortField="name"
        pagination
        striped
        responsive
        highlightOnHover
        pointerOnHover
        customStyles={customStyles}
      />
    </Box>
  );
};

export default Resumes;
